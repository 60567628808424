/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "react-loader";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { withSize } from "react-sizeme";

import Colors from "styles/colors";

import { NoChartData } from "components/molecules/NoChartData.molecule";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { FontSize } from "components/atoms/Text.atom";
import { barStyle } from "pages/inventoryview/utils/chart.utils";

import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from "victory";

import {
  axisStyle,
  axisTicksLabelStyle,
  crossAxisStyle,
  strokeDasharray,
  strokeLinecap,
  strokeLinejoin,
  strokeWidth,
} from "styles/graph-styles";

export const DeparturesCountsWidget = ({
  widgetData,
  isLoading = false,
  title,
  bars,
  yAxisTickFormat,
}) => {
  const { t } = useTranslation("inventory-view");
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const onSize = (size) => {
    setDimensions({
      height: size.height,
      width: size.width,
    });
  };

  const data = widgetData.data;
  const trendData = widgetData.trendData;
  const actualsData = widgetData.actualsData;

  const xAxisTickFormatValue = data.map((item) => item.formattedDate);

  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content style={{ position: "relative", minHeight: 300 }}>
        <Loader loaded={!isLoading}>
          {trendData?.length ? (
            <div
              css={{
                svg: {
                  maxHeight: "23rem",
                },
                display: "flex",
                flexDirection: "row",
              }}
            >
              <ChartComponent
                bars={bars}
                barStyle={barStyle}
                axisStyle={axisStyle}
                crossAxisStyle={crossAxisStyle}
                onSize={onSize}
                dimensions={dimensions}
                xAxisTickFormatValue={xAxisTickFormatValue}
                width={dimensions.width}
                threshold={widgetData.primaryDepartureTarget}
                maxThreshold={widgetData.secondaryDepartureTarget}
                projectedTrend={widgetData.projectedTrend}
                trendData={trendData}
                actualsData={actualsData}
              />
            </div>
          ) : (
            <div
              css={{
                minHeight: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: Colors.text.GRAY,
                padding: "2em",
                textAlign: "center",
              }}
            >
              <NoChartData
                size={FontSize.size20}
                label={t(
                  "inventory-view:Unable to fetch details. Please try again later.",
                )}
              />
            </div>
          )}
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

const ChartComponent = withSize()(({
  dimensions,
  bars,
  axisStyle,
  crossAxisStyle,
  xAxisTickFormatValue,
  width,
  threshold,
  maxThreshold,
  projectedTrend,
  trendData,
  actualsData,
}) => {
  const containerRef = useRef();
  let legendLabels = bars.map((bar) => ({
    name: bar.legendLabel,
    y: bar.y,
    symbol: { type: bar.symbolType },
    color: bar.color,
  }));
  const colorScale = legendLabels.map((label) => label.color);

  const showPrimaryTarget = threshold > 0;
  const showSecondaryTarget = maxThreshold > 0;

  const maxCount = _.chain(actualsData).maxBy("y").get("y").value();
  const maxLimit =
    maxCount > maxThreshold
      ? maxCount > threshold
        ? maxCount
        : threshold
      : maxThreshold > threshold
      ? maxThreshold
      : threshold > 0
      ? threshold
      : 100;

  const axisLabelPlacement = width / 2 - 20;

  return (
    <div css={{ width: "100%" }}>
      <div ref={containerRef}>
        <VictoryChart
          domain={{ y: [0, maxLimit] }}
          domainPadding={{ x: 0, y: 30 }}
          padding={{ top: 20, bottom: 30, left: 30, right: 50 }}
          width={dimensions.width}
          height={400}
          containerComponent={<VictoryVoronoiContainer radius={50} />}
        >
          <VictoryArea
            data={actualsData}
            style={{
              data: {
                stroke: Colors.graphs.CHART_BLUE,
                fill: "#d9f0ff",
              },
            }}
          />

          <VictoryLine
            style={{
              data: {
                stroke: Colors.inventoryView.ORANGE,
                strokeDasharray,
                strokeLinecap,
                strokeLinejoin,
                strokeWidth,
              },
            }}
            data={trendData}
            y={(datum) => datum.projectedTrend}
          />
          <VictoryAxis
            axisValue={projectedTrend}
            orientation="top"
            style={{
              tickLabels: { fill: "none" },
              axisLabel: { fontSize: FontSize.size12 },
              axis: {
                stroke: "none",
              },
            }}
            label={projectedTrend}
            axisLabelComponent={
              <VictoryLabel
                dy={30}
                dx={axisLabelPlacement}
                style={{
                  fontWeight: 600,
                  fill: Colors.inventoryView.ORANGE,
                }}
              />
            }
          />

          <VictoryAxis
            style={axisStyle}
            tickLabelComponent={
              <VictoryLabel
                textAnchor="middle"
                style={{ fontSize: 12, ...axisTicksLabelStyle }}
              />
            }
            tickFormat={xAxisTickFormatValue}
            crossAxis={true}
          />
          <VictoryAxis style={crossAxisStyle} dependentAxis crossAxis={true} />
          {showPrimaryTarget && (
            <VictoryAxis
              axisValue={threshold}
              crossAxis={true}
              style={{
                tickLabels: { fill: "none" },
                axis: {
                  stroke: Colors.text.DARK_GRAY,
                  strokeWidth,
                },
              }}
            />
          )}
          {showPrimaryTarget && (
            <VictoryAxis
              axisValue={threshold}
              orientation="top"
              style={{
                tickLabels: { fill: "none" },
                axisLabel: { fontSize: FontSize.size12 },
                axis: {
                  stroke: "none",
                },
              }}
              label={threshold}
              axisLabelComponent={
                <VictoryLabel
                  dy={30}
                  dx={axisLabelPlacement}
                  style={{
                    fontWeight: 600,
                    fill: Colors.text.DARK_GRAY,
                  }}
                />
              }
            />
          )}
          {showSecondaryTarget && (
            <VictoryAxis
              axisValue={maxThreshold}
              style={{
                tickLabels: { fill: "none" },
                axis: {
                  stroke: Colors.graphs.CHART_LINE,
                  strokeWidth,
                },
              }}
            />
          )}
          {showSecondaryTarget && (
            <VictoryAxis
              axisValue={maxThreshold}
              orientation="top"
              style={{
                tickLabels: { fill: "none" },
                axisLabel: { fontSize: FontSize.size12 },
                axis: {
                  stroke: "none",
                },
              }}
              label={maxThreshold}
              axisLabelComponent={
                <VictoryLabel
                  dy={30}
                  dx={axisLabelPlacement}
                  style={{
                    fontWeight: 600,
                    fill: Colors.graphs.CHART_LINE,
                  }}
                />
              }
            />
          )}
          <VictoryScatter
            style={{
              data: {
                fill: Colors.inventoryView.WHITE,
                stroke: Colors.graphs.CHART_BLUE,
                strokeWidth: 2,
                cursor: "pointer",
              },
            }}
            data={actualsData}
            samples={14}
            size={5}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: () => ({
                    target: "data",
                    mutation: (props) => {
                      const clickableChart = bars.filter(
                        (bar) => bar.legendLabel === "Actual",
                      );
                      if (clickableChart.length) {
                        clickableChart[0].onClick(props.datum);
                      }
                    },
                  }),
                },
              },
            ]}
            labels={({ datum }) => datum.y}
          />
        </VictoryChart>
        <VictoryLegend
          x={(width / legendLabels.length) * 0.75}
          y={0}
          height={25}
          width={dimensions.width}
          style={{ labels: { fontFamily: "inherit" } }}
          colorScale={colorScale}
          data={legendLabels}
          orientation="horizontal"
        />
      </div>
    </div>
  );
});

DeparturesCountsWidget.propTypes = {
  isLoading: PropTypes.bool,
  widgetData: PropTypes.object,
  title: PropTypes.string,
  bars: PropTypes.array,
  yAxisTickFormat: PropTypes.object,
  trendData: PropTypes.array,
  actualsData: PropTypes.array,
};
