/** @jsxImportSource @emotion/react */
import { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useGetLatest } from "components/hooks/useGetLatest";
import { useEntityTripSummary } from "shared/hooks/useEntityTripSummary";
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import RoutingMap from "modules/map/components/RoutingMap";
import {
  RoutingMapContext,
  RoutingMapContextProvider,
} from "modules/map/components/RoutingMapContext";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import { getLegShipments } from "modules/shipment-detail/ShipmentUtils";
import { isDealer } from "shared/utils/organizations.utils";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";

import {
  useDiagnosticDataFormatter,
  getTransitDuration,
  getCurrentPositionDetails,
} from "shared/utils/entity.utils";
import { getVinPopUpDetails } from "shared/utils/vin.utils";
import { VinDetailsPanelGroup } from "pages/components/organisms/VinDetailsPanelGroup";
import {
  ArrivalAndDeparturePanelGroup,
  getLocationDataFromShipments,
  transformLocation,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
import { SourceType } from "api/consts";
import {
  Tabs,
  TabsClassNames,
  NO_PAGINATION,
} from "components/molecules/Tabs.molecule";
// Components for detail tabs
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { TripSummary } from "shared/components/organisms/TripSummary.organism";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { ConnectedCarCoordinatesTable } from "shared/components/organisms/ConnectedCarCoordinatesTable.organism";
import { useUpdatesTable } from "shared/hooks/columns/useUpdatesTable.columns";
import { useActiveExceptionsTable } from "shared/hooks/columns/useActiveExceptionsTable";

import { useDiagnosticsDataTable } from "shared/hooks/columns/useDiagnosticsDataTable";
import { useOrderDetailsTable } from "shared/hooks/columns/useOrderDetailsTable";
import { useVinDetailsTable } from "shared/hooks/columns/useVinDetailsTable";
import {
  useSetSubTitle,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { MediaQueries, isExtraSmall } from "components/responsive";
import { getVinTabs } from "../utils/carrierViewDetailsTabDefinitions";

const MAX_CONNECTED_CAR_COORDINATES = 1000;

const detailTabPanelStyle = {
  boxShadow: "0px 15px 12px 0px rgba(0,0,0,0.05)",
};

const DEFAULT_TAB_PAGE_SIZE = 20;

const removeHoldUpdates = (updates) => {
  return updates?.filter((update) => {
    return !(update?.code?.includes("HoldCreated") || update?.isHold);
  });
};

export const CarrierViewDetails = ({
  solutionId,
  entityId,
  details,
  fetchEntityDetails,
  fetchEntityExceptions,
  fetchEntityHolds,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  fetchPlannedTripLeg,
  fetchActualTripLeg,
  fetchVinSubscription,
  fetchEntityMedia,
  fetchLegShipmentDetails,
  clearLegShipmentDetails,
  clearEntityMedia,
  setWatchEntity,
  shareVin,
  combinedLegs,
  childShipments,
  shipmentsLoaded,
  plannedLegs,
  actualLegs,
  connectedCarCoords,
  combinedExceptions,
  currentLocation,
  authorization,
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  fetchNotification,
  fetchEntityShippers,
  shippers,
  activeOrganization,
  events = null,
  exceptions = null,
  holds = null,
  vinSubscription,
  isVinSubscriptionLoading,
  vinSubscriptionRequestError,
  updateSubscription,
  subscribe,
  unsubscribe,
  isVinSubscriptionUpdating,
  vinSubscriptionUpdateSuccess,
  vinSubscriptionUpdateError,
  media = null,
  clearShareVin,
  isSharingVin,
  isShareVinSuccessful,
  isShareVinFailed,
  // Map Coordinates
  addCoordinate,
  clearCoordinatesByType,
  selectedMapCoordinate,
  currentMapCoordinate,
  selectedConnectedCarCoordinate,
  // Historical Data
  getHistoryByQualifier,
  fetchHistoricalData,
}) => {
  const { t } = useTranslation("carrierview-details");
  const vinDetailsColumns = useVinDetailsTable();

  const orderDetailsColumns = useOrderDetailsTable();

  const {
    columns: diagnosticsDetailsColumns,
    SubComponent: DiagnosticsHistoricalTableSubComponent,
  } = useDiagnosticsDataTable(t, true);

  const { getDiagonsticLabel, getColorByState } = useDiagnosticDataFormatter();
  useSetTitleOnMount(t("carrierview-details:CarrierView Details"));
  useSetSubTitle(details?.id, details);

  useTrackWithMixpanelOnce("Viewed Page: CarrierView / Details");

  const columns = useActiveExceptionsTable();

  const [isLoading, setIsLoading] = useState(true);
  const [watch, setWatch] = useState(null);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const {
    columns: updatesTableColumns,
    SubComponent: UpdatesTableSubComponent,
  } = useUpdatesTable(false, false, true);

  useEffect(() => {
    if (entityId) {
      setIsLoading(true);

      // Non-layout data fetches; do not display spinner while loading
      fetchEntityExceptions(entityId);
      fetchEntityHolds(entityId);
      // Connected Car tab details - type set to "entity"
      fetchEntityPositionUpdates(
        solutionId,
        entityId,
        "entity",
        MAX_CONNECTED_CAR_COORDINATES,
      );
      // Populates `props.coords`.
      // This prop isn't used but needs to be fetched for curentLocation data.
      // See `getEntityCurrentLocation` selector.
      fetchEntityPositionUpdates(solutionId, entityId, "tripleg");
      fetchEntityEvents(solutionId, entityId);
      fetchEntityShippers();

      // Data fetches needed to render layout; return Promise to display isLoading spinner
      Promise.all([
        fetchEntityDetails(solutionId, entityId),
        fetchPlannedTripLeg(solutionId, entityId),
        fetchActualTripLeg(solutionId, entityId),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [
    entityId,
    solutionId,
    fetchEntityExceptions,
    fetchEntityHolds,
    fetchEntityPositionUpdates,
    fetchEntityEvents,
    fetchEntityDetails,
    fetchPlannedTripLeg,
    fetchActualTripLeg,
    fetchEntityShippers,
  ]);

  // Get existing vin subscription
  useEffect(() => {
    if (_.isFunction(fetchVinSubscription) && !_.isEmpty(details)) {
      fetchVinSubscription(details);
    }
  }, [fetchVinSubscription, details]);

  useEffect(() => {
    clearEntityMedia();

    if (details) {
      if (details?.references) {
        const imageURLRef = _.find(details.references, {
          qualifier: "Decoded-ImageURL",
        });

        if (imageURLRef) {
          fetchEntityMedia(imageURLRef.value);
        }
      }

      // If the details have been updated we need to make sure
      // that our watch checkbox matches the value in the props
      setWatch(details.watch);
    }
  }, [clearEntityMedia, details, fetchEntityMedia]);

  // We know the VIN if the URL ID and the internal ID matches.
  // The entityId can come back as either a string or number, so
  // convert both to strings for easy comparison.
  let vin =
    details?.internal_id?.toString() === entityId?.toString()
      ? details?.id
      : null;
  // Wrap the value in a "getter" callback so it doesn't cause additional updates to the effect.
  // We can access the object in the effect by calling `getLatestActualLegs()`
  const getLatestActualLegs = useGetLatest(actualLegs);
  // Using the ID in the deps of the effect so it will update when the ID changes.
  const latestActualLegEntityId = getLatestActualLegs()?.id;
  useEffect(() => {
    // H2-3151: Make sure we fetch using legs for this VIN.
    // The VIN can be null so making sure we have it before requesting.
    if (!_.isNil(vin) && vin === latestActualLegEntityId) {
      fetchLegShipmentDetails(getLatestActualLegs());
    } else {
      // If we don't yet have actual legs or a VIN, clear the leg shipment details
      clearLegShipmentDetails();
    }
  }, [
    vin,
    latestActualLegEntityId,
    getLatestActualLegs,
    fetchLegShipmentDetails,
    clearLegShipmentDetails,
  ]);

  const doShare = useCallback(
    (emails, capturedScreenshot, timezone) => {
      return shareVin(
        solutionId,
        entityId,
        emails,
        capturedScreenshot,
        timezone,
      );
    },
    [solutionId, entityId, shareVin],
  );

  const endToEndCoords = (shipments) => {
    let coordinatesData = [];

    if (!shipments || !shipments.length) {
      return coordinatesData;
    }

    shipments.forEach((s) => {
      s.current_location.updates.forEach((c) => {
        coordinatesData.push(c);
      });
    });

    return coordinatesData;
  };

  const tripSummary = useEntityTripSummary(combinedLegs, events);
  const hasTripPlan = tripSummary.stops.length > 0;

  const loaded = !isLoading && shipmentsLoaded;
  let hasActualLegs = false;
  let legShipments = [];
  let isMultimodal = false;
  let routeShipments = [];

  /* H1-1263 make sure child shipments are also loaded before attempting to render */
  if (loaded) {
    hasActualLegs =
      actualLegs && actualLegs.tripLegs && actualLegs.tripLegs.length > 0;

    legShipments = getLegShipments(
      childShipments,
      shipmentsLoaded,
      hasTripPlan || hasActualLegs,
    );
    // H1-1546: Added plannedLegs to this condition, once the ticket says that
    // Trip plan should be showed even when actual trip legs do not exist
    isMultimodal = legShipments.length > 0 || plannedLegs.tripLegs.length > 0;

    routeShipments = legShipments;
  }

  // When there are too many connected car coordinates,
  // it takes forever to load the map, so limit it to the top
  // MAX_CONNECTED_CAR_COORDINATES coordinates.
  let limitedConnectedCarCoords = _.slice(
    connectedCarCoords,
    0,
    MAX_CONNECTED_CAR_COORDINATES,
  );

  let hasMoreConnectedCarCoords =
    limitedConnectedCarCoords.length < connectedCarCoords.length;

  // H2-3038: on VinView suppress diagnostic tab per customer configuration
  const showDiagnosticDataTab =
    details?.configurations?.displayDiagnosticTab &&
    (authorization.isAuthorized([], [Features.DIAGNOSTIC_DATA]) ||
      authorization.hasOrganizationTypes([OrganizationType.DEALER]));

  // H2-3038: on VinView suppress CC tab per customer configuration
  const showConnectedCarTab = details?.configurations?.displayConnectedCarTab;

  const vinTabs = useMemo(
    () =>
      getVinTabs({
        t,
        combinedExceptions,
        currentLocation,
        details,
        connectedCarCoords,
        showDiagnosticDataTab,
        showConnectedCarTab,
        shouldFilterExceptions: false,
        getDiagnosticLabel: getDiagonsticLabel,
        getColorByState: getColorByState,
        hasTripPlan,
      }),
    [
      t,
      combinedExceptions,
      currentLocation,
      details,
      connectedCarCoords,
      showDiagnosticDataTab,
      showConnectedCarTab,
      getDiagonsticLabel,
      getColorByState,
      hasTripPlan,
    ],
  );

  const heatmapCoords = _.flatten(
    routeShipments.map((item) => _.get(item, "current_location.updates")),
  );

  let shipmentsSorted = null;

  if (hasActualLegs) {
    // H1-1451: If there are actual trip plan legs, sort in the exact order returned from the API
    shipmentsSorted = actualLegs.tripLegs.map((actualLeg) => {
      const matchingShipment = _.find(
        legShipments,
        (shipment) => shipment.creator_shipment_id === actualLeg.id,
      );

      return matchingShipment ?? null;
    });
  } else {
    // Sort by the origin's earliest arrival timestamp
    shipmentsSorted = _.orderBy(legShipments, [
      (s) => s.origin_earliest_arrival || "", // Make sure null values appear first
    ]);
  }

  // Child shipment details for `legTabs`
  // This is a map from shipment ID to details object.
  let childDetails = {};
  shipmentsSorted.forEach((s) => {
    if (s) {
      childDetails[s.id] = { ...s };
    }
  });

  // Remove nulls (so errors don't throw in the render)
  shipmentsSorted = shipmentsSorted.filter((shipment) => !_.isNil(shipment));

  // Add eta and actual delivery to next stop in tripLegs
  tripSummary.stops = tripSummary.stops.map((stop) => {
    // The internal shipment ID is not stored in legs.
    // We must find it in the list based on the displayed ID.
    const shipment = shipmentsSorted?.find(
      (shipment) =>
        shipment.creator_shipment_id === stop.actualCreatorShipmentId,
    );

    stop.active = shipment?.active_status_ng === "Active";
    stop.etaToStop = null;

    // FIN-5721: Don't show ETA to Stop for delivered VINs
    if (details?.lifeCycleState !== "Delivered") {
      // FIN-5585: The ETA to next stop should be the same as on the shipment detail page.
      if (shipment) {
        const { calculatedEta, frozenEta } =
          getLocationDataFromShipments([shipment]) ?? {};
        stop.etaToStop = frozenEta ?? calculatedEta ?? null;
      }
    }

    return stop;
  });

  const hasTransitTimerFeature = authorization.isAuthorized(
    [],
    [Features.TRANSIT_TIMER],
  );

  const origin =
    loaded && !_.isNil(details?.ultimateOrigin)
      ? transformLocation(details.ultimateOrigin)
      : null;
  const destination =
    loaded && !_.isNil(details?.ultimateDestination)
      ? transformLocation(details.ultimateDestination)
      : null;

  const currentPositionDetails = useMemo(() => {
    return loaded ? getCurrentPositionDetails(details, t) : null;
  }, [loaded, details, t]);

  useEffect(() => {
    if (currentPositionDetails) {
      addCoordinate(
        MapCoordinateType.CURRENT_LOCATION,
        currentPositionDetails.latitude,
        currentPositionDetails.longitude,
        currentPositionDetails.datetime,
        currentPositionDetails.ind,
        {
          PopUpDetails: {
            name: currentPositionDetails.currentPositionName,
            ...getVinPopUpDetails(details, "currentLocation"),
          },
        },
      );
    }
  }, [currentPositionDetails, addCoordinate, details]);

  // H2-3038: on VinView suppress source field per customer configuration
  const canDisplaySource =
    currentPositionDetails?.sourceType !== SourceType.CONNECTED_CAR ||
    details?.configurations?.displayConnectedCarSource;

  const transitDuration =
    loaded && hasTransitTimerFeature
      ? getTransitDuration(tripSummary.updates)
      : null;

  let tripPlanCompleteTs = loaded ? details?.tripPlanCompleteTs : null;

  const displayHoldEventsForDealersAndCarriers =
    details?.configurations?.displayHoldEventsForDealersAndCarriers;

  const eventUpdatesForTabs = displayHoldEventsForDealersAndCarriers
    ? tripSummary.updates
    : removeHoldUpdates(tripSummary.updates);

  // For tabs...
  const onTabsSelect = (index, lastIndex, event) => {
    // Connected Car
    // if we leave from the connected car tab
    if (event.target.textContent !== t("fv-vin-details:Connected Car")) {
      clearCoordinatesByType([MapCoordinateType.CURRENT_CONNECTED_CAR]);
    }

    // Coordinates
    // if we leave from the coordinates tab
    if (event.target.textContent !== t("fv-vin-details:Coordinates")) {
      clearCoordinatesByType([MapCoordinateType.SELECTED_COORDINATE]);
    }
  };

  const endToEndTabCoords = endToEndCoords(shipmentsSorted);
  const popUpDetails = getVinPopUpDetails(details);

  const endToEndPanelContent = loaded ? (
    <div css={{ overflow: "auto" }}>
      {/* Details Group */}
      <VinDetailsPanelGroup
        entityId={details?.internal_id}
        details={details}
        media={media}
        holds={holds}
        exceptions={exceptions}
        addCoordinate={addCoordinate}
        clearCoordinatesByType={clearCoordinatesByType}
        currentMapCoordinate={currentMapCoordinate}
        hasTransitTimerFeature={hasTransitTimerFeature}
        transitDuration={transitDuration}
        currentPositionDetails={currentPositionDetails}
        canDisplaySource={canDisplaySource}
        showShareAction={true}
        showAlertAction={true}
        shouldBuildExceptionsBySeverity={true}
        vinSubscription={vinSubscription}
        isVinSubscriptionLoading={isVinSubscriptionLoading}
        vinSubscriptionRequestError={vinSubscriptionRequestError}
        updateSubscription={updateSubscription}
        subscribe={subscribe}
        unsubscribe={unsubscribe}
        isVinSubscriptionUpdating={isVinSubscriptionUpdating}
        vinSubscriptionUpdateSuccess={vinSubscriptionUpdateSuccess}
        vinSubscriptionUpdateError={vinSubscriptionUpdateError}
        watch={watch}
        toggleWatchEntity={() => {
          // Toggle our internal state, so the UI remains responsive
          // and then send the update.  If the state is not properly
          // changed on the backend, we will revert when we receive the
          // response from the API
          const newWatchState = !watch;
          setWatch(newWatchState);
          setWatchEntity(solutionId, entityId, newWatchState);
        }}
        watchToggleTooltipText={
          isDealer(activeOrganization)
            ? t(
                "carrierview-details:Add this VIN to your Watched VINs list on the CarrierView Dashboard and receive notifications for new comments",
              )
            : t(
                "carrierview-details:Add this VIN to your Watched VINs list on the CarrierView Dashboard",
              )
        }
        clearShareVin={clearShareVin}
        shareVin={doShare}
        isSharingVin={isSharingVin}
        isShareVinSuccessful={isShareVinSuccessful}
        isShareVinFailed={isShareVinFailed}
        canShareEta={false}
        style={{ margin: "1em" }}
      />

      {/* Arrivals and Departures Group */}
      {hasTripPlan ? (
        <ArrivalAndDeparturePanelGroup
          origin={origin}
          destination={destination}
          hideScheduled={true}
          // FIN-5585: Don't show ETA in Arrivals and Departures panel
          eta={null}
          style={{ margin: "1em" }}
          tripPlanCompleteTs={tripPlanCompleteTs}
        />
      ) : null}

      <CommentFeed
        commentShareableWithOrgId={details?.shipperOrgId}
        fetchComments={(pageNumber, pageSize) => {
          return fetchComments(solutionId, entityId, pageNumber, pageSize);
        }}
        isFetchingComments={isFetchingComments}
        comments={comments}
        addComment={(data) => {
          return addComment(solutionId, entityId, data);
        }}
        cancelAddComment={cancelAddComment}
        updateComment={(commentId, updatedData) => {
          return updateComment(solutionId, entityId, commentId, updatedData);
        }}
        cancelUpdateComment={cancelUpdateComment}
        markCommentsRead={(datetime) => {
          return markCommentsRead(solutionId, entityId, datetime);
        }}
        showBatchUpload={false}
        style={{ margin: "1em" }}
        fetchNotification={fetchNotification}
        shippers={shippers}
      />

      <div css={{ margin: "1em" }}>
        <Tabs
          css={{
            [TabsClassNames.Tab]: {
              // Reduces the tabs padding so more text can fit horizontally.
              padding: "0 2px",
            },
          }}
          fullWidthTabs
          onSelect={onTabsSelect}
        >
          <Tabs.TabList maxTabs={isExtraSmall() ? 4 : NO_PAGINATION}>
            {vinTabs.array?.map((tab) => (
              <Tabs.Tab key={tab.title}>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    // This adjusts the text at different screen sizes
                    // so they are more likely to fit on one line.
                    [MediaQueries.mediumAndDown]: {
                      fontSize: 12,
                    },
                    [MediaQueries.large]: {
                      fontSize: 10,
                    },
                    [MediaQueries.extraLarge]: {
                      fontSize: 12,
                    },
                  }}
                >
                  {tab.title}
                </div>
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
          {/* Trip Plan tab */}

          {
            // If we have tripSummary.stops, render the TripSummary (with Location Info and Updates).
            tripSummary.stops?.length > 0 ? (
              <Tabs.TabPanel style={detailTabPanelStyle}>
                <TripSummary
                  plannedStops={tripSummary.stops}
                  hideMadBorder={true}
                  showMadTooltip={true}
                  hideScheduled={true}
                  showUpdates={false}
                  // FIN-5585: Hide TripLegReferences button for CarrierView
                  showTripLegReferences={false}
                  // FIN-5585: Hide comments column and eta.
                  showComments={false}
                  showEta={false}
                  showActiveShipmentEtaToStop={true}
                  showActualDeliveryToStop={true}
                />
              </Tabs.TabPanel>
            ) : null
          }
          {/* Updates tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {
              // Else, if we have events, render the Events/Updates table.
              eventUpdatesForTabs.length > 0 ? (
                <BaseTable
                  theme={Themes.LIGHT}
                  data={eventUpdatesForTabs}
                  columns={updatesTableColumns}
                  disableSortBy={true}
                  pageSize={Math.max(
                    eventUpdatesForTabs.length,
                    DEFAULT_TAB_PAGE_SIZE,
                  )}
                  subComponent={(row) => {
                    return (
                      <UpdatesTableSubComponent
                        statusUpdate={row.row.original.statusUpdate}
                        comments={row.row.original.comments}
                        duplicateMilestoneGroup={
                          row.row.original.duplicateMilestoneGroup
                        }
                        showComments={false}
                        t={t}
                      />
                    );
                  }}
                />
              ) : (
                // Else, "no data" placeholder.
                <TabPanelPlaceholder
                  text={t("fv-vin-details:No Updates Available")}
                />
              )
            }
          </Tabs.TabPanel>
          {/* Active Exceptions tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {vinTabs.data.combinedExceptions.length > 0 ? (
              <BaseTable
                data-qa="active-exceptions"
                theme={Themes.LIGHT}
                data={vinTabs.data.combinedExceptions}
                columns={columns}
                pageSize={Math.max(
                  vinTabs.data.combinedExceptions.length,
                  DEFAULT_TAB_PAGE_SIZE,
                )}
              />
            ) : (
              <TabPanelPlaceholder
                text={t("fv-vin-details:No Active Exceptions")}
              />
            )}
          </Tabs.TabPanel>
          {/* Coordinates tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {endToEndTabCoords.length > 0 ? (
              <CoordinatesTable
                addCoordinate={(coordinateType, lat, long, time, ind, row) => {
                  addCoordinate(coordinateType, lat, long, time, ind, {
                    PopUpDetails: popUpDetails,
                    City: row.city,
                    State: row.state,
                    Country: row.country,
                  });
                }}
                clearCoordinatesByType={clearCoordinatesByType}
                selectedMapCoordinate={selectedMapCoordinate}
                coords={endToEndTabCoords}
              />
            ) : (
              <TabPanelPlaceholder
                text={t("fv-vin-details:No Coordinates Available")}
              />
            )}
          </Tabs.TabPanel>
          {/* Order Details tab */}
          {vinTabs.data.orderDetails.length > 0 && (
            <Tabs.TabPanel style={detailTabPanelStyle}>
              <BaseTable
                data-qa="order-details"
                theme={Themes.LIGHT}
                data={vinTabs.data.orderDetails}
                columns={orderDetailsColumns}
                pageSize={Math.max(
                  vinTabs.data.orderDetails.length,
                  DEFAULT_TAB_PAGE_SIZE,
                )}
                defaultSortColumn="qualifier"
                defaultReverseSort={false}
              />
            </Tabs.TabPanel>
          )}
          {/* VIN Details tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {vinTabs.data.vinEquipment.length > 0 ? (
              <BaseTable
                data-qa="equipment-details"
                theme={Themes.LIGHT}
                data={vinTabs.data.vinEquipment}
                columns={vinDetailsColumns}
                pageSize={Math.max(
                  vinTabs.data.vinEquipment.length,
                  DEFAULT_TAB_PAGE_SIZE,
                )}
                defaultSortColumn="qualifier"
                defaultReverseSort={false}
              />
            ) : (
              <TabPanelPlaceholder
                text={t("fv-vin-details:No Details Available")}
              />
            )}
          </Tabs.TabPanel>
          {/* Connected Car tab */}
          {showConnectedCarTab && limitedConnectedCarCoords.length > 0 ? (
            <Tabs.TabPanel style={detailTabPanelStyle}>
              <ConnectedCarCoordinatesTable
                addCoordinate={addCoordinate}
                clearCoordinatesByType={clearCoordinatesByType}
                selectedConnectedCarCoordinate={selectedConnectedCarCoordinate}
                coords={limitedConnectedCarCoords}
                maxConnectedCarCoords={MAX_CONNECTED_CAR_COORDINATES}
                hasMoreConnectedCarCoords={hasMoreConnectedCarCoords}
                popUpDetails={popUpDetails}
              />
            </Tabs.TabPanel>
          ) : null}
          {/* Diagnostics Data tab */}
          {showDiagnosticDataTab &&
            vinTabs.data.diagnosticsData?.length > 0 && (
              <Tabs.TabPanel style={detailTabPanelStyle}>
                <BaseTable
                  data-qa="diagnostic-details"
                  theme={Themes.LIGHT}
                  data={vinTabs.data.diagnosticsData}
                  columns={diagnosticsDetailsColumns}
                  pageSize={Math.max(
                    vinTabs.data.diagnosticsData.length,
                    DEFAULT_TAB_PAGE_SIZE,
                  )}
                  subComponent={(row) => {
                    const qualifier = row.row.original.qualifier;

                    return (
                      <DiagnosticsHistoricalTableSubComponent
                        solutionId={solutionId}
                        entityId={entityId}
                        qualifier={qualifier}
                        fetchHistoricalData={fetchHistoricalData}
                        getHistoryByQualifier={getHistoryByQualifier}
                      />
                    );
                  }}
                />
              </Tabs.TabPanel>
            )}
        </Tabs>
      </div>
    </div>
  ) : null;

  let content = null;

  if (loaded) {
    content = endToEndPanelContent;
  }

  return (
    <RoutingMapContextProvider
      shipments={routeShipments}
      showBreadCrumbs={true}
      showStopSequence={true}
      showHeatmap={showHeatmap}
      heatmapCoords={heatmapCoords}
      isMultimodal={isMultimodal}
      activeShipment={isMultimodal ? details?.activeTripLegId : null}
      connectedCarCoords={limitedConnectedCarCoords}
      hideMads={true}
      t={t}
      allowCaptureScreenshot={false}
    >
      <DetailWithMap
        mapChildren={
          <Fragment>
            <RoutingMapContext.Consumer>
              {(routingMapProps) => <RoutingMap {...routingMapProps} />}
            </RoutingMapContext.Consumer>

            {heatmapCoords?.length > 0 ? (
              <HeatMapButton
                isToggled={showHeatmap}
                onToggle={() => setShowHeatmap(!showHeatmap)}
                css={{
                  position: "absolute",
                  top: 5,
                  right: 0,
                  zIndex: 2,
                }}
              />
            ) : null}
          </Fragment>
        }
        detailsChildren={<Loader loaded={loaded}>{content}</Loader>}
      />
    </RoutingMapContextProvider>
  );
};

CarrierViewDetails.propTypes = {
  solutionId: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  details: PropTypes.object,
  combinedLegs: PropTypes.array,
  childShipments: PropTypes.object,
  shipmentsLoaded: PropTypes.bool,
  plannedLegs: PropTypes.object,
  actualLegs: PropTypes.object,
  connectedCarCoords: PropTypes.array,
  combinedExceptions: PropTypes.array,
  currentLocation: PropTypes.object,
  authorization: PropTypes.object,
  fetchComments: PropTypes.func,
  isFetchingComments: PropTypes.bool,
  comments: PropTypes.object,
  addComment: PropTypes.func,
  cancelAddComment: PropTypes.func,
  updateComment: PropTypes.func,
  cancelUpdateComment: PropTypes.func,
  markCommentsRead: PropTypes.func,
  addBatchComments: PropTypes.func.isRequired,
  clearBatchComments: PropTypes.func.isRequired,
  isBatchCommentInProgress: PropTypes.bool,
  isBatchCommentSuccessful: PropTypes.bool,
  isBatchCommentFailed: PropTypes.bool,
  fetchNotification: PropTypes.func.isRequired,
  fetchEntityShippers: PropTypes.func.isRequired,
  shippers: PropTypes.array,
  activeOrganization: PropTypes.object,
  events: PropTypes.array,
  exceptions: PropTypes.array,
  holds: PropTypes.array,
  vinSubscription: PropTypes.object,
  isVinSubscriptionLoading: PropTypes.bool,
  vinSubscriptionRequestError: PropTypes.bool,
  updateSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  isVinSubscriptionUpdating: PropTypes.bool,
  vinSubscriptionUpdateSuccess: PropTypes.bool,
  vinSubscriptionUpdateError: PropTypes.bool,
  media: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  clearEntityMedia: PropTypes.func,
  fetchLegShipmentDetails: PropTypes.func,
  clearLegShipmentDetails: PropTypes.func,
  fetchEntityDetails: PropTypes.func,
  fetchEntityExceptions: PropTypes.func,
  fetchEntityHolds: PropTypes.func,
  fetchEntityPositionUpdates: PropTypes.func,
  fetchEntityEvents: PropTypes.func,
  fetchPlannedTripLeg: PropTypes.func,
  fetchActualTripLeg: PropTypes.func,
  fetchVinSubscription: PropTypes.func,
  fetchEntityMedia: PropTypes.func,
  setWatchEntity: PropTypes.func,
  coords: PropTypes.array,
  clearShareVin: PropTypes.func.isRequired,
  shareVin: PropTypes.func.isRequired,
  isSharingVin: PropTypes.bool,
  isShareVinSuccessful: PropTypes.bool,
  isShareVinFailed: PropTypes.bool,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
  currentMapCoordinate: PropTypes.object,
  selectedConnectedCarCoordinate: PropTypes.object,
  // Historical Data
  getHistoryByQualifier: PropTypes.func.isRequired,
  fetchHistoricalData: PropTypes.func.isRequired,
};
