import _ from "lodash";

export const DATA_INPUT_TYPE_ERROR_TYPES = {
  REQUIRED_FIELD: "REQUIRED_FIELD",
  NEGATIVE_NUMBER: "NEGATIVE_NUMBER",
  DUPLICATE_ORG_FV_ID: "DUPLICATE_ORG_FV_ID",
};

export const validateLocationOrgData = (value, orgLevelLocationInfo = []) => {
  const numericValue = parseInt(value);
  if (!_.isNaN(numericValue)) {
    return null;
  }

  if (value && orgLevelLocationInfo) {
    if (
      orgLevelLocationInfo
        .filter((ele) => ele?.enabled)
        .map((ele) => ele?.orgFvId?.trim(""))
        .filter((ele) => ele?.toLowerCase() === value?.toLowerCase()).length > 1
    ) {
      return DATA_INPUT_TYPE_ERROR_TYPES.DUPLICATE_ORG_FV_ID;
    }
  }

  if (!value || _.isEmpty(value.trim())) {
    return DATA_INPUT_TYPE_ERROR_TYPES.REQUIRED_FIELD;
  }
};

const validateNonNegativeNumber = (value) => {
  const numericValue = parseInt(value);
  if (!_.isNaN(numericValue)) {
    return numericValue < 0
      ? DATA_INPUT_TYPE_ERROR_TYPES.NEGATIVE_NUMBER
      : null;
  }
};

export const validateInput = (value) => {
  return validateLocationOrgData(value) || validateNonNegativeNumber(value);
};
