import _ from "lodash";
import {
  isCarrier,
  isShipper,
  isPartner,
  isHealthcareProvider,
  isDealer,
} from "shared/utils/organizations.utils";

const shipperOnlyPaths = ["/locations", "/reporting"];
const carrierOnlyPaths = ["/create-shipment"];
const partnerAllowedPaths = [
  "/partners",
  "/partners/create-milestone-event",
  "/manage/users",
  "/container-tracking",
  "/container-tracking/search",
  "/container-tracking/details/:id",
  "/inventoryview",
  "/inventoryview/details",
  "/damageview",
  "/damageview/search",
  "/damageview/details",
  "/compliance",
  "/compliance/reports/:reportId",
];
const patOnlyPath = "/plant-asset";
const healthcareProviderAllowedPaths = [
  "/healthcare-container-tracking",
  "/healthcare-tracking-management",
  "/manage/users",
  "/manage/locations",
  "/profile",
];
const dealerAllowedPaths = [
  "/vinview",
  "/vinview/search",
  "/vinview/details",
  "/manage/users",
  "/profile",
  "/driveaway",
  "/partview-seller",
  "/partview-seller/search",
  "/partview-seller/details",
];

// Check shipper/carrier routes
// TODO: Move to Authorization() or create a new class for these checks
function isRouteForbidden(authorization, activeOrganization, route) {
  if (!isShipper(activeOrganization) && shipperOnlyPaths.includes(route.path)) {
    return true;
  }
  if (!isCarrier(activeOrganization) && carrierOnlyPaths.includes(route.path)) {
    return true;
  }
  if (
    isPartner(activeOrganization) &&
    !partnerAllowedPaths.includes(route.path)
  ) {
    return true;
  }
  if (
    isHealthcareProvider(activeOrganization) &&
    !healthcareProviderAllowedPaths.includes(route.path) &&
    !route.path.includes("reports")
  ) {
    return true;
  }
  if (
    isDealer(activeOrganization) &&
    !dealerAllowedPaths.includes(route.path) &&
    !route.path.includes("documentation")
  ) {
    return true;
  }
  if (
    authorization.featureAuthorization.plantAssetTrackingOnly() &&
    !route.path.includes(patOnlyPath)
  ) {
    return true;
  }
  return false;
}

// Build a list of links and auth data from a navGroup's child routes
export function groupData(authorization, activeOrganization, navGroup) {
  const groupLinks = [];
  const groupPrivileges = [];
  const groupFeatures = [];
  const groupOrganizationTypes = [];
  const groupGlobalAppConfigs = [];

  _.keys(navGroup.routes).forEach((key) => {
    const route = navGroup.routes[key];
    if (route.hidden) {
      return;
    }
    const privileges = route.privileges ?? [];
    const features = route.features ?? [];
    const organizationTypes = route.organizationTypes ?? [];
    const globalAppConfig = route.globalAppConfig ?? {};
    const isAuthorized = authorization.isAuthorized(
      privileges,
      features,
      organizationTypes,
      [globalAppConfig],
    );
    const isForbidden = isRouteForbidden(
      authorization,
      activeOrganization,
      route,
    );

    if (isAuthorized && !isForbidden) {
      groupLinks.push({ key, route });
    }

    groupPrivileges.push.apply(groupPrivileges, privileges);
    groupFeatures.push.apply(groupFeatures, features);
    groupOrganizationTypes.push.apply(
      groupOrganizationTypes,
      organizationTypes,
    );
    groupGlobalAppConfigs.push.apply(groupGlobalAppConfigs, globalAppConfig);
  });

  return {
    links: groupLinks,
    privileges: [...new Set(groupPrivileges)],
    features: [...new Set(groupFeatures)],
    organizationTypes: [...new Set(groupOrganizationTypes)],
    globalAppConfigs: groupGlobalAppConfigs,
  };
}
