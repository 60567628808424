import { connect } from "react-redux";

import { getSolutionId } from "modules/organizations/OrganizationsState";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import InventoryViewInsights from "./InventoryView.Insights.page";

import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";

function mapStateToProps(state) {
  const locationId = state.location.payload.locationId;
  const { getLocation, getLocationTimezone } =
    InventoryViewDetailsState.selectors;

  const { getIsLoading } = InventorySearchBarState.selectors;

  return {
    solutionId: getSolutionId(state),
    locationId: locationId,
    currentOrganization: getActiveOrganization(state),
    location: getLocation(state),
    isLoading: getIsLoading(state),
    searchFilters: InventorySearchBarState.selectors.getSearchFilters(state),
    locationTimezone: getLocationTimezone(state),
  };
}

const {
  searchEntities,
  setSearchFilter,
  resetSearchAndFilters,
  toggleShowFilters,
  setCurrentLocation,
} = InventorySearchBarState.actionCreators;

const {
  fetchLocation,
  redirectToDetailsView,
  redirectToDashboardView,
  cleanupInventoryLocationDetails,
} = InventoryViewDetailsState.actionCreators;

const actionCreators = {
  fetchLocation,
  setCurrentLocation,
  setSearchFilter,
  resetSearchAndFilters,
  toggleShowFilters,
  redirectToDetailsView: (solutionId, locationId) => (dispatch) => {
    dispatch(searchEntities(solutionId));
    dispatch(redirectToDetailsView(locationId));
  },
  redirectToDashboardView,
  onsiteVinsResetSearch: (solutionId) => (dispatch) => {
    dispatch(resetSearchAndFilters());
    dispatch(searchEntities(solutionId));
  },
  cleanupInventoryLocationDetails,
};

export default connect(mapStateToProps, actionCreators)(InventoryViewInsights);
