import moment from "moment";

import { InventoryStatus, TimeOnSiteDuration } from "api/consts";

import { useInventoryViewTranslation } from "shared/hooks/useInventoryViewTranslation";
import { useInventoryTranslation } from "shared/hooks/useInventoryTranslation";

import {
  getBasicQueryString,
  getBasicWithStaticOptionsQueryString,
  getDateRangeQueryString,
  getEverythingQueryString,
  getDateRangeStringForLocationTimezone,
  getMultiSelectQueryString,
} from "components/search-bar/search-filter-query-strings";

import {
  AsyncSelectFilterButton,
  DateRangeFilterButton,
  MultiSelectFilterButton,
  SelectFilterButton,
} from "components/search-bar/FilterButtons";

import {
  currentLocationOptionsState,
  destinationOptionsState,
  orderTypesOptionsState,
  productTypeOptionsState,
  carrierOptionsState,
} from "pages/inventoryview/details/search/InventoryView.Details.SearchFilterLoaders";

import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";
import { BrowserStorage } from "../../../../utils/browser-storage.utils";
import { initialETAOptions } from "pages/inventoryview/utils/consts";

export const QUERY_KEY_MAPPING = {
  ARRIVAL_DATE: "arrivalTs",
  CARRIER: "carrier",
  CURRENT_LOCATION: "currentLocation",
  CURRENT_LOCATION_TYPE: "currentLocationType",
  DEPARTURE_DATE: "departureTs",
  PRODUCT_TYPE: "productType",
  DESTINATION: "ultimateDestinationLocation",
  ENTITY_ID: "entityId",
  FORECASTED_ARRIVAL: "forecastedArrivalTs",
  INITIAL_ETA: "initialEta",
  ORDER_TYPE: "orderType",
  PRODUCTION_DATE: "estimatedProductionTs",
  TIME_ON_SITE: "timeOnSite",
  SHIPPABILITY: "isShippable",
};

export const timeOnSiteOptions = {
  [TimeOnSiteDuration.DAYS_10]: { to: 9 },
  [TimeOnSiteDuration.DAYS_6_9]: { from: 9, to: 5 },
  [TimeOnSiteDuration.DAYS_3_5]: { from: 5, to: 2 },
  [TimeOnSiteDuration.DAYS_0_2]: { from: 2 },
  [TimeOnSiteDuration.ALL]: { all: 1 },
  [TimeOnSiteDuration.NONE]: { all: 0 },
};

const getTimeOnSiteDateRangeQuery = (queryKey, filterValue) => {
  const filterSelection = timeOnSiteOptions[filterValue];
  if (filterSelection == null) {
    return "";
  }

  if (filterSelection.hasOwnProperty("all")) {
    const urlParams = new URLSearchParams();
    urlParams.set("onSite", filterSelection.all);
    return `&${urlParams.toString()}`;
  }

  const locationTimezone = BrowserStorage.locationTimezone
    ? BrowserStorage.locationTimezone
    : moment.tz.guess();

  const toParam =
    filterSelection.to != null
      ? moment
          .tz(locationTimezone)
          .startOf("day")
          .subtract(filterSelection.to, "days")
          .utc()
      : moment.utc();
  // fix 0-2 days going to current time
  const fromParam =
    filterSelection.from != null
      ? moment
          .tz(locationTimezone)
          .startOf("day")
          .subtract(filterSelection.from, "days")
          .utc()
      : null;

  const dateParams = {
    to: toParam ?? null,
    from: fromParam ?? null,
  };

  return getDateRangeQueryString(queryKey, dateParams, {
    convertToUtc: true,
  });
};

export const INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES = [
  {
    queryKey: QUERY_KEY_MAPPING.ENTITY_ID,
    label: () => null,
    placeholder: (t) => t("inventory-view:Search VIN"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];

export const INVENTORY_VIEW_DETAILS_FILTERS = [
  {
    queryKey: QUERY_KEY_MAPPING.PRODUCT_TYPE,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Product Type"),
    optionsState: productTypeOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: QUERY_KEY_MAPPING.SHIPPABILITY,
    label: (t) => t("inventory-view:Shippability"),
    Component: (props) => {
      const { getTranslatedStatus } = useInventoryViewTranslation();
      // it can be solved using 'ts' only it seems
      // eslint-disable-next-line react/prop-types
      const translatedOptions = props?.options?.map((option) => ({
        ...option,
        label: getTranslatedStatus(option.label),
      }));

      return <SelectFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => {
      return [
        {
          label: InventoryStatus.SHIPPABLE,
          value: "true",
        },
        {
          label: InventoryStatus.NONSHIPPABLE,
          value: "false",
        },
      ];
    },
    queryBuilder: getBasicQueryString,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.FORECASTED_ARRIVAL,
    label: (t) => t("inventory-view:Forecasted Arrival"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    timezoneGetter: (props) => props.locationTimezone,
    isValueValid: isDateRangeValueValid,
    queryBuilder: getDateRangeStringForLocationTimezone,
  },
  {
    queryKey: QUERY_KEY_MAPPING.CURRENT_LOCATION_TYPE,
    label: (t) => t("inventory-view:Current Location Type"),
    optionsGetter: (props) => {
      return props.currentLocation;
    },
    Component: (props) => {
      const { getTranslatedLabelsPipelineGraph } = useInventoryTranslation();
      // eslint-disable-next-line react/prop-types
      const translatedOptions = props.options.map((lad) => ({
        value: lad.value,
        label: getTranslatedLabelsPipelineGraph(lad.label),
      }));

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    isMulti: true,
    queryBuilder: getBasicWithStaticOptionsQueryString,
    hideFuzzySearch: true,
    hideSelectAll: true,
    hideSelectEmpty: false,
  },
  {
    queryKey: QUERY_KEY_MAPPING.CURRENT_LOCATION,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Current Location"),
    optionsState: currentLocationOptionsState,
    queryBuilder: getMultiSelectQueryString,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.PRODUCTION_DATE,
    label: (t) => t("inventory-view:Production Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    timezoneGetter: (props) => props.locationTimezone,
    isValueValid: isDateRangeValueValid,
    queryBuilder: getDateRangeStringForLocationTimezone,
  },
  {
    queryKey: QUERY_KEY_MAPPING.TIME_ON_SITE,
    label: (t) => t("inventory-view:Time on Site"),
    Component: (props) => {
      const { getTranslatedTimeOnSiteLabels } = useInventoryViewTranslation();
      // eslint-disable-next-line react/prop-types
      const translatedOptions = props?.options?.map((option) => ({
        value: option,
        label: getTranslatedTimeOnSiteLabels(option),
      }));

      return <SelectFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => Object.keys(timeOnSiteOptions),
    queryBuilder: (queryKey, filterValue) => {
      return getTimeOnSiteDateRangeQuery(queryKey, filterValue);
    },
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.DESTINATION,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Destination"),
    optionsState: destinationOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: QUERY_KEY_MAPPING.INITIAL_ETA,
    label: (t) => t("inventory-view:Initial ETA"),
    isMulti: true,
    Component: (props) => {
      const { getTranslatedInitialETALabels } = useInventoryViewTranslation();
      // eslint-disable-next-line react/prop-types
      const translatedOptions = props?.options?.map((option) => ({
        value: initialETAOptions[option],
        label: getTranslatedInitialETALabels(option),
      }));

      return <MultiSelectFilterButton {...props} options={translatedOptions} />;
    },
    optionsGetter: () => Object.keys(initialETAOptions),
    queryBuilder: getMultiSelectQueryString,
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.CARRIER,
    Component: AsyncSelectFilterButton,
    isMulti: true,
    label: (t) => t("inventory-view:Carrier"),
    optionsState: carrierOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
  {
    queryKey: QUERY_KEY_MAPPING.ARRIVAL_DATE,
    label: (t) => t("inventory-view:Arrival Date"),
    Component: DateRangeFilterButton,
    timezoneGetter: (props) => props.locationTimezone,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: getDateRangeStringForLocationTimezone,
  },
  {
    queryKey: QUERY_KEY_MAPPING.DEPARTURE_DATE,
    label: (t) => t("inventory-view:Departure Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    timezoneGetter: (props) => props.locationTimezone,
    isValueValid: isDateRangeValueValid,
    queryBuilder: getDateRangeStringForLocationTimezone,
  },
  {
    queryKey: QUERY_KEY_MAPPING.ORDER_TYPE,
    label: (t) => t("inventory-view:Order Type"),
    Component: AsyncSelectFilterButton,
    isMulti: true,
    optionsState: orderTypesOptionsState,
    queryBuilder: getMultiSelectQueryString,
  },
];
