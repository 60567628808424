export const BulkActionType = {
  BULK_SUBMIT: "Bulk Submit",
  BULK_APPROVE: "Bulk Approve",
  BULK_DENY: "Bulk Deny",
};

export const translateBulkActionType = (t, actionType) => {
  if (actionType) {
    switch (actionType) {
      case BulkActionType.BULK_SUBMIT:
        return t("driveaway-search:Bulk Submit");
      case BulkActionType.BULK_APPROVE:
        return t("driveaway-search:Bulk Approve");
      case BulkActionType.BULK_DENY:
        return t("driveaway-search:Bulk Deny");
      default:
        return actionType;
    }
  }
};

export const DPUStatus = {
  AVAILABLE: "Available",
  SUBMITTED: "Submitted",
  DENIED: "Denied",
  APPROVED: "Approved",
  DELIVERED: "Delivered",
  CANCELED: "Canceled",
  NOT_AVAILABLE: "Not Available",
  Preauthed: "Preauthed",
};

export const DPUsubStatus = {
  PERSISTENT: "Persistent",
};

export const FederatedUserEmailTrail = ".sso.freightverify.com";
