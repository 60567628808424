import moment from "moment/moment";
import Colors from "styles/colors";

export const getInitialEtaCellColorCode = (initialETA) => {
  let colorCode = Colors.text.BLACK;
  const currentTime = () => moment.utc();

  if (initialETA) {
    const initialEta = moment(initialETA);

    const upto48Hours = currentTime()
      .add(48, "hours")
      .subtract(1, "milliseconds");
    const _48Hours = currentTime().add(48, "hours");
    const upto96Hours = currentTime()
      .add(96, "hours")
      .subtract(1, "milliseconds");
    const _96Hours = currentTime().add(96, "hours");

    const isMissedFirstEta = () => initialEta < currentTime();
    const is0To47HoursOut = () =>
      initialEta >= currentTime() && initialEta < upto48Hours;
    const is48To95HoursOut = () =>
      initialEta >= _48Hours && initialEta < upto96Hours;
    const is96HoursPlusOut = () => initialEta >= _96Hours;

    switch (true) {
      case isMissedFirstEta():
        colorCode = Colors.inventoryView.initialETA.MISSED_FIRST_ETA;
        break;
      case is0To47HoursOut():
        colorCode = Colors.inventoryView.initialETA.HOURS_0_TO_47_OUT;
        break;
      case is48To95HoursOut():
        colorCode = Colors.inventoryView.initialETA.HOURS_48_TO_96_OUT;
        break;
      case is96HoursPlusOut():
        colorCode = Colors.inventoryView.initialETA.HOURS_96_PLUS_OUT;
        break;
    }
  }
  return colorCode;
};
