import moment from "moment/moment";

import { QUERY_KEY_MAPPING } from "../details/search/InventoryView.Details.Search.Options";
import { TimeOnSiteDuration } from "api/consts";
import { BrowserStorage } from "utils/browser-storage.utils";

export const InventoryInsightOnClickHandlers = (
  solutionId,
  location,
  setSearchFilter,
  resetSearchAndFilters,
  redirectToDetailsView,
) => {
  const locationId = location.locationId;
  const locationTimezone = location.timeZone ?? moment.tz.guess();

  // set location's date
  const locationDate = moment
    .tz(moment.utc(), locationTimezone)
    .format("YYYY-MM-DD");

  const dateToday = () => moment(locationDate);

  const initializeFilter = () => {
    resetSearchAndFilters();
    BrowserStorage.locationTimezone = locationTimezone;
  };

  return {
    currentInventory: () => {
      initializeFilter();
      setSearchFilter(QUERY_KEY_MAPPING.TIME_ON_SITE, TimeOnSiteDuration.ALL);
      redirectToDetailsView(solutionId, locationId);
    },
    daysOnSite: () => {
      initializeFilter();
      setSearchFilter(
        QUERY_KEY_MAPPING.TIME_ON_SITE,
        TimeOnSiteDuration.DAYS_10,
      );
      redirectToDetailsView(solutionId, locationId);
    },
    todaysForecastedArrival: () => {
      initializeFilter();
      const today = {
        from: dateToday().startOf("day").toISOString(),
        to: dateToday().endOf("day").toISOString(),
      };
      setSearchFilter(QUERY_KEY_MAPPING.FORECASTED_ARRIVAL, today);
      redirectToDetailsView(solutionId, locationId);
    },
    yesterdaysDeparture: () => {
      initializeFilter();
      const yesterday = {
        from: dateToday().subtract(1, "days").startOf("day").toISOString(),
        to: dateToday().startOf("day").toISOString(),
      };
      setSearchFilter(QUERY_KEY_MAPPING.DEPARTURE_DATE, yesterday);
      redirectToDetailsView(solutionId, locationId);
    },
  };
};
