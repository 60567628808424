/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import { faChartLine } from "@fortawesome/pro-regular-svg-icons";
import Colors from "styles/colors";

import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";
import { useOnsiteData } from "pages/inventoryview/react-queries/InventoryViewDonutChartHooks";
import { getColors, getParams } from "pages/inventoryview/utils/chart.utils";
import {
  useInventoryGraph,
  useDepartureGraph,
  useArrivalGraph,
} from "pages/inventoryview/react-queries/InventoryViewHooks";
import moment from "moment";
import { extractCounts } from "pages/inventoryview/utils/count.utils";
import { Alert } from "components/atoms/Alert.atom";
import { InventoryInsightOnClickHandlers } from "../../shared/InventoryInsightOnClickHandler";

const metricsDivCss = {
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: "1em",
  gridAutoColumns: "minmax(max-content, auto)",
  marginBottom: "1em",
};

const metricsTileCss = {
  padding: "10px 16px",
  borderRadius: "4px",
  backgroundColor: Colors.inventoryView.LIGHTER_GRAY,
  cursor: "pointer",
  ":hover": {
    "box-shadow": "rgba(0, 0, 0, 0.6) 0px 1px 6px -2px",
  },
};

export const InventoryLocationPanel = ({
  solutionId,
  location,
  redirectToInsightsView,
  redirectToDetailsView,
  setSearchFilter,
  resetSearchAndFilters,
  orgFvId,
  isShipperOrg,
}) => {
  const { t } = useTranslation("inventory-view");
  const locationTimezone = location.timeZone ?? moment.tz.guess();
  const locationId = location.locationId;

  // set location's date
  const locationDate = moment
    .tz(moment.utc(), locationTimezone)
    .format("YYYY-MM-DD");

  // set today's date
  const todaysDate = moment(locationDate);
  const inventoryDateWindow = 0;
  const arrivalDateWindow = 0;
  const departureDataWindow = 1;

  // get params
  const { inventoryParams, arrivalsParams, departureParams } = getParams(
    locationId,
    locationTimezone,
    orgFvId,
    isShipperOrg,
    inventoryDateWindow,
    arrivalDateWindow,
    departureDataWindow,
  );

  const {
    transformedData: inventoryChartData,
    isFetching: isInventoryChartDataFetching,
    isError: isInventoryChartDataFetchingError,
  } = useInventoryGraph(inventoryParams);

  const {
    transformedData: departuresChartData,
    isFetching: isDeparturesChartDataFetching,
    isError: isDeparturesChartDataFetchingError,
  } = useDepartureGraph(departureParams);

  const {
    transformedData: arrivalGraphData,
    isFetching: isArrivalGraphDataFetching,
    isError: isArrivalGraphDataFetchingError,
  } = useArrivalGraph(arrivalsParams);

  // Retrieve count from requested date
  const getDeparturesCount = useCallback(
    (departuresChartData, requestedDate) => {
      const yesterdaysActualDepartures = departuresChartData.data.filter(
        (data) => data.x === requestedDate,
      );

      return yesterdaysActualDepartures[0]?.y ?? 0;
    },
    [],
  );

  // Retrieve count from previous day
  const actualDeparturesYesterday = useMemo(() => {
    const yesterdaysDate = moment(todaysDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    if (departuresChartData.data.length !== 0) {
      return getDeparturesCount(departuresChartData, yesterdaysDate);
    }
  }, [getDeparturesCount, departuresChartData, todaysDate]);

  const isError =
    isArrivalGraphDataFetchingError &&
    isInventoryChartDataFetchingError &&
    isDeparturesChartDataFetchingError;

  // extract counts from data
  const { todaysInventory, todaysArrivalsCount, fivePercentOfTotalCapacity } =
    extractCounts(
      inventoryChartData,
      isArrivalGraphDataFetchingError,
      arrivalGraphData,
      departuresChartData,
    );

  const { data: donutChartsData } = useOnsiteData({
    locationId: location.locationId,
  });

  const tenDaysOnSite = donutChartsData.timeOnSiteData.filter(
    (item) => item.key === "days10_inf",
  );

  const inventoryInsightOnClickHandlers = InventoryInsightOnClickHandlers(
    solutionId,
    location,
    setSearchFilter,
    resetSearchAndFilters,
    redirectToDetailsView,
  );

  return (
    <PanelGroup>
      {
        <Fragment>
          <PanelGroup.Header
            title={`(${location.code}) ${location.name}`}
            titleSize={FontSize.size16}
          />
          <PanelGroup.Content
            style={{
              minHeight: 200,
            }}
          >
            {!isError ? (
              <Loader
                loaded={
                  !isArrivalGraphDataFetching &&
                  !isInventoryChartDataFetching &&
                  !isDeparturesChartDataFetching
                }
              >
                <div css={{ ...metricsDivCss }}>
                  <div
                    css={{ ...metricsTileCss }}
                    onClick={inventoryInsightOnClickHandlers.currentInventory}
                  >
                    <Text
                      color={getColors(
                        "inventory",
                        todaysInventory,
                        departuresChartData.capacity,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber value={todaysInventory} />
                      {departuresChartData.capacity !== 0 &&
                        departuresChartData.capacity && (
                          <Fragment>
                            /
                            <LocalizedNumber
                              value={departuresChartData.capacity}
                            />
                          </Fragment>
                        )}
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:Current Inventory")}
                    </Text>
                  </div>
                  <div
                    css={{ ...metricsTileCss }}
                    onClick={inventoryInsightOnClickHandlers.daysOnSite}
                  >
                    <Text
                      color={getColors(
                        "daysOnSite",
                        tenDaysOnSite[0].non_shippable_vins +
                          tenDaysOnSite[0].vins_associated,
                        fivePercentOfTotalCapacity,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber
                        value={
                          tenDaysOnSite[0].non_shippable_vins +
                          tenDaysOnSite[0].vins_associated
                        }
                      />
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:10+ Days on Site")}
                    </Text>
                  </div>
                </div>
                <div css={{ ...metricsDivCss }}>
                  <div
                    css={{ ...metricsTileCss }}
                    onClick={
                      inventoryInsightOnClickHandlers.todaysForecastedArrival
                    }
                  >
                    <Text
                      color={getColors(
                        "forecastedArrivals",
                        todaysArrivalsCount,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber value={todaysArrivalsCount} />
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:Today's Forecasted Arrivals")}
                    </Text>
                  </div>
                  <div
                    css={{ ...metricsTileCss }}
                    onClick={
                      inventoryInsightOnClickHandlers.yesterdaysDeparture
                    }
                  >
                    <Text
                      color={getColors(
                        "departures",
                        actualDeparturesYesterday,
                        departuresChartData.primaryDepartureTarget,
                      )}
                      size={FontSize.size18}
                      bold="true"
                      as="div"
                    >
                      <LocalizedNumber value={actualDeparturesYesterday} />
                      {departuresChartData.primaryDepartureTarget !== 0 &&
                        departuresChartData.primaryDepartureTarget && (
                          <Fragment>
                            /
                            <LocalizedNumber
                              value={departuresChartData.primaryDepartureTarget}
                            />
                          </Fragment>
                        )}
                    </Text>
                    <Text
                      size={FontSize.size12}
                      as="div"
                      color={Colors.text.DARK_GRAY}
                    >
                      {t("inventory-view:Yesterday's Departures")}
                    </Text>
                  </div>
                </div>
                <div css={{ textAlign: "center" }}>
                  <Button
                    variant="primary"
                    onClick={() => redirectToInsightsView(location?.locationId)}
                  >
                    {t("inventory-view:Expand Metrics")}{" "}
                    <Icon src={faChartLine}></Icon>
                  </Button>
                </div>
              </Loader>
            ) : null}
            <Alert show={isError} variant="danger">
              {t(
                "inventory-view:Unable to fetch details. Please try again later.",
              )}
            </Alert>
          </PanelGroup.Content>
        </Fragment>
      }
    </PanelGroup>
  );
};

InventoryLocationPanel.propTypes = {
  solutionId: PropTypes.string,
  location: PropTypes.object,
  redirectToInsightsView: PropTypes.func,
  redirectToDetailsView: PropTypes.func,
  setSearchFilter: PropTypes.func,
  resetSearchAndFilters: PropTypes.func,
  orgFvId: PropTypes.string,
  isShipperOrg: PropTypes.bool,
};
