/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Fragment, Component } from "react";
import { useDispatch } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import _ from "lodash";
import styled from "@emotion/styled";
import { keyframes, css } from "@emotion/react";
import moment from "moment-timezone";
import GeofenceType, {
  isFenceValid,
  getType as getGeofenceType,
} from "modules/geofence-edit/geofence-types";
import { getDefaultLocation } from "pages/administration/location-management/redux/Locations.state";
import LocationEditForm from "./components/LocationNewOrEditForm";
import LocationEditFormDPU from "./components/LocationNewOrEditFormDPU";
import GeofenceBuilderMap from "modules/map/components/GeofenceBuilderMap";
import Colors from "styles/colors";
import Loader from "react-loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { faPlusCircle, faMinusCircle } from "@fortawesome/pro-solid-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import { Text, FontSize } from "components/atoms/Text.atom";
import { Button } from "components/atoms/Button.atom";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import {
  Tabs,
  TabHeight,
  TabsClassNames,
} from "components/molecules/Tabs.molecule";
import { FlexDiv, FlexRowDiv, FlexColDiv } from "styles/container-elements";
import { geofenceWithUpdatedCoordinates } from "modules/geofence-edit/geofence-coordinates";
import { DeleteLocationModal } from "./modals/DeleteLocationModal";
import { DeletePolygonModal } from "./modals/DeletePolygonModal";
import { UpdateGeofenceTypeModal } from "./modals/UpdateGeofenceTypeModal";
import { Privileges } from "modules/auth/Authorization";
import { geocode } from "modules/map/utils/geocoding";
import { isCarrier, isShipper } from "shared/utils/organizations.utils";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { getColumns } from "./LocationManagement.LocationNewOrEdit.columns";
import MixpanelUtils from "trackers/mixpanel";
import { Icon } from "components/atoms/Icon.atom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Features } from "modules/auth/Authorization";
import { LobsType } from "shared/utils/lads.utils";
import {
  DATA_INPUT_TYPE_ERROR_TYPES,
  validateInput,
  validateLocationOrgData,
} from "../utils/field_validation";
import { Tooltip } from "components/atoms/Tooltip.atom";

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const Section = styled.section({
  display: "flex",
  flex: 0.5,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh - 3.3em)",
  backgroundColor: "white",
  position: "relative",
});

/**
 *
 * @param isValid
 * @param field
 * @return {*}
 * @constructor
 */
const UnclassifiedLinkBanner = (props) => {
  const { locationId, parentLocationCode, parentLocationName } = props;

  const { t } = useTranslation("location-edit");
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        gap: "1em",
        padding: "0.5em 0.25em",
      }}
    >
      <Icon color={Colors.text.WHITE} src={faInfoCircle}></Icon>
      <Text
        color={Colors.text.WHITE}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "1em",
        }}
      >
        {parentLocationCode && parentLocationName ? (
          <Fragment>
            {/* If there is a :, we likely want to split the translated text. */}
            {`${t(
              "location-edit:This is an Unclassified Child Shipper Location that is linked to parent location",
            )}: ${parentLocationName} (${t(
              "location-edit:location code",
            )}: ${parentLocationCode})`}
          </Fragment>
        ) : (
          <Fragment>
            {t(
              'location-edit:This is an Unclassified Shipper Location. In order to link this address to an existing shipper location please click the "Link" button',
            )}
            <Button
              style={{
                alignSelf: "flex-end",
                color: Colors.text.BLACK,
                backgroundColor: Colors.text.WHITE,
              }}
              onClick={() => {
                dispatch({
                  type: "LOCATION_LINKING",
                  payload: { location_id: locationId },
                });
              }}
            >
              {t("Link")}
            </Button>
          </Fragment>
        )}
      </Text>
    </div>
  );
};

UnclassifiedLinkBanner.propTypes = {
  locationId: PropTypes.any,
  parentLocationCode: PropTypes.string,
  parentLocationName: PropTypes.string,
};

const ValidationRow = ({ isValid, field }) => {
  const { t } = useTranslation("location-edit");

  return (
    <div css={{ display: "flex", width: "50%", marginBottom: ".25em" }}>
      <FontAwesomeIcon
        style={{
          color: isValid ? Colors.highlight.GREEN : Colors.background.GRAY,
          fontSize: "large",
        }}
        icon={faCheckCircle}
      />
      <span css={{ fontSize: "small", marginLeft: ".5em" }}>
        {`${field} ${t("location-edit:is required")}.`}
      </span>
    </div>
  );
};

ValidationRow.propTypes = {
  field: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};

/**
 *
 * @param existingData
 * @param lat
 * @param lng
 * @return {*}
 */
const mergeLatLng = (existingData, lat, lng) => {
  const data = _.cloneDeep(existingData);
  let geoDiff;

  if (getGeofenceType(data.geofence) === GeofenceType.RADIAL) {
    geoDiff = {
      geometry: {
        coordinates: [lng, lat],
      },
      properties: {
        center: {
          latitude: lat,
          longitude: lng,
        },
      },
    };
  } else {
    geoDiff = {
      properties: {
        center: {
          latitude: lat,
          longitude: lng,
        },
      },
    };
  }
  _.merge(data, { geofence: geoDiff });
  return data;
};

export const LastUpdatedLabel = ({ t, lastUpdateTimestring, lastUpdater }) => {
  const lastUpdated = moment
    .duration(moment(lastUpdateTimestring).diff(moment()))
    .humanize(true);

  return lastUpdateTimestring ? (
    <Fragment>
      <Text bold block color={Colors.text.GRAY} size={FontSize.size14}>
        {t("geofence-edit:Location Last Updated")}:
      </Text>
      <Text block color={Colors.text.DARK_BLUE} size={FontSize.size12}>
        {lastUpdated}
      </Text>
      <Text block color={Colors.text.DARK_BLUE} size={FontSize.size12}>
        {t("geofence-edit:by")} {lastUpdater}
      </Text>
    </Fragment>
  ) : null;
};

LastUpdatedLabel.propTypes = {
  t: PropTypes.func.isRequired,
  lastUpdateTimestring: PropTypes.string,
  lastUpdater: PropTypes.string,
};

class LocationEditView extends Component {
  static propTypes = {
    isCreateMode: PropTypes.bool,
    authorization: PropTypes.object,
    isFvAdmin: PropTypes.bool,
    actionStatus: PropTypes.any,
    activeOrganization: PropTypes.object.isRequired,
    addAndLinkLocation: PropTypes.func.isRequired,
    addLocation: PropTypes.func.isRequired,
    clearActionStatus: PropTypes.func.isRequired,
    clearSubdivisions: PropTypes.func.isRequired,
    countries: PropTypes.array,
    deleteLocation: PropTypes.func.isRequired,
    editLocation: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchHeatMapData: PropTypes.func.isRequired,
    fetchLocationDetails: PropTypes.any.isRequired,
    fetchDdaLocationDetails: PropTypes.any.isRequired,
    fetchInventoryViewLocationDetails: PropTypes.any.isRequired,
    fetchDamageViewLocationDetails: PropTypes.any.isRequired,
    fetchCriticalPartsLocationDetails: PropTypes.any.isRequired,
    fetchSubdivisions: PropTypes.func.isRequired,
    googleMaps: PropTypes.object.isRequired,
    heatmapData: PropTypes.array,
    heatmapIsLoading: PropTypes.bool,
    hereMapsPlatform: PropTypes.object,
    lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    locationLinked: PropTypes.bool.isRequired,
    isLocationSaving: PropTypes.bool,
    isConfigSaving: PropTypes.bool,
    isInventoryViewLocationDetailsLoading: PropTypes.bool,
    isDamageViewLocationConfigLoading: PropTypes.bool,
    isCriticalPartsLocationConfigLoading: PropTypes.bool,
    isScacListLoading: PropTypes.bool,
    scacListData: PropTypes.array,
    isEnableDpuLoading: PropTypes.bool,
    isPersistentApprovalEnabled: PropTypes.bool,
    locationSaved: PropTypes.bool.isRequired,
    mapTypeOverride: PropTypes.string,
    mapPlatform: PropTypes.string,
    pushLocationsScreen: PropTypes.func.isRequired,
    pushShipmentDetailView: PropTypes.func.isRequired,
    selectedLocation: PropTypes.object,
    setLocationLinked: PropTypes.func.isRequired,
    setLocationSaved: PropTypes.func.isRequired,
    subdivisions: PropTypes.array.isRequired,
    subdivisionsCountryCode: PropTypes.string,
    t: PropTypes.func.isRequired,
    unresolvedLocation: PropTypes.object,
    unresolvedShipment: PropTypes.bool,
    setTitle: PropTypes.func.isRequired,
    setSubTitle: PropTypes.func.isRequired,
    fetchLocationChildren: PropTypes.func,
    locationChildren: PropTypes.array,
    isLoadingLocationChildren: PropTypes.bool,
    locationChildrenPage: PropTypes.number,
    locationChildrenPageSize: PropTypes.number,
    locationChildrenTotalPages: PropTypes.number,
    setLocationChildrenPagination: PropTypes.func,
    invLocationData: PropTypes.object,
    previousInvLocationData: PropTypes.object,
    dvLocationData: PropTypes.object,
    cpLocationData: PropTypes.object,
    dpuAddLocationData: PropTypes.object,
    ddaLocationData: PropTypes.object,
    updateDdaLocationDetails: PropTypes.func,
    fetchDdaSubdivisions: PropTypes.func,
    ddaSubdivisions: PropTypes.object,
    locationLadChanged: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: getDefaultLocation(),
      ddaLocationData: {},
      invLocationData: {
        isAddLocationForInventoryViewEnabled:
          props.invLocationData.isAddLocationForInventoryViewEnabled ?? false,
        capacity: props.invLocationData.capacity ?? null,
        primaryDepartureTarget:
          props.invLocationData.primaryDepartureTarget ?? null,
        secondaryDepartureTarget:
          props.invLocationData.secondaryDepartureTarget ?? null,
        orgLevelLocationInformation:
          props.invLocationData.orgLevelLocationInformation ?? [],
      },
      dvLocationData: {
        isDamageViewAddLocationEnabled:
          props.dvLocationData.isDamageViewAddLocationEnabled ?? false,
      },
      prevDvLocationData: {
        isDamageViewAddLocationEnabled:
          props.dvLocationData.isDamageViewAddLocationEnabled ?? false,
      },
      cpLocationData: {
        isCriticalPartsAddLocationEnabled:
          props.cpLocationData.isCriticalPartsAddLocationEnabled ?? false,
      },
      prevCpLocationData: {
        isCriticalPartsAddLocationEnabled:
          props.cpLocationData.isCriticalPartsAddLocationEnabled ?? false,
      },
      dpuAddLocationData: {
        isDpuAddLocationEnabled:
          props.dpuAddLocationData?.isDpuAddLocationEnabled ?? false,
      },
      prevDpuAddLocationData: {
        isDpuAddLocationEnabled:
          props.dpuAddLocationData?.isDpuAddLocationEnabled ?? false,
      },
      typingTimeout: 0,
      showHeatmap: false,
      showDeleteLocationModal: false,
      isTracing: false,
      tracePoints: [],
      editingPolygonIndex: null,
      showDeletePolygonModal: false,
      showUpdateGeofenceTypeModal: false,
      showDdaConfirmationDialog: false,
      showError: false,
      scacModificationList: props.scacListData ?? [],
      isPersistentApprovalEnabled: props.isPersistentApprovalEnabled ?? false,
    };

    this.onDragPolygonalGeofence = this.onDragPolygonalGeofence.bind(this);
    this.onDragRadialGeofence = this.onDragRadialGeofence.bind(this);
    this.onDragGeofenceControlPoint =
      this.onDragGeofenceControlPoint.bind(this);

    this.addPointToTrace = this.addPointToTrace.bind(this);
    this.addPolygon = this.addPolygon.bind(this);
    this.cancelTracing = this.cancelTracing.bind(this);
    this.createPolygonFromTrace = this.createPolygonFromTrace.bind(this);
    this.deletePointFromPolygon = this.deletePointFromPolygon.bind(this);
    this.updatePolygonPoints = this.updatePolygonPoints.bind(this);
    this.deletePointFromTrace = this.deletePointFromTrace.bind(this);
    this.deletePolygonConfirm = this.deletePolygonConfirm.bind(this);
    this.deletePolygon = this.deletePolygon.bind(this);
    this.showUpdateGeofenceTypeModal =
      this.showUpdateGeofenceTypeModal.bind(this);
    this.updateGeofence = this.updateGeofence.bind(this);
    this.updateGeofenceType = this.updateGeofenceType.bind(this);
  }

  componentDidMount() {
    const {
      t,
      isCreateMode,
      fetchLocationDetails,
      fetchDdaLocationDetails,
      fetchInventoryViewLocationDetails,
      fetchDamageViewLocationDetails,
      fetchCriticalPartsLocationDetails,
      fetchHeatMapData,
      locationId,
      fetchCountries,
      activeOrganization,
      fetchLocationChildren,
      setTitle,
    } = this.props;

    const title = isCreateMode
      ? t("location-edit:New Location")
      : t("location-edit:Edit Location");

    setTitle(title);

    MixpanelUtils.track(
      `Viewed Page: Location Management / ${
        isCreateMode ? "New" : "Edit"
      } Location`,
    );

    this.props.setLocationSaved(false);
    this.props.setLocationLinked(false);

    fetchCountries();
    this.updateSubdivisions();
    fetchLocationChildren(locationId, true);

    // Do not fetch the heatmap if
    // we are in add mode
    if (!isCreateMode) {
      const dereference = isCarrier(activeOrganization);
      fetchLocationDetails(locationId, dereference);
      fetchDdaLocationDetails(locationId);
      fetchInventoryViewLocationDetails(locationId);
      fetchDamageViewLocationDetails(locationId);
      fetchCriticalPartsLocationDetails(locationId);
      fetchHeatMapData(locationId);
    }

    this.setData(this.props);
  }

  componentWillUnmount() {
    const { clearActionStatus } = this.props;
    clearActionStatus();
    this.setState({ data: getDefaultLocation() });
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (!_.isEqual(nextProps.invLocationData, prevProps.invLocationData)) {
      this.setState({
        invLocationData: {
          isAddLocationForInventoryViewEnabled:
            this.props.invLocationData.isAddLocationForInventoryViewEnabled ??
            false,
          capacity: this.props.invLocationData.capacity ?? null,
          primaryDepartureTarget:
            this.props.invLocationData.primaryDepartureTarget ?? null,
          secondaryDepartureTarget:
            this.props.invLocationData.secondaryDepartureTarget ?? null,
          orgLevelLocationInformation:
            this.props.invLocationData.orgLevelLocationInformation ?? [],
        },
      });
    }

    if (!_.isEqual(nextProps.dvLocationData, prevProps.dvLocationData)) {
      this.setState({
        dvLocationData: {
          isDamageViewAddLocationEnabled:
            this.props.dvLocationData.isDamageViewAddLocationEnabled ?? false,
        },
        prevDvLocationData: this.props.dvLocationData,
      });
    }

    if (!_.isEqual(nextProps.cpLocationData, prevProps.cpLocationData)) {
      this.setState({
        cpLocationData: {
          isCriticalPartsAddLocationEnabled:
            this.props.cpLocationData.isCriticalPartsAddLocationEnabled ??
            false,
        },
        prevCpLocationData: this.props.cpLocationData,
      });
    }

    if (
      !_.isEqual(nextProps.dpuAddLocationData, prevProps.dpuAddLocationData)
    ) {
      this.setState({
        dpuAddLocationData: {
          isDpuAddLocationEnabled:
            this.props.dpuAddLocationData?.isDpuAddLocationEnabled ?? false,
        },
        prevDpuAddLocationData: this.props.dpuAddLocationData,
      });
    }

    if (!_.isEqual(nextProps.scacListData, prevProps.scacListData)) {
      this.setState({
        scacModificationList: this.props.scacListData,
      });
    }

    if (
      !_.isEqual(
        nextProps.isPersistentApprovalEnabled,
        prevProps.isPersistentApprovalEnabled,
      )
    ) {
      this.setState({
        isPersistentApprovalEnabled: this.props.isPersistentApprovalEnabled,
      });
    }

    if (nextProps.selectedLocation !== prevProps.selectedLocation) {
      this.setData(nextProps);
      this.updateSubdivisions();
    }

    // If we have an unresolved location, look for the linked flag to toggle
    if (_.isEmpty(nextProps.unresolvedLocation) === false) {
      if (
        nextProps.locationLinked === true &&
        prevProps.locationLinked === false
      ) {
        // PUSH shipment screen OR locations screen
        const shipmentID = nextProps.unresolvedShipment
          ? nextProps.unresolvedShipment.id
          : null;
        if (shipmentID) {
          this.props.pushShipmentDetailView(shipmentID);
        } else {
          // Above we check that the unresolvedLocation is not empty
          // and that we have linked the location.
          // That means we push the unresolved locations screen.
          this.props.pushLocationsScreen(true);
        }
      }
    } else {
      // Normal operation, we will trap on the location saved flag to to
      // return to the previous page
      if (
        nextProps.locationSaved === true &&
        prevProps.locationSaved === false
      ) {
        this.props.pushLocationsScreen();
      }
    }
  }

  getCurrentCountry() {
    const currentSelectedCountry = this.props.selectedLocation
      ? this.props.selectedLocation.country
      : null;
    const currentUnresolvedCountry = this.props.unresolvedLocation
      ? this.props.unresolvedLocation.country
      : null;
    return currentSelectedCountry || currentUnresolvedCountry;
  }

  setData(newProps) {
    const {
      isCreateMode,
      unresolvedLocation,
      fetchSubdivisions,
      clearSubdivisions,
    } = this.props;
    const { selectedLocation, actionStatus } = newProps;
    const currentCountry = this.getCurrentCountry();

    let locationData =
      !isCreateMode && !_.isEmpty(selectedLocation) ? selectedLocation : null;

    // If we are adding a new location AND location data is undefined
    // check for an unresolved location element, if so we will
    // use the unresolved data to seed our display
    if (
      isCreateMode &&
      locationData === null &&
      !_.isEmpty(unresolvedLocation)
    ) {
      // Extract the necessary fields from this location,
      // do not pull all the fields, this causes conflicts
      // when trying to create
      locationData = {
        address: unresolvedLocation.address,
        city: unresolvedLocation.city,
        code: unresolvedLocation.code,
        country: unresolvedLocation.country,
        geofence: unresolvedLocation.geofence,
        name: unresolvedLocation.name,
        postal_code: unresolvedLocation.postal_code,
        state: unresolvedLocation.state,
      };
    }

    // combine codes into a single field for editing
    if (locationData != null) {
      //H1-1037 - some locations do not have a country code saved to them
      if (!locationData.country) {
        // clear subdivision options
        clearSubdivisions();
      } else if (locationData.country !== currentCountry) {
        fetchSubdivisions(locationData.country);
      }
      const data = { ...locationData };
      this.setState({ data });
      // Set the sub-title based on the existing location name
      this.setPageSubTitle(data.name);
    } else if (actionStatus !== "Duplicate_Location") {
      // reset data if we are not adding a new location
      if (!isCreateMode) {
        const data = getDefaultLocation();
        this.setState({ data });
      }
    }
  }

  updateSubdivisions() {
    const { subdivisions, subdivisionsCountryCode, fetchSubdivisions } =
      this.props;
    const currentCountry = this.getCurrentCountry();
    if (!currentCountry) {
      return;
    }
    if (_.isEmpty(subdivisions) || currentCountry !== subdivisionsCountryCode) {
      fetchSubdivisions(currentCountry);
    }
  }

  isDoneTyping() {
    return Date.now() - this.state.lastKeyTimestamp > 1000;
  }

  onDragPolygonalGeofence(polygonIndex, deltaLat, deltaLng) {
    const { data, isTracing } = this.state;
    const { geofence } = data;

    if (isTracing) {
      return;
    }

    const fenceType = getGeofenceType(geofence);
    let newGeofence;

    if (fenceType === GeofenceType.POLYGONAL) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let coordsCopy = _.cloneDeep(geofence.geometry.coordinates);

      let newCoords = coordsCopy[0].map((point) => {
        return [point[0] - deltaLng, point[1] - deltaLat];
      });
      newGeofence = geofenceWithUpdatedCoordinates(geofence, [newCoords]);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      let polygonsCopy = _.cloneDeep(geofence.geometry);
      let newCoords = polygonsCopy[polygonIndex].geometry.coordinates[0].map(
        (point) => {
          return [point[0] - deltaLng, point[1] - deltaLat];
        },
      );
      polygonsCopy[polygonIndex].geometry.coordinates.splice(0, 1, newCoords);
      newGeofence = geofenceWithUpdatedCoordinates(geofence, polygonsCopy);
    }

    this.updateGeofence(newGeofence);
  }

  onDragRadialGeofence(deltaLat, deltaLng) {
    const { data } = this.state;
    const { geofence } = data;

    const newCoords = [
      geofence.geometry.coordinates[0] - deltaLng,
      geofence.geometry.coordinates[1] - deltaLat,
    ];

    const newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    this.updateGeofence(newGeofence);
  }

  onDragGeofenceControlPoint(polygonIndex, pointIndex, coords) {
    const { data, isTracing } = this.state;
    const { geofence } = data;

    if (isTracing) {
      return;
    }

    const fenceType = getGeofenceType(geofence);

    let newGeofence;

    if (fenceType === GeofenceType.POLYGONAL) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = _.cloneDeep(geofence.geometry.coordinates);

      newCoords[0][pointIndex] = [coords.lng, coords.lat];
      if (pointIndex === 0) {
        newCoords[0][newCoords[0].length - 1] = [coords.lng, coords.lat];
      }

      newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let polygonsCopy = _.cloneDeep(geofence.geometry);

      polygonsCopy[polygonIndex].geometry.coordinates[0][pointIndex] = [
        coords.lng,
        coords.lat,
      ];
      if (pointIndex === 0) {
        polygonsCopy[polygonIndex].geometry.coordinates[0][
          polygonsCopy[polygonIndex].geometry.coordinates[0].length - 1
        ] = [coords.lng, coords.lat];
      }

      newGeofence = geofenceWithUpdatedCoordinates(geofence, polygonsCopy);
    }

    this.updateGeofence(newGeofence);
  }

  deletePointFromPolygon(polygonIndex, pointIndex) {
    const { geofence } = this.state.data;
    const fenceType = getGeofenceType(geofence);

    let newGeofence;

    // Prompt the user to delete this polygon if less than 3 points would remain
    // after deleting the last one. Remember that we have one extra point at the end
    // mirroring the first one.
    if (fenceType === GeofenceType.POLYGONAL) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let coordsCopy = _.cloneDeep(geofence.geometry.coordinates);

      if (coordsCopy[0].length <= 4) {
        this.deletePolygonConfirm(polygonIndex);
      } else {
        coordsCopy[0].splice(pointIndex, 1);
      }

      newGeofence = geofenceWithUpdatedCoordinates(geofence, coordsCopy);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let polygonCopy = _.cloneDeep(geofence.geometry);

      if (polygonCopy[polygonIndex].geometry.coordinates[0].length <= 4) {
        this.deletePolygonConfirm(polygonIndex);
      } else {
        polygonCopy[polygonIndex].geometry.coordinates[0].splice(pointIndex, 1);
      }

      newGeofence = geofenceWithUpdatedCoordinates(geofence, polygonCopy);
    }

    this.updateGeofence(newGeofence);
  }

  updatePolygonPoints(polygonIndex, newPoints) {
    const { geofence } = this.state.data;
    const fenceType = getGeofenceType(geofence);

    let newGeofence;

    if (fenceType === GeofenceType.POLYGONAL) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = _.cloneDeep(geofence.geometry.coordinates);

      newCoords[0] = newPoints;

      newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = _.cloneDeep(geofence.geometry);

      newCoords[polygonIndex].geometry.coordinates[0] = newPoints;

      newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    }

    this.updateGeofence(newGeofence);
  }

  addPointToTrace(position) {
    const { isTracing, tracePoints } = this.state;

    if (isTracing) {
      let newPoints = tracePoints || [];
      newPoints.push([position.lng, position.lat]);
      this.setState({ tracePoints: newPoints });
    }
  }

  deletePointFromTrace(pointIndex) {
    const { isTracing, tracePoints } = this.state;

    if (isTracing) {
      let newPoints = tracePoints.slice();
      newPoints.splice(pointIndex, 1);
      this.setState({ tracePoints: newPoints });
    }
  }

  createPolygonFromTrace() {
    const { data, isTracing, tracePoints } = this.state;
    const { geofence } = data;

    if (isTracing && tracePoints.length > 2) {
      let newGeofence;

      const fenceType = getGeofenceType(geofence);
      if (fenceType === GeofenceType.POLYGONAL) {
        // Using a slice of the coordinates is not enough for poly geofences since they're
        // deeply nested arrays and slice just does a shallow copy.
        let newCoords = _.cloneDeep(geofence.geometry.coordinates);

        // Preserve first-last mirror
        newCoords = [...tracePoints, tracePoints[0]];
        newGeofence = geofenceWithUpdatedCoordinates(geofence, [newCoords]);
      } else if (fenceType === GeofenceType.MULTIPOLYGON) {
        // Using a slice of the coordinates is not enough for poly geofences since they're
        // deeply nested arrays and slice just does a shallow copy.
        let newPolygons = _.cloneDeep(geofence.geometry);

        let lastPoly =
          newPolygons[newPolygons.length - 1].geometry.coordinates[0];
        // Preserve first-last mirror
        lastPoly.push(...tracePoints, tracePoints[0]);
        newGeofence = geofenceWithUpdatedCoordinates(geofence, newPolygons);
      }

      this.setState({
        isTracing: false,
        editingPolygonIndex: null,
        tracePoints: [],
        data: { ...data, geofence: newGeofence },
      });
    }
  }

  toggleEditGeofence() {
    const { isTracing } = this.state;
    this.setState({ isTracing: !isTracing });
  }

  updateGeofence(newGeofence) {
    const { authorization } = this.props;

    if (
      authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]) ||
      authorization.hasPrivileges([Privileges.MANAGE_GEOFENCE_NAME])
    ) {
      const { data } = this.state;
      const mergedData = {
        ...data,
        geofence: newGeofence,
      };
      this.setState({ data: mergedData });
    }
  }

  addPolygon() {
    const { geofence } = this.state.data;
    const fenceType = getGeofenceType(geofence);

    let newGeofence;
    if (fenceType === GeofenceType.POLYGONAL) {
      newGeofence = geofenceWithUpdatedCoordinates(geofence, [[[]]]);

      this.setState({
        editingPolygonIndex: newGeofence.geometry.coordinates.length - 1,
      });
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = geofence?.geometry ? _.cloneDeep(geofence.geometry) : [];
      newCoords.push({
        geometry: {
          coordinates: [[]],
          type: "Polygon",
        },
        name: null,
        // Get the highest sequence value and add 1 so it sorts correctly
        sequence:
          !geofence.geometry || geofence.geometry.length === 0
            ? 1
            : Math.max.apply(
                Math,
                geofence.geometry.map((p) => p.sequence),
              ) + 1,
      });

      newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);

      this.setState({
        editingPolygonIndex: newGeofence.geometry.length - 1,
      });
    }

    this.updateGeofence(newGeofence);
    this.toggleEditGeofence();
  }

  deletePolygonConfirm(polygonIndex) {
    this.setState({
      showDeletePolygonModal: true,
      editingPolygonIndex: polygonIndex,
    });
  }

  deletePolygon(polygonIndex) {
    const { geofence } = this.state.data;

    let newGeofence;
    const fenceType = getGeofenceType(geofence);

    if (fenceType === GeofenceType.POLYGONAL) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = _.cloneDeep(geofence.geometry.coordinates);
      newCoords.splice(polygonIndex, 1);

      newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newPolygons = _.cloneDeep(geofence.geometry);
      newPolygons.splice(polygonIndex, 1);

      newGeofence = geofenceWithUpdatedCoordinates(geofence, newPolygons);
    }
    this.updateGeofence(newGeofence);

    // Close the Delete poly modal.
    this.setState({ showDeletePolygonModal: false });
  }

  showUpdateGeofenceTypeModal() {
    this.setState({ showUpdateGeofenceTypeModal: true });
  }

  updateGeofenceType(newType) {
    this.setState({ showUpdateGeofenceTypeModal: false });
    // Type didn't change, bail.
    if (!newType) {
      return;
    }
    const { isCreateMode } = this.props;
    const { geofence } = this.state.data;

    const fenceType = getGeofenceType(geofence);

    let typeConverterName;

    if (newType.displayName.toLowerCase() !== "multipolygon") {
      typeConverterName = `to${_.capitalize(newType.displayName)}`;
    } else {
      typeConverterName = "toMultiPolygon";
    }

    const typeConverter = fenceType[typeConverterName];

    let newGeofence = typeConverter(geofence);
    const newFenceType = getGeofenceType(newGeofence);

    // If this is a new location of poly type, clear the dummy coordinates and enable
    // tracing mode.
    if (isCreateMode && newFenceType === GeofenceType.POLYGONAL) {
      this.setState({ isTracing: true });
      const emptyCoords = [[]];
      newGeofence.geometry.coordinates = emptyCoords;
      this.updateGeofence(newGeofence, () => {
        this.addPolygon();
      });
    } else if (isCreateMode && newFenceType === GeofenceType.MULTIPOLYGON) {
      this.setState({ isTracing: true });
      const emptyCoords = [[]];
      const newGeometry = {
        geometry: { coordinates: emptyCoords, type: "Polygon" },
        name: null,
        sequence: 1,
      };
      newGeofence.geometry = [];
      newGeofence.geometry.push(newGeometry);
      this.updateGeofence(newGeofence, () => {
        this.addPolygon();
      });
    } else {
      this.updateGeofence(newGeofence);
    }
  }

  cancelTracing() {
    const { data } = this.state;
    const { geofence } = data;

    this.setState({
      isTracing: false,
      tracePoints: [],
      editingPolygonIndex: null,
    });

    const fenceType = getGeofenceType(geofence);
    if (fenceType === GeofenceType.POLYGONAL) {
      this.deletePolygon(data.geofence.geometry.coordinates.length - 1);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      this.deletePolygon(data.geofence.geometry.length - 1);
    }
  }

  setPageSubTitle(subTitle) {
    const { setSubTitle } = this.props;

    if (!_.isNil(subTitle) && !_.isEmpty(subTitle)) {
      setSubTitle(subTitle);
    } else {
      setSubTitle(null);
    }
  }

  canShowDpuTab(location) {
    return (
      this.props.authorization.hasFeatures([Features.DEALER_DRIVE_AWAY]) &&
      location?.lad?.lob_name === LobsType.FINISHED_PRODUCT
    );
  }

  isChildLocation(location) {
    return location?.id !== location?.resolved_referent_id;
  }

  isScacInputUnique(value, scacList = []) {
    return (
      scacList.map((ele) => ele?.scac?.trim()).filter((ele) => ele === value)
        .length < 2
    );
  }

  isScacListValid(scacList) {
    if (!scacList) {
      return false;
    }

    let res = true;

    for (const ele of scacList) {
      if (
        !ele.scac ||
        _.isEmpty(ele.scac.trim()) ||
        !this.isScacInputUnique(ele.scac.trim(), scacList)
      ) {
        res = false;
        break;
      }
    }
    return res;
  }

  canShowDamageViewLocationConfig() {
    return (
      this.props.authorization.hasFeatures([Features.DAMAGE_VIEW]) &&
      isShipper(this.props.activeOrganization)
    );
  }

  canShowCriticalPartsLocationConfig(location) {
    return (
      this.props.authorization.hasFeatures([Features.CRITICAL_PARTS]) &&
      location?.lad?.lob_name === LobsType.ASSEMBLY &&
      isShipper(this.props.activeOrganization)
    );
  }

  canShowEnableDpu(isFvAdmin = false) {
    return isFvAdmin && isShipper(this.props.activeOrganization);
  }

  updateInvLocationData(key, value) {
    this.setState((prevState) => {
      const newState = { ...prevState };
      let newInvLocationData = { ...prevState.invLocationData };

      if (key === "isAddLocationForInventoryViewEnabled") {
        newInvLocationData = {
          ...newInvLocationData,
          [key]: value,
        };
      } else {
        newInvLocationData = {
          ...newInvLocationData,
          [key]: _.isNaN(value) ? null : value,
        };
      }

      newState.invLocationData = newInvLocationData;
      return newState;
    });
  }

  updateOrgInventoryLocationData(key, value, orgFvId, idx) {
    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.invLocationData = {
        ...prevState.invLocationData,
        orgLevelLocationInformation:
          prevState.invLocationData.orgLevelLocationInformation.map(
            (item, orgIndex) =>
              orgIndex === idx ? { ...item, [key]: value } : item,
          ),
      };

      return newState;
    });
  }

  addNewOrgInvLocation() {
    this.setState((prevState) => {
      const newState = { ...prevState };
      const newInvLocationData = { ...prevState.invLocationData };
      newInvLocationData.orgLevelLocationInformation.push({
        orgFvId: null,
        capacity: null,
        primaryDepartureTarget: null,
        secondaryDepartureTarget: null,
        enabled: true,
        saved: false,
      });
      newState.invLocationData = newInvLocationData;
      return newState;
    });
  }

  removeOrgInvLocation(orgFvId, idx) {
    this.setState((prevState) => {
      const newState = { ...prevState };
      newState.invLocationData.orgLevelLocationInformation =
        newState.invLocationData.orgLevelLocationInformation.map(
          (item, orgIndex) =>
            item.orgFvId === orgFvId && orgIndex === idx
              ? { ...item, enabled: !item.enabled }
              : item,
        );
      return newState;
    });
  }

  render() {
    const {
      t,
      isCreateMode,
      authorization,
      isFvAdmin,
      locationId,
      addLocation,
      addAndLinkLocation,
      editLocation,
      isLocationSaving,
      isConfigSaving,
      isInventoryViewLocationDetailsLoading,
      isDamageViewLocationConfigLoading,
      isCriticalPartsLocationConfigLoading,
      isEnableDpuLoading,
      deleteLocation,
      pushLocationsScreen,
      lads,
      selectedLocation,
      hereMapsPlatform,
      googleMaps,
      heatmapData,
      heatmapIsLoading,
      actionStatus,
      countries,
      subdivisions,
      fetchSubdivisions,
      activeOrganization,
      mapPlatform,
      mapTypeOverride,
      unresolvedLocation,
      locationChildren,
      isLoadingLocationChildren,
      locationChildrenPage,
      locationChildrenPageSize,
      locationChildrenTotalPages,
      setLocationChildrenPagination,
      ddaLocationData,
      updateDdaLocationDetails,
      fetchDdaSubdivisions,
      ddaSubdivisions,
      isScacListLoading,
    } = this.props;

    const {
      data,
      showHeatmap,
      showDeleteLocationModal,
      showDeletePolygonModal,
      isTracing,
      tracePoints,
      editingPolygonIndex,
    } = this.state;

    const { location_code, location_name } =
      this.state.data?.parent_location_details ?? {};

    const columns = getColumns(t, lads);
    let selectedLad = _.get(selectedLocation, "lad");
    if (
      selectedLad &&
      selectedLad.id &&
      lads &&
      selectedLad.name &&
      selectedLad.name !== "Unclassified"
    ) {
      let foundLad;
      if (Array.isArray(lads)) {
        foundLad = lads.find((l) => Number(l.id) === Number(selectedLad.id));
      } else {
        const keys = Object.keys(lads);
        let selectedLadId = selectedLad.id;
        for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
          foundLad = lads[keys[keyIndex]].find(
            (l) => Number(l.id) === Number(selectedLadId),
          );
        }
      }

      const defaultName = foundLad ? foundLad.default_name : "";
      selectedLad = { ...selectedLad, default_name: defaultName };
    }

    // Use a placeholder geofence while the location details are loading.
    const { geofence } =
      !data.isLoadingDetails && isFenceValid(data.geofence)
        ? data
        : getDefaultLocation();

    let mapLocationData = _.isEmpty(data) ? selectedLocation : data;
    if (mapLocationData.isLoadingDetails) {
      mapLocationData = null;
    } else if (!mapLocationData.latitude && mapLocationData.position) {
      // Adapt selectedLocation to the expected interface
      mapLocationData.latitude = mapLocationData.position.lat;
      mapLocationData.longitude = mapLocationData.position.lng;
    }

    const isNameValid = _.isEmpty(data.name) ? false : data.name.length > 2;
    const isLadValid = !_.isEmpty(data.lad);
    const isCodeValid = data.code?.length > 0;
    const isAddressValid =
      !_.isEmpty(data.city) &&
      (!_.isEmpty(data.state) || subdivisions.length === 0) &&
      !_.isEmpty(data.country);
    const fenceType = getGeofenceType(geofence);
    const isGeofenceValid = fenceType.isValid(geofence);
    const isLocationValid =
      isNameValid &&
      isLadValid &&
      isCodeValid &&
      isGeofenceValid &&
      isAddressValid;
    const isNewLocation = isCreateMode;

    const previousInvLocationData = this.props.previousInvLocationData;

    const getFilteredList = (currentList, previousList) => {
      let processedList = _.differenceWith(
        [...currentList],
        [...previousList],
        _.isEqual,
      );
      if (_.isEmpty(previousList)) {
        processedList = processedList.filter((item) => item?.enabled !== false);
      }

      processedList.filter((item) => item?.orgFvId !== null);

      // remove duplicates and consider the last updated
      if (!_.isEmpty(processedList)) {
        const convertedObject = processedList.reduce((acc, curr) => {
          acc[curr?.orgFvId?.toLowerCase()] = curr; // Convert orgFvId to lowercase for case-insensitive matching
          return acc;
        }, {});

        return Object.values(convertedObject);
      }
      return processedList;
    };

    let filteredOrgLevelInformation = [];

    if (this.state.invLocationData.isAddLocationForInventoryViewEnabled) {
      const isOrgLevelDataEqual = _.isEqual(
        this.state.invLocationData.orgLevelLocationInformation,
        previousInvLocationData.orgLevelLocationInformation,
      );

      // check difference for org level information
      if (!isOrgLevelDataEqual) {
        filteredOrgLevelInformation = getFilteredList(
          this.state.invLocationData.orgLevelLocationInformation,
          previousInvLocationData.orgLevelLocationInformation,
        );
      }
    }

    const isCarrierOrg = isCarrier(activeOrganization);
    const readOnly =
      isCarrierOrg ||
      !authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]);
    const hasManageGeofenceName = authorization.hasPrivileges([
      Privileges.MANAGE_GEOFENCE_NAME,
    ]);
    const canGeocodeFullAddress = ({ city, state, country }) => {
      return !_.isEmpty(country) && !_.isEmpty(state) && !_.isEmpty(city);
    };

    const doGeocode = (mergedData, addressUpdated = false) => {
      // H1-82: Geocode the address for new locations and anytime the address is updated
      if (
        (!isNewLocation && !addressUpdated) ||
        (addressUpdated && fenceType !== GeofenceType.RADIAL)
      ) {
        return;
      }

      geocode(
        mapPlatform,
        mapTypeOverride,
        hereMapsPlatform,
        googleMaps,
        mergedData,
        ({ lat, lng }) => {
          if (!(_.isUndefined(lat) || _.isUndefined(lng))) {
            const dataWithLatLng = mergeLatLng(mergedData, lat, lng);
            this.setState({
              data: dataWithLatLng,
            });
          }
        },
        (err) => console.error(err),
      );
    };

    const fieldChangesGeocode = (field) => {
      return [
        "address",
        "address2",
        "city",
        "country",
        "state",
        "postal_code",
      ].includes(field);
    };

    const updateDdaLocationData = (field, val) => {
      if (
        ddaLocationData.pickupLocation?.country === null ||
        val?.dpu_country !== ddaLocationData.pickupLocation.country
      ) {
        // if editing an existing location, we need to reset country and state fields when selecting a new one
        if (selectedLocation && selectedLocation.pickupLocation) {
          selectedLocation.pickupLocation.country = val.country;
          selectedLocation.pickupLocation.state = "";
        }
      }
      ddaLocationData.pickupLocation[field] = val;
      this.setState({ ddaLocationData: ddaLocationData });
    };

    const updateConfiguration = (
      locationId,
      ddaLocationData,
      ActionOptions = {
        showDpu: false,
        showInv: false,
        showDv: false,
        showCp: false,
        showEnableDpu: false,
      },
    ) => {
      const scacModificationList = this.state.scacModificationList?.map(
        (obj) => ({ scac: obj?.scac, override_scac: obj?.override_scac }),
      );

      updateDdaLocationDetails(
        locationId,
        ddaLocationData.pickupLocation,
        ActionOptions,
        this.state.invLocationData.isAddLocationForInventoryViewEnabled,
        this.state.invLocationData.capacity,
        this.state.invLocationData.primaryDepartureTarget,
        this.state.invLocationData.secondaryDepartureTarget,
        filteredOrgLevelInformation,
        previousInvLocationData.isAddLocationForInventoryViewEnabled,
        this.state.dvLocationData.isDamageViewAddLocationEnabled,
        this.state.prevDvLocationData.isDamageViewAddLocationEnabled,
        this.state.cpLocationData.isCriticalPartsAddLocationEnabled,
        this.state.prevCpLocationData.isCriticalPartsAddLocationEnabled,
        this.state.dpuAddLocationData.isDpuAddLocationEnabled,
        this.state.prevDpuAddLocationData.isDpuAddLocationEnabled,
        scacModificationList,
        this.state.isPersistentApprovalEnabled,
      ).catch((err) => {
        this.setState({
          showError: true,
        });
      });
    };

    const updateField = (field, val) => {
      if (field === "name") {
        // Every time the user changes the name value, update the sub-title accordingly.
        this.setPageSubTitle(val);
      }

      if (field === "lad") {
        this.props.locationLadChanged(data?.lad?.lob_name, val?.lob_name);
      }

      const mergedData = { ...data, [field]: val };
      this.setState({
        data: mergedData,
      });

      if (field === "country") {
        fetchSubdivisions(val);

        // if editing an existing location, we need to reset country and state fields when selecting a new one
        if (selectedLocation) {
          selectedLocation.country = val;
          selectedLocation.state = "";
        }
      }

      if (field === "state" && selectedLocation) {
        selectedLocation.state = val;
      }

      if (canGeocodeFullAddress(mergedData)) {
        if (this.state.typingTimeout) {
          clearTimeout(this.state.typingTimeout);
        }

        if (fieldChangesGeocode(field)) {
          this.setState({
            typingTimeout: setTimeout(() => {
              doGeocode(mergedData, true);
            }, 500),
          });
        }
      }
    };

    const addScac = () => {
      this.setState((prevState) => {
        return {
          scacModificationList: [
            ...prevState.scacModificationList,
            {
              scac: "",
              override_scac: "",
            },
          ],
        };
      });
    };

    const removeScac = (idx) => {
      this.setState((prevState) => {
        let newList = [...prevState.scacModificationList].filter(
          (ele, i) => i !== idx,
        );
        return {
          scacModificationList: newList,
        };
      });
    };

    const isCodeUnresolved = this.state?.data?.lad?.code === "u";
    const showLinkingBanner = isCodeUnresolved && !isNewLocation;
    let ActionOptions = {
      showDpu:
        !this.isChildLocation(data) &&
        this.canShowDpuTab(data) &&
        this.state.dpuAddLocationData.isDpuAddLocationEnabled,
      showInv: !this.isChildLocation(data),
      showDv: this.canShowDamageViewLocationConfig(),
      showCp: this.canShowCriticalPartsLocationConfig(data),
      showEnableDpu: this.canShowEnableDpu(isFvAdmin),
    };

    // common errors for InventoryView Form
    const validateInvNumberInput = (value) => {
      let state = {
        isInvalid: null,
        errorText: null,
      };
      if (validateInput(value) === DATA_INPUT_TYPE_ERROR_TYPES.REQUIRED_FIELD) {
        state = {
          isInvalid: true,
          errorText: t("location-edit:This field is required."),
        };
      } else if (
        validateInput(value) === DATA_INPUT_TYPE_ERROR_TYPES.NEGATIVE_NUMBER
      ) {
        state = {
          isInvalid: true,
          errorText: t("location-edit:The value should not be negative."),
        };
      }
      return state;
    };

    const validateInvOrgFvIdInput = (value) => {
      let state = {
        isInvalid: null,
        errorText: null,
      };
      const invalidState = validateLocationOrgData(
        value,
        this.state.invLocationData.orgLevelLocationInformation,
      );
      if (invalidState === DATA_INPUT_TYPE_ERROR_TYPES.REQUIRED_FIELD) {
        state = {
          isInvalid: true,
          errorText: t("location-edit:This field is required."),
        };
      } else if (
        invalidState === DATA_INPUT_TYPE_ERROR_TYPES.DUPLICATE_ORG_FV_ID
      ) {
        state = {
          isInvalid: true,
          errorText: t("location-edit:Please enter a unique FV ID."),
        };
      }

      return state;
    };

    return (
      <div css={{ display: "flex", flexDirection: "row" }}>
        <Section
          style={{
            position: "relative",
            backgroundColor: Colors.background.LIGHT_GRAY,
          }}
        >
          {showLinkingBanner && isCodeValid ? (
            <div
              style={{
                width: "100%",
                textAlign: "inherit",
                padding: 10,

                backgroundColor: Colors.finishedVehicles.LIGHT_BLUE,
              }}
            >
              <UnclassifiedLinkBanner
                locationId={locationId}
                parentLocationCode={location_code}
                parentLocationName={location_name}
              ></UnclassifiedLinkBanner>
            </div>
          ) : null}

          <Tabs
            css={{
              backgroundColor: "#f4f8fa",
              height: "calc(100vh - 7em)",
              [TabsClassNames.TabPanelSelected]: {
                display: "flex",
                flexDirection: "column",
                height: "100%",
              },
            }}
            tabHeight={TabHeight.SHORT}
            onSelect={() => {
              setLocationChildrenPagination(
                locationId,
                0,
                locationChildrenPageSize,
              );
            }}
          >
            <Tabs.TabList style={{ marginLeft: 10 }}>
              <Tabs.Tab>{t("location-edit:Details")}</Tabs.Tab>
              {!isCreateMode ? (
                <Fragment>
                  <Tabs.Tab>{t("location-edit:Child Locations")}</Tabs.Tab>
                  {ActionOptions.showDpu ||
                  ActionOptions.showInv ||
                  ActionOptions.showDv ||
                  ActionOptions.showCp ? (
                    <Tabs.Tab>{t("location-edit:Configurations")}</Tabs.Tab>
                  ) : null}
                </Fragment>
              ) : null}
            </Tabs.TabList>
            <Tabs.TabPanel>
              <FlexColDiv css={{ overflowY: "scroll" }}>
                <LocationEditForm
                  data={data}
                  lads={lads}
                  updateField={updateField}
                  updateGeofence={this.updateGeofence}
                  isReadOnly={readOnly}
                  isTracing={isTracing}
                  addPolygon={this.addPolygon}
                  cancelTracing={this.cancelTracing}
                  editingPolygonIndex={editingPolygonIndex}
                  deletePolygon={this.deletePolygonConfirm}
                  showUpdateGeofenceTypeModal={this.showUpdateGeofenceTypeModal}
                  countries={countries}
                  subdivisions={subdivisions}
                  isCarrierOrg={isCarrierOrg}
                />
              </FlexColDiv>
              <FlexColDiv
                css={{
                  backgroundColor: Colors.background.LIGHT_GRAY,
                  color: Colors.text.DARK_BLUE,
                  padding: "2em",
                  justifyContent: "flex-start",
                }}
              >
                {actionStatus && actionStatus === "Duplicate_Location" ? (
                  <div
                    css={{
                      color: Colors.highlight.RED,
                      marginTop: ".5em",
                      marginLeft: ".1em",
                    }}
                  >
                    {t(
                      "location-edit:Unable to add new location, location code already exists. Please change the location code and resubmit.",
                    )}
                  </div>
                ) : null}
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  {!readOnly && (
                    <div
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        width: "70%",
                      }}
                    >
                      <ValidationRow
                        isValid={isNameValid}
                        field="Location Name"
                      />
                      <ValidationRow
                        isValid={isLadValid}
                        field="Location Type"
                      />
                      <ValidationRow
                        isValid={isCodeValid}
                        field="Location Code"
                      />
                      <ValidationRow isValid={isAddressValid} field="Address" />
                      <ValidationRow
                        isValid={isGeofenceValid}
                        field="Valid Geofence"
                      />
                    </div>
                  )}
                  <div
                    css={{
                      width: "30%",
                    }}
                  >
                    <LastUpdatedLabel
                      t={t}
                      lastUpdateTimestring={data?.geofence?.lastUpdateTime}
                      lastUpdater={data?.geofence?.lastUpdater}
                    />
                  </div>
                </div>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingTop: "1em",
                  }}
                >
                  {!isCreateMode && !readOnly && (
                    <Button
                      variant={"danger"}
                      onClick={() =>
                        this.setState({ showDeleteLocationModal: true })
                      }
                    >
                      {t("location-edit:Delete Location")}
                    </Button>
                  )}
                  {!readOnly || hasManageGeofenceName ? (
                    <div>
                      <Button
                        variant={"secondary"}
                        css={{
                          marginRight: "0.5em",
                        }}
                        className="me-1"
                        onClick={() => {
                          const isResolving =
                            _.isEmpty(unresolvedLocation) === false;
                          pushLocationsScreen(isResolving);
                        }}
                      >
                        {t("location-edit:Cancel")}
                      </Button>

                      {isNewLocation && !readOnly && (
                        <Button
                          variant={"success"}
                          disabled={!isLocationValid || isLocationSaving}
                          onClick={() => {
                            // If we were called from the resolve location
                            // screen we need to perform an add and link
                            if (
                              _.isEmpty(this.props.unresolvedLocation) === false
                            ) {
                              addAndLinkLocation(
                                data,
                                this.props.unresolvedLocation,
                                this.props.unresolvedShipment,
                              );
                            } else {
                              addLocation(data);
                            }
                          }}
                        >
                          {t("location-edit:Add Location")}
                        </Button>
                      )}

                      {((!isNewLocation && !readOnly) ||
                        hasManageGeofenceName) && (
                        <Button
                          variant={"success"}
                          disabled={!isLocationValid || isLocationSaving}
                          onClick={() => {
                            editLocation(locationId, data);
                          }}
                        >
                          {t("location-edit:Update Location")}
                        </Button>
                      )}
                    </div>
                  ) : null}
                </div>
              </FlexColDiv>
            </Tabs.TabPanel>
            {!isCreateMode ? (
              <Tabs.TabPanel>
                <FlexColDiv css={{ overflowY: "scroll" }}>
                  <BaseTable
                    theme={Themes.LIGHT}
                    data={locationChildren}
                    columns={columns}
                    isLoading={isLoadingLocationChildren}
                    showPagination={true}
                    fixPaginationToBottom={true}
                    isManualPagination={true}
                    pageIndex={locationChildrenPage}
                    pageSize={locationChildrenPageSize}
                    pageCount={locationChildrenTotalPages}
                    disableSortBy={true}
                    onPageChange={(newPage) => {
                      setLocationChildrenPagination(
                        locationId,
                        newPage,
                        locationChildrenPageSize,
                      );
                    }}
                    onPageSizeChange={(newPageSize) => {
                      setLocationChildrenPagination(locationId, 0, newPageSize);
                    }}
                    noDataFoundMessage={t(
                      "location-edit:No Child Locations Found",
                    )}
                  />
                </FlexColDiv>
              </Tabs.TabPanel>
            ) : null}

            {!isCreateMode &&
            (ActionOptions.showEnableDpu ||
              ActionOptions.showDpu ||
              ActionOptions.showInv ||
              ActionOptions.showDv ||
              ActionOptions.showCp) ? (
              <Tabs.TabPanel>
                <div css={{ overflowY: "scroll" }}>
                  {ActionOptions.showEnableDpu ? (
                    <PanelGroup collapsible css={{ padding: "1rem" }}>
                      <PanelGroup.Header
                        title={t("location-edit:Enable Dealer Pick Up")}
                      />
                      <PanelGroup.Content
                        style={{
                          padding: 0,
                          minHeight: !isEnableDpuLoading ? null : "5rem",
                        }}
                      >
                        <Loader loaded={!isEnableDpuLoading}>
                          <Form>
                            <FormGroup css={{ marginBottom: "1rem" }}>
                              <div
                                className="d-flex align-items-center"
                                css={{ margin: "1rem 2rem" }}
                              >
                                <span css={{ marginRight: "1rem" }}>
                                  {t(
                                    "location-edit:Add Location for Dealer Pick Up",
                                  )}
                                </span>
                                <Form.Check
                                  type="switch"
                                  label=""
                                  checked={
                                    this.state.dpuAddLocationData
                                      .isDpuAddLocationEnabled
                                  }
                                  onChange={(e) => {
                                    this.setState((prevState) => {
                                      return {
                                        dpuAddLocationData: {
                                          isDpuAddLocationEnabled:
                                            !prevState.dpuAddLocationData
                                              .isDpuAddLocationEnabled,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                              {this.state.dpuAddLocationData
                                .isDpuAddLocationEnabled && (
                                <div
                                  className="d-flex align-items-center"
                                  css={{ margin: "1rem 2rem" }}
                                >
                                  <span css={{ marginRight: "0.5rem" }}>
                                    {t(
                                      "location-edit:Enable Persistent Approval",
                                    )}
                                  </span>
                                  <Tooltip
                                    placement="top"
                                    tooltipChildren={
                                      <Text>
                                        {t(
                                          "location-edit:When this is enabled, this allows carriers to provide a persistent approval which allows a DPU request to remain in Approved even if the VIN is shipped or bayed at a new location.",
                                        )}
                                      </Text>
                                    }
                                  >
                                    <Icon src={faInfoCircle} />
                                  </Tooltip>
                                  <Form.Check
                                    css={{ marginLeft: "1rem" }}
                                    type="switch"
                                    label=""
                                    checked={
                                      this.state.isPersistentApprovalEnabled
                                    }
                                    onChange={(e) =>
                                      this.setState({
                                        isPersistentApprovalEnabled:
                                          e.target.checked,
                                      })
                                    }
                                  />
                                </div>
                              )}
                            </FormGroup>
                          </Form>
                        </Loader>
                      </PanelGroup.Content>
                    </PanelGroup>
                  ) : null}
                  {ActionOptions.showEnableDpu &&
                  this.state.dpuAddLocationData.isDpuAddLocationEnabled ? (
                    <Fragment>
                      <PanelGroup css={{ padding: "1rem" }}>
                        <PanelGroup.Header
                          title={t(
                            "location-edit:Dealer Pick Up SCAC Modification Tool",
                          )}
                        />
                        <PanelGroup.Content
                          style={{
                            minHeight: !isScacListLoading ? null : "5rem",
                          }}
                        >
                          <Loader loaded={!isScacListLoading}>
                            <Form>
                              <FormGroup>
                                {this.state.scacModificationList.map(
                                  (scacObj, idx) => (
                                    <div
                                      key={idx}
                                      css={css`
                                        display: flex;
                                        justify-content: space-between;
                                        gap: 1rem;
                                        margin-bottom: 1.5rem;
                                        animation: ${slideIn} 0.5s;
                                      `}
                                    >
                                      <div css={{ flex: 3 }}>
                                        <span>{t("location-edit:SCAC")}</span>
                                        <InputGroup hasValidation>
                                          <FormControl
                                            disabled={scacObj?.saved ?? false}
                                            value={scacObj?.scac ?? ""}
                                            isInvalid={
                                              _.isEmpty(
                                                scacObj?.scac?.trim(),
                                              ) ||
                                              !this.isScacInputUnique(
                                                scacObj?.scac?.trim(),
                                                this.state.scacModificationList,
                                              )
                                            }
                                            onChange={(e) => {
                                              this.setState((prevState) => {
                                                const newList = [
                                                  ...prevState.scacModificationList,
                                                ];
                                                newList[idx].scac =
                                                  e.target.value;
                                                return {
                                                  scacModificationList: newList,
                                                };
                                              });
                                            }}
                                          />
                                          <FormControl.Feedback
                                            type="invalid"
                                            tooltip
                                          >
                                            {_.isEmpty(scacObj?.scac?.trim())
                                              ? t(
                                                  "location-edit:This field is required.",
                                                )
                                              : null}
                                            {!_.isEmpty(
                                              scacObj?.scac?.trim(),
                                            ) &&
                                            !this.isScacInputUnique(
                                              scacObj?.scac?.trim(),
                                              this.state.scacModificationList,
                                            )
                                              ? t(
                                                  "location-edit:Please enter a unique scac code",
                                                )
                                              : null}
                                          </FormControl.Feedback>
                                        </InputGroup>
                                      </div>
                                      <div css={{ flex: 3 }}>
                                        <span>
                                          {t("location-edit:SCAC Override")}
                                        </span>
                                        <FormControl
                                          disabled={scacObj?.saved ?? false}
                                          value={scacObj?.override_scac ?? ""}
                                          onChange={(e) => {
                                            this.setState((prevState) => {
                                              const newList = [
                                                ...prevState.scacModificationList,
                                              ];
                                              newList[idx].override_scac =
                                                e.target.value;
                                              return {
                                                scacModificationList: newList,
                                              };
                                            });
                                          }}
                                        />
                                      </div>
                                      <div
                                        css={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "end",
                                          paddingBottom: "0.5rem",
                                          flex: 1,
                                        }}
                                      >
                                        <div
                                          css={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {this.state.scacModificationList
                                            .length !== 1 ? (
                                            <Icon
                                              color={Colors.highlight.RED}
                                              size={1.3}
                                              src={faMinusCircle}
                                              onClick={() => {
                                                removeScac(idx);
                                              }}
                                            />
                                          ) : null}
                                          {this.state.scacModificationList
                                            .length ===
                                          idx + 1 ? (
                                            <Icon
                                              color={Colors.highlight.GREEN}
                                              size={1.3}
                                              src={faPlusCircle}
                                              onClick={() => {
                                                addScac();
                                              }}
                                            />
                                          ) : null}
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )}
                              </FormGroup>
                            </Form>
                          </Loader>
                        </PanelGroup.Content>
                      </PanelGroup>
                    </Fragment>
                  ) : null}
                  {ActionOptions.showDpu ? (
                    <PanelGroup collapsible css={{ padding: "1rem" }}>
                      <PanelGroup.Header
                        title={t("location-edit:Dealer Pick Up")}
                      />
                      <PanelGroup.Content css={{ padding: 0 }}>
                        <LocationEditFormDPU
                          data={data}
                          pickupLocation={ddaLocationData.pickupLocation}
                          updateField={updateDdaLocationData}
                          isReadOnly={readOnly}
                          countries={countries}
                          subdivisions={ddaSubdivisions}
                          isCarrierOrg={isCarrierOrg}
                          fetchDdaSubdivisions={fetchDdaSubdivisions}
                        />
                      </PanelGroup.Content>
                    </PanelGroup>
                  ) : null}

                  {ActionOptions.showInv ? (
                    <PanelGroup collapsible css={{ padding: "1rem" }}>
                      <PanelGroup.Header
                        title={t(
                          "location-edit:Finished Vehicle InventoryView",
                        )}
                      />
                      <PanelGroup.Content
                        style={{ padding: 0, minHeight: "5rem" }}
                      >
                        <Loader loaded={!isInventoryViewLocationDetailsLoading}>
                          <Form>
                            <FormGroup css={{ marginBottom: "1rem" }}>
                              <div
                                className="d-flex align-items-center"
                                css={{ margin: "1rem 2rem" }}
                              >
                                <span css={{ marginRight: "1rem" }}>
                                  {t(
                                    "location-edit:Add Location for InventoryView",
                                  )}
                                </span>
                                <Form.Check
                                  type="switch"
                                  label=""
                                  id="inventoryView-switch"
                                  checked={
                                    this.state.invLocationData
                                      .isAddLocationForInventoryViewEnabled
                                  }
                                  onChange={() => {
                                    this.updateInvLocationData(
                                      "isAddLocationForInventoryViewEnabled",
                                      !this.state.invLocationData
                                        .isAddLocationForInventoryViewEnabled,
                                    );
                                  }}
                                />
                              </div>
                              <div
                                css={{
                                  display: this.state.invLocationData
                                    .isAddLocationForInventoryViewEnabled
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <FlexDiv
                                  css={{
                                    margin: "1em 2em",
                                    borderBottom: "1px solid #ddd",
                                    paddingBottom: "30px",
                                  }}
                                >
                                  <FlexRowDiv
                                    css={{
                                      alignItems: "space-between",
                                      columnGap: "20px",
                                    }}
                                  >
                                    <FlexColDiv>
                                      <FormLabel>Location Capacity</FormLabel>
                                      <InputGroup hasValidation>
                                        <FormControl
                                          required
                                          type="number"
                                          disabled={
                                            !this.state.invLocationData
                                              .isAddLocationForInventoryViewEnabled
                                          }
                                          value={
                                            this.state.invLocationData
                                              .isAddLocationForInventoryViewEnabled
                                              ? this.state.invLocationData
                                                  .capacity
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            this.updateInvLocationData(
                                              "capacity",
                                              parseInt(value),
                                            );
                                          }}
                                          min={0}
                                          isInvalid={
                                            validateInvNumberInput(
                                              this.state.invLocationData
                                                .capacity,
                                            ).isInvalid
                                          }
                                        ></FormControl>
                                        <FormControl.Feedback
                                          type="invalid"
                                          tooltip
                                        >
                                          {
                                            validateInvNumberInput(
                                              this.state.invLocationData
                                                .capacity,
                                            ).errorText
                                          }
                                        </FormControl.Feedback>
                                      </InputGroup>
                                    </FlexColDiv>

                                    <FlexColDiv>
                                      <FormLabel>
                                        Location Departure Target
                                      </FormLabel>
                                      <FormControl
                                        type="number"
                                        disabled={
                                          !this.state.invLocationData
                                            .isAddLocationForInventoryViewEnabled
                                        }
                                        value={
                                          this.state.invLocationData
                                            .isAddLocationForInventoryViewEnabled
                                            ? this.state.invLocationData
                                                .primaryDepartureTarget
                                            : null
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          this.updateInvLocationData(
                                            "primaryDepartureTarget",
                                            parseInt(value),
                                          );
                                        }}
                                        min={0}
                                      />
                                    </FlexColDiv>
                                    <FlexColDiv>
                                      <FormLabel>
                                        Location Stretch Departure Target
                                      </FormLabel>
                                      <FormControl
                                        type="number"
                                        disabled={
                                          !this.state.invLocationData
                                            .isAddLocationForInventoryViewEnabled
                                        }
                                        value={
                                          this.state.invLocationData
                                            .isAddLocationForInventoryViewEnabled
                                            ? this.state.invLocationData
                                                .secondaryDepartureTarget
                                            : null
                                        }
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          this.updateInvLocationData(
                                            "secondaryDepartureTarget",
                                            parseInt(value),
                                          );
                                        }}
                                        min={0}
                                      ></FormControl>
                                    </FlexColDiv>
                                  </FlexRowDiv>
                                </FlexDiv>
                                <div css={{ margin: "0 2em" }}>
                                  {this.state.invLocationData
                                    ?.orgLevelLocationInformation &&
                                    this.state.invLocationData.orgLevelLocationInformation.map(
                                      (item, idx) => {
                                        const disabledPrevOrg =
                                          item.saved ?? false;
                                        if (item.enabled) {
                                          return (
                                            <FlexDiv
                                              css={{
                                                alignItems: "space-between",
                                                columnGap: "20px",
                                                marginBottom: "20px",
                                                marginTop: "30px",
                                              }}
                                              key={`org-${idx}`}
                                            >
                                              <FlexColDiv>
                                                <FormLabel>FV ID</FormLabel>
                                                <InputGroup hasValidation>
                                                  <FormControl
                                                    required
                                                    type="text"
                                                    disabled={disabledPrevOrg}
                                                    value={
                                                      this.state.invLocationData
                                                        .isAddLocationForInventoryViewEnabled
                                                        ? item.orgFvId
                                                        : null
                                                    }
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value.trim("");
                                                      if (!_.isEmpty(value)) {
                                                        this.updateOrgInventoryLocationData(
                                                          "orgFvId",
                                                          value,
                                                          item.orgFvId,
                                                          idx,
                                                        );
                                                      } else {
                                                        this.updateOrgInventoryLocationData(
                                                          "orgFvId",
                                                          null,
                                                          item.orgFvId,
                                                          idx,
                                                        );
                                                      }
                                                    }}
                                                    isInvalid={
                                                      validateInvOrgFvIdInput(
                                                        this.state
                                                          .invLocationData
                                                          .orgLevelLocationInformation[
                                                          idx
                                                        ]?.orgFvId,
                                                      ).isInvalid
                                                    }
                                                  ></FormControl>
                                                  <FormControl.Feedback
                                                    type="invalid"
                                                    tooltip
                                                  >
                                                    {
                                                      validateInvOrgFvIdInput(
                                                        this.state
                                                          .invLocationData
                                                          .orgLevelLocationInformation[
                                                          idx
                                                        ]?.orgFvId,
                                                      ).errorText
                                                    }
                                                  </FormControl.Feedback>
                                                </InputGroup>
                                              </FlexColDiv>
                                              <FlexColDiv
                                                style={{ flexShrink: 2 }}
                                              >
                                                <FormLabel>Capacity</FormLabel>
                                                <FormControl
                                                  type="number"
                                                  disabled={
                                                    !this.state.invLocationData
                                                      .isAddLocationForInventoryViewEnabled
                                                  }
                                                  value={
                                                    this.state.invLocationData
                                                      .isAddLocationForInventoryViewEnabled
                                                      ? item.capacity
                                                      : null
                                                  }
                                                  onChange={(e) => {
                                                    const value = parseInt(
                                                      e.target.value,
                                                    );
                                                    const parsedValue = _.isNaN(
                                                      value,
                                                    )
                                                      ? null
                                                      : value;
                                                    this.updateOrgInventoryLocationData(
                                                      "capacity",
                                                      parsedValue,
                                                      item.orgFvId,
                                                      idx,
                                                    );
                                                  }}
                                                  min={0}
                                                ></FormControl>
                                              </FlexColDiv>
                                              <FlexColDiv
                                                style={{ flexShrink: 2 }}
                                              >
                                                <FormLabel>
                                                  Departure Target
                                                </FormLabel>

                                                <FormControl
                                                  type="number"
                                                  disabled={
                                                    !this.state.invLocationData
                                                      .isAddLocationForInventoryViewEnabled
                                                  }
                                                  value={
                                                    this.state.invLocationData
                                                      .isAddLocationForInventoryViewEnabled
                                                      ? item.primaryDepartureTarget
                                                      : null
                                                  }
                                                  onChange={(e) => {
                                                    const value = parseInt(
                                                      e.target.value,
                                                    );
                                                    const parsedValue = _.isNaN(
                                                      value,
                                                    )
                                                      ? null
                                                      : value;
                                                    this.updateOrgInventoryLocationData(
                                                      "primaryDepartureTarget",
                                                      parsedValue,
                                                      item.orgFvId,
                                                      idx,
                                                    );
                                                  }}
                                                  min={0}
                                                ></FormControl>
                                              </FlexColDiv>
                                              <FlexColDiv>
                                                <FlexRowDiv
                                                  style={{
                                                    alignItems: "flex-end",
                                                  }}
                                                >
                                                  <FlexColDiv>
                                                    <FormLabel>
                                                      Stretch Departure Target
                                                    </FormLabel>
                                                    <FormControl
                                                      type="number"
                                                      disabled={
                                                        !this.state
                                                          .invLocationData
                                                          .isAddLocationForInventoryViewEnabled
                                                      }
                                                      value={
                                                        this.state
                                                          .invLocationData
                                                          .isAddLocationForInventoryViewEnabled
                                                          ? item.secondaryDepartureTarget
                                                          : null
                                                      }
                                                      onChange={(e) => {
                                                        const value = parseInt(
                                                          e.target.value,
                                                        );
                                                        const parsedValue =
                                                          _.isNaN(value)
                                                            ? null
                                                            : value;
                                                        this.updateOrgInventoryLocationData(
                                                          "secondaryDepartureTarget",
                                                          parsedValue,
                                                          item.orgFvId,
                                                          idx,
                                                        );
                                                      }}
                                                      min={0}
                                                    ></FormControl>
                                                  </FlexColDiv>
                                                  <FlexColDiv
                                                    style={{
                                                      padding: "1em",
                                                      paddingRight: 0,
                                                    }}
                                                  >
                                                    <Icon
                                                      color={
                                                        Colors.highlight.RED
                                                      }
                                                      size={1.3}
                                                      src={faMinusCircle}
                                                      onClick={() =>
                                                        this.removeOrgInvLocation(
                                                          item.orgFvId,
                                                          idx,
                                                        )
                                                      }
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </FlexColDiv>
                                                </FlexRowDiv>
                                              </FlexColDiv>
                                            </FlexDiv>
                                          );
                                        } else {
                                          return null;
                                        }
                                      },
                                    )}
                                </div>
                                <FlexDiv
                                  style={{
                                    justifyContent: "center",
                                    marginTop: "2.5em",
                                  }}
                                >
                                  <Button
                                    variant="success"
                                    onClick={() => {
                                      this.addNewOrgInvLocation();
                                    }}
                                  >
                                    <Icon
                                      src={faPlus}
                                      style={{ marginRight: "0.5em" }}
                                    />
                                    Add Organization
                                  </Button>
                                </FlexDiv>
                              </div>
                            </FormGroup>
                          </Form>
                        </Loader>
                      </PanelGroup.Content>
                    </PanelGroup>
                  ) : null}

                  {ActionOptions.showDv ? (
                    <PanelGroup collapsible css={{ padding: "1rem" }}>
                      <PanelGroup.Header
                        title={t("location-edit:Finished Vehicle Damage View")}
                      />
                      <PanelGroup.Content
                        style={{
                          padding: 0,
                          minHeight: !isDamageViewLocationConfigLoading
                            ? null
                            : "5rem",
                        }}
                      >
                        <Loader loaded={!isDamageViewLocationConfigLoading}>
                          <Form>
                            <FormGroup css={{ marginBottom: "1rem" }}>
                              <div
                                className="d-flex align-items-center"
                                css={{ margin: "1rem 2rem" }}
                              >
                                <span css={{ marginRight: "1rem" }}>
                                  {t(
                                    "location-edit:Add Location for Damage View",
                                  )}
                                </span>
                                <Form.Check
                                  type="switch"
                                  label=""
                                  id="inventoryView-switch"
                                  checked={
                                    this.state.dvLocationData
                                      .isDamageViewAddLocationEnabled
                                  }
                                  onChange={() => {
                                    this.setState((prevState) => {
                                      return {
                                        dvLocationData: {
                                          isDamageViewAddLocationEnabled:
                                            !prevState.dvLocationData
                                              .isDamageViewAddLocationEnabled,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Form>
                        </Loader>
                      </PanelGroup.Content>
                    </PanelGroup>
                  ) : null}

                  {ActionOptions.showCp ? (
                    <PanelGroup collapsible css={{ padding: "1rem" }}>
                      <PanelGroup.Header
                        title={t("location-edit:Critical Parts")}
                      />
                      <PanelGroup.Content
                        style={{
                          padding: 0,
                          minHeight: !isCriticalPartsLocationConfigLoading
                            ? null
                            : "5rem",
                        }}
                      >
                        <Loader loaded={!isCriticalPartsLocationConfigLoading}>
                          <Form>
                            <FormGroup css={{ marginBottom: "1rem" }}>
                              <div
                                className="d-flex align-items-center"
                                css={{ margin: "1rem 2rem" }}
                              >
                                <Text css={{ marginRight: "1rem" }}>
                                  {t(
                                    "location-edit:Add Location for Critical Parts",
                                  )}
                                </Text>
                                <Form.Check
                                  type="switch"
                                  label=""
                                  id="criticalParts-switch"
                                  checked={
                                    this.state.cpLocationData
                                      .isCriticalPartsAddLocationEnabled
                                  }
                                  onChange={() => {
                                    this.setState((prevState) => {
                                      return {
                                        cpLocationData: {
                                          isCriticalPartsAddLocationEnabled:
                                            !prevState.cpLocationData
                                              .isCriticalPartsAddLocationEnabled,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Form>
                        </Loader>
                      </PanelGroup.Content>
                    </PanelGroup>
                  ) : null}

                  <FlexColDiv
                    css={{
                      backgroundColor: Colors.background.LIGHT_GRAY,
                      color: Colors.text.DARK_BLUE,
                      padding: "2em",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      css={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignSelf: "flex-end",
                      }}
                    >
                      {(!readOnly || hasManageGeofenceName) &&
                      (ActionOptions.showDpu ||
                        ActionOptions.showInv ||
                        ActionOptions.showDv ||
                        ActionOptions.showCp) ? (
                        <div>
                          <Button
                            variant={"secondary"}
                            css={{
                              marginRight: "0.5em",
                            }}
                            className="me-1"
                            onClick={() => {
                              const isResolving =
                                _.isEmpty(unresolvedLocation) === false;
                              pushLocationsScreen(isResolving);
                            }}
                          >
                            {t("location-edit:Cancel")}
                          </Button>

                          {((!isNewLocation && !readOnly) ||
                            hasManageGeofenceName) && (
                            <Button
                              variant={"success"}
                              disabled={
                                isConfigSaving ||
                                (ActionOptions.showEnableDpu &&
                                  this.state.dpuAddLocationData
                                    .isDpuAddLocationEnabled &&
                                  !this.isScacListValid(
                                    this.state.scacModificationList,
                                  ))
                              }
                              onClick={() => {
                                this.setState({
                                  showError: false,
                                });

                                if (ActionOptions.showDpu) {
                                  // Have to check Dpu first and give some confirmation
                                  if (!_.some(ddaLocationData.pickupLocation)) {
                                    this.setState({
                                      showDdaConfirmationDialog: true,
                                    });
                                  } else {
                                    updateConfiguration(
                                      locationId,
                                      ddaLocationData,
                                      ActionOptions,
                                    );
                                  }
                                } else {
                                  updateConfiguration(
                                    locationId,
                                    ddaLocationData,
                                    ActionOptions,
                                  );
                                }
                              }}
                            >
                              {t("location-edit:Update Location")}
                            </Button>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <Alert
                      show={this.state.showError}
                      dismissible={false}
                      variant={AlertVariant.Danger}
                      style={{ marginTop: "1em" }}
                    >
                      <Text bold>{t("location-edit:Warning")}: </Text>
                      <Text>
                        {t(
                          "location-edit:Something went wrong. Please try again.",
                        )}
                      </Text>
                    </Alert>
                  </FlexColDiv>
                </div>
              </Tabs.TabPanel>
            ) : null}
          </Tabs>
        </Section>
        <Section>
          <GeofenceBuilderMap
            showHeatmap={showHeatmap}
            heatmapCoords={heatmapData}
            onDragGeofenceControlPoint={this.onDragGeofenceControlPoint}
            onDragPolygonalGeofence={this.onDragPolygonalGeofence}
            onDragRadialGeofence={this.onDragRadialGeofence}
            selectedLocation={mapLocationData}
            mapLocations={mapLocationData ? [mapLocationData] : []}
            addPointToTrace={this.addPointToTrace}
            deletePointFromPolygon={this.deletePointFromPolygon}
            updatePolygonPoints={this.updatePolygonPoints}
            deletePointFromTrace={this.deletePointFromTrace}
            selectedLad={selectedLad}
            enableDraggingGeofence={!readOnly}
            enableGeofenceBuilder
            isTracing={isTracing}
            tracePoints={tracePoints}
            onPolygonDrawEnd={this.createPolygonFromTrace}
            useBoxChiclets
          />
          {isTracing && (
            <div
              style={{
                background: "rgba(251, 210, 14, 0.5)",
                padding: "0.5em 0 0.5em 0",
                color: "#364251",
                fontWeight: "bold",
                position: "absolute",
                top: 0,
                right: 0,
                width: "50%",
                textAlign: "center",
                fontSize: "1.0em",
              }}
            >
              {t(
                "Click on map to draw new geofence; click on first point again to complete",
              )}
            </div>
          )}
          {heatmapData?.length > 0 ? (
            <HeatMapButton
              isToggled={showHeatmap}
              onToggle={() => this.setState({ showHeatmap: !showHeatmap })}
              isLoading={heatmapIsLoading}
              css={{
                position: "absolute",
                top: 25,
                right: 25,
              }}
            />
          ) : null}
        </Section>
        <DeleteLocationModal
          show={showDeleteLocationModal}
          hide={() => this.setState({ showDeleteLocationModal: false })}
          handleConfirm={() => {
            deleteLocation(locationId).then(() => {
              pushLocationsScreen();
            });
          }}
        />
        <DeletePolygonModal
          show={showDeletePolygonModal}
          hide={() =>
            this.setState({
              showDeletePolygonModal: false,
              editingPolygonIndex: null,
            })
          }
          handleConfirm={() => {
            this.deletePolygon(editingPolygonIndex);
          }}
        />
        <UpdateGeofenceTypeModal
          show={this.state.showUpdateGeofenceTypeModal}
          fenceType={fenceType}
          hide={() =>
            this.setState({
              showUpdateGeofenceTypeModal: false,
            })
          }
          handleConfirm={this.updateGeofenceType}
        />
        <DialogModal
          show={this.state.showDdaConfirmationDialog}
          onHide={() => this.setState({ showDdaConfirmationDialog: false })}
          title={t("location-edit:Update Location")}
          cancelButtonText={t("location-edit:Cancel")}
          submitButtonText={t("location-edit:Update")}
          submitButtonVariant="primary"
          size="sm"
          submitButtonDisabled={isConfigSaving}
          onSubmit={() => {
            updateConfiguration(locationId, ddaLocationData, ActionOptions);
            this.setState({ showDdaConfirmationDialog: false });
          }}
        >
          <Text block>
            {t(
              "location-edit:There has been no entry made on Dealer Pick Up. Are you sure you want to update location?",
            )}
          </Text>
        </DialogModal>
      </div>
    );
  }
}

export default withTranslation(["location-edit"])(LocationEditView);
