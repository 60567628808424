import { connect } from "react-redux";

import InventoryViewDashboard from "./InventoryView.Dashboard.page";

import InventoryViewDashboardState from "../redux/InventoryViewDashboardState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";
import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";
import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
const { setCurrentLocation } = InventorySearchBarState.actionCreators;

const mapStateToProps = (state) => {
  return {
    solutionId: getSolutionId(state),
    watchedLocations:
      InventoryViewDashboardState.selectors.getWatchedLocations(state),
    allWatchedLocations:
      InventoryViewDashboardState.selectors.getAllWatchedLocations(state),
    isWatchedLocationsLoading:
      InventoryViewDashboardState.selectors.getWatchedLocationsLoading(state),
    isAllWatchedLocationsLoading:
      InventoryViewDashboardState.selectors.getAllWatchedLocationsLoading(
        state,
      ),
    currentOrganization: getActiveOrganization(state),
    watchedLocationsPageCount:
      InventoryViewDashboardState.selectors.getWatchedLocationsPageCount(state),
  };
};

const { searchEntities } = InventorySearchBarState.actionCreators;

const actionCreators = {
  searchEntities,
  redirectToDashboardView:
    InventoryViewDetailsState.actionCreators.redirectToDashboardView,
  setSelectedTabIndex:
    InventoryViewDetailsState.actionCreators.setSelectedTabIndex,
  fetchWatchedLocations:
    InventoryViewDashboardState.actionCreators.fetchWatchedLocations,
  fetchAllWatchedLocations:
    InventoryViewDashboardState.actionCreators.fetchAllWatchedLocations,
  saveWatchedLocations:
    InventoryViewDashboardState.actionCreators.saveWatchedLocations,
  resetSearchAndFilters:
    InventorySearchBarState.actionCreators.resetSearchAndFilters,
  redirectToInsightsView: (locationId) => (dispatch) => {
    dispatch(setCurrentLocation(locationId));
    dispatch(
      InventoryViewDetailsState.actionCreators.redirectToInsightsView(
        locationId,
      ),
    );
  },
  redirectToDetailsView: (solutionId, locationId) => (dispatch) => {
    dispatch(setCurrentLocation(locationId));
    dispatch(searchEntities(solutionId));
    dispatch(
      InventoryViewDetailsState.actionCreators.redirectToDetailsView(
        locationId,
      ),
    );
  },
  setSearchFilter: InventorySearchBarState.actionCreators.setSearchFilter,
};

export default connect(mapStateToProps, actionCreators)(InventoryViewDashboard);
