/** @jsxImportSource @emotion/react */
// Third party Libraries
import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";

// Third party UI Libraries
import { FormControl } from "react-bootstrap";
import DatePicker from "react-widgets/lib/DatePicker";
import TimePicker from "react-widgets/lib/TimePicker";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
  faInfoCircle,
  faPlusCircle,
  faMinusCircle,
} from "@fortawesome/pro-solid-svg-icons";

// UI Libraries
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { Dropdown } from "components/molecules/Dropdown.molecule";
import { Text } from "components/atoms/Text.atom";
import { Radio } from "components/atoms/Radio.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { Alert } from "react-bootstrap";

import Colors from "styles/colors";
import { translateDenyReason } from "pages/driveaway/utils/driveaway.utils";
import {
  getDateFormatFromUserPreferences,
  getTimeFormatFromUserPreferences,
} from "utils/date-time";
import { Checkbox } from "components/atoms/Checkbox.atom";
import { DPUsubStatus } from "pages/driveaway/utils/enums.utils";

export const ReviewRequestModal = ({
  daId,
  prefilledTimeWindows,
  pickUpWindowTzAbbr,
  pickUpWindowTz,
  showDenyRadioButton,
  show,
  setShowReviewRequestModal,
  submitDriveAwayRequestReview,
  driveAwayDenyReasons,
  isDenyReasonsLoading,
  isDenyReasonModifyModal,
  deniedReason,
  deniedComment,
  isModalLoading,
  subStatus,
  persistentApprovalEnabled,
}) => {
  const { t } = useTranslation("driveaway-search");

  // The maximum number of time windows the user can add by clicking the "Add pick up window" button
  const MAXIMUM_TIME_WINDOWS = 10;

  const defaultTimeWindow = {
    id: "0",
    startTs: moment()
      .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
      .toDate(),
    endTs: moment()
      .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
      .toDate(),
  };

  const [selectedApproveRequest, setSelectedApproveRequest] = useState(true);
  const [showDenyReasonDropdown, setShowDenyReasonDropdown] = useState(false);
  const [denyReason, setDenyReason] = useState("Select");
  const [isLoading, setIsLoading] = useState(false);
  const [loadStatus, setLoadStatus] = useState("");
  const [approvalId, setApprovalId] = useState("");
  const [denyReasonExplanation, setDenyReasonExplanation] = useState("");
  const [timeWindows, setTimeWindows] = useState([_.clone(defaultTimeWindow)]);
  const [timeWindowKey, setTimeWindowKey] = useState(1);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const [showInvalidDateMsg, setShowInvalidDateMsg] = useState(false);
  const [showSameDayModificationMsg, setShowSameDayModificationMsg] =
    useState(false);
  const [showInvalidTimeMsg, setShowInvalidTimeMsg] = useState(false);
  const [userComment, setUserComment] = useState("");
  const [selectedPersistentApproval, setSelectedPersistentApproval] =
    useState(null);

  const [
    userCommentWhenDenyReasonIsOther,
    setUserCommentWhenDenyReasonIsOther,
  ] = useState("");

  const dateFormat = getDateFormatFromUserPreferences(); // : "l"
  const timeFormat = getTimeFormatFromUserPreferences();

  useEffect(() => {
    setSelectedApproveRequest(!isDenyReasonModifyModal);
    setDenyReason(deniedReason ?? "Select");
    setUserCommentWhenDenyReasonIsOther(deniedComment);
    setSelectedPersistentApproval(subStatus);
  }, [isDenyReasonModifyModal, deniedReason, deniedComment, subStatus]);

  function resetState() {
    setSelectedApproveRequest(true);
    setShowDenyReasonDropdown(false);
    setTimeWindows([_.clone(defaultTimeWindow)]);
    setTimeWindowKey(1);
    setDenyReason("Select");
    setIsLoading(false);
    setLoadStatus("");
    setApprovalId("");
    setDenyReasonExplanation("");
    setShowInvalidDateMsg(false);
    setShowSameDayModificationMsg(false);
    setShowInvalidTimeMsg(false);
    setUserComment("");
    setUserCommentWhenDenyReasonIsOther("");
    setSelectedPersistentApproval(null);
  }

  /**
   *  @description This function preprocesses the time windows received from the backend.
   *  This is so the user can choose pick up time in the pick up location's time zone.
   *  The timewindows from the backend are in UTC but does not indicate so. We need to first tell
   *  moment that it's UTC and then apply the pickup window time zone information.
   */
  const preprocessPrefilledWindow = (window, pickUpWindowTz) => {
    return moment(
      moment
        .tz(moment.utc(window), pickUpWindowTz)
        .format("YYYY-MM-DD HH:mm:SS"),
    );
  };

  const initPrefilledWindows = useCallback(
    (prefilledTimeWindows, pickUpWindowTz) => {
      let key = timeWindowKey;
      let windows = [];
      prefilledTimeWindows.forEach((timeWindow) => {
        const newStartTime = preprocessPrefilledWindow(
          timeWindow.startTs,
          pickUpWindowTz,
        ).toDate();
        const newEndTime = preprocessPrefilledWindow(
          timeWindow.endTs,
          pickUpWindowTz,
        ).toDate();
        const newTimeWindow = {
          id: `${key}`,
          startTs: newStartTime,
          endTs: newEndTime,
        };
        windows.push(newTimeWindow);
        key += 1;
      });
      setTimeWindows((_) => {
        return [...windows];
      });
      setTimeWindowKey(key);
    },
    [timeWindowKey],
  );

  useEffect(() => {
    validateInput();
    setShowSameDayModificationMsg(
      prefilledTimeWindows?.some((timeWindow) =>
        moment().isSame(timeWindow.startTs, "day"),
      ),
    );

    // Disable the input box on date picker and time pickers
    const widgetInputs = document.querySelectorAll(".rw-widget-input");
    widgetInputs.forEach((widget) => {
      widget.setAttribute("readonly", true);
    });
  });

  useEffect(() => {
    if (prefilledTimeWindows) {
      initPrefilledWindows(prefilledTimeWindows, pickUpWindowTz);
    }
  }, [prefilledTimeWindows]);

  /**
   *  @discussion The requirement was to allow the user to choose pick up time from the vehicle pickup location timezone
   *  However, the TimePicker component onChange value give a Date object which has the user's local time offset instead
   *  of the pickup location timezone so this function exists to do the following:
   *    1. Strip off local time offset without applying it
   *    2. Add the pickup location's offset
   *  If there's a better way to do this please suggest.
   */
  const toPickUpLocationTz = (time) =>
    moment
      .tz(moment(time).format("YYYY-MM-DD HH:mm:SS"), pickUpWindowTz)
      .toDate();

  const onSubmitRequest = () => {
    if (_.isNil(daId)) {
      return;
    }
    if (selectedApproveRequest) {
      const payload = {
        status: "approved",
        pickup_window: timeWindows.map((window) => {
          return {
            startTs: toPickUpLocationTz(window.startTs),
            endTs: toPickUpLocationTz(window.endTs),
          };
        }),
        comments: userComment,
        persistent: selectedPersistentApproval,
      };
      submitDriveAwayRequestReview({
        daId,
        payload,
        setIsLoading,
        setLoadStatus,
        setApprovalId,
      });
    } else {
      const payload = {
        status: "denied",
        reason_code: denyReason,
        comments: userCommentWhenDenyReasonIsOther,
        persistent: selectedPersistentApproval,
      };
      submitDriveAwayRequestReview({
        daId,
        payload,
        setIsLoading,
        setLoadStatus,
      });
    }
  };

  const debouncedSubmitRequest = useCallback(
    _.debounce(onSubmitRequest, 300, { leading: true }),
  );

  /**
   * @description Adds a new line on the UI for user to input time window.
   * When creating a new time window, the last time window in the timeWindows array is used as reference,
   * add 1 extra day, and sets it to from 9:00 AM to 3:00 PM
   * If the last time window in the array is invalid use today's day as reference,
   * add 1 extra day, and sets it to from 9:00 AM to 3:00 PM
   */
  const addPickUpWindowHandler = () => {
    const today = moment();
    const lastStartTs = timeWindows[timeWindows.length - 1].startTs;
    const lastEndTs = timeWindows[timeWindows.length - 1].endTs;
    const validLastStartTs =
      moment(lastStartTs).isValid() && moment(lastStartTs).isAfter(today);
    const validLastEndTs =
      moment(lastEndTs).isValid() && moment(lastEndTs).isAfter(today);

    const newstartTs = moment(validLastStartTs ? lastStartTs : moment())
      .add(1, "day")
      .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
      .toDate();
    const newendTs = moment(validLastEndTs ? lastEndTs : moment())
      .add(1, "day")
      .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
      .toDate();
    const newTimeWindow = {
      id: `${timeWindowKey}`,
      startTs: newstartTs,
      endTs: newendTs,
    };
    timeWindows.push(newTimeWindow);
    setTimeWindowKey(timeWindowKey + 1);
    setTimeWindows([...timeWindows]);
  };

  /**
   * @description Validates user input and enable/disable submit button
   */
  const validateInput = () => {
    if (selectedApproveRequest) {
      let timeValid = true;
      let dateValid = true;
      timeWindows.forEach((timeWindow) => {
        timeValid = timeValid ? isTwoHoursApart(timeWindow) : timeValid;
        dateValid = dateValid
          ? isSameOrBeforeCurrentDate(timeWindow)
          : dateValid;
      });

      setShowInvalidTimeMsg(!timeValid);
      setShowInvalidDateMsg(!dateValid);
      setSubmitButtonDisabled(!timeValid || !dateValid);
    } else {
      isDenyReasonModifyModal
        ? setSubmitButtonDisabled(
            denyReason === "Select" ||
              (denyReason === "OTHER" &&
                userCommentWhenDenyReasonIsOther &&
                userCommentWhenDenyReasonIsOther.trim().length === 0) ||
              (denyReason === deniedReason &&
                (userCommentWhenDenyReasonIsOther
                  ? deniedComment === userCommentWhenDenyReasonIsOther?.trim()
                  : true)),
          )
        : setSubmitButtonDisabled(
            denyReason === "Select" ||
              (denyReason === "OTHER" &&
                userCommentWhenDenyReasonIsOther &&
                userCommentWhenDenyReasonIsOther.trim().length === 0),
          );
    }
  };

  /**
   * @description Checks Start time and end time are at least 2 hours apart
   * @param {*} timeWindow
   * @returns
   */
  const isTwoHoursApart = (timeWindow) => {
    return (
      moment(timeWindow.endTs).diff(
        moment(timeWindow.startTs),
        "hours",
        true,
      ) >= 2
    );
  };

  /**
   * @description Checks Start date can not be before current date
   * @param {*} timeWindow
   * @returns
   */
  const isSameOrBeforeCurrentDate = (timeWindow) => {
    return moment(moment()).isSameOrBefore(timeWindow.startTs, "day");
  };

  const showPersistentApproval =
    subStatus === DPUsubStatus.PERSISTENT || persistentApprovalEnabled;

  return (
    <DialogModal
      style={{ width: "50%" }}
      show={show}
      onHide={() => {
        setShowReviewRequestModal(false);
        resetState();
      }}
      isLoading={isLoading}
      loadStatus={loadStatus}
      closeOnBlur={false}
      title={
        isDenyReasonModifyModal || isModalLoading
          ? t("driveaway-search:Modify Denial")
          : t("driveaway-search:Review Request")
      }
      cancelButtonText={t("driveaway-search:Cancel")}
      submitButtonText={t("driveaway-search:Submit")}
      okButtonText={t("driveaway-search:Ok")}
      submitButtonVariant="primary"
      onSubmit={debouncedSubmitRequest}
      size="md"
      submitButtonDisabled={
        isModalLoading ||
        submitButtonDisabled ||
        (showSameDayModificationMsg && timeWindows.length === 1)
      }
    >
      {!isModalLoading && isLoading === false && loadStatus === "" ? (
        <div>
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "1em",
              justifyContent: isDenyReasonModifyModal ? "center" : "normal",
            }}
          >
            {!isDenyReasonModifyModal ? (
              <div
                onClick={() => setSelectedApproveRequest(true)}
                css={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 10,
                  userSelect: "none",
                }}
              >
                <Radio
                  checked={selectedApproveRequest}
                  color={Colors.filters.CHECK_DEFAULT_COLOR}
                  checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
                  disabledColor={Colors.text.DISABLED}
                  css={{ alignItems: "center", cursor: "pointer" }}
                />
                {t("driveaway-search:Approve")}
              </div>
            ) : null}

            {showDenyRadioButton ? (
              <div
                onClick={() => setSelectedApproveRequest(false)}
                className="ms-3"
                css={{
                  display: "flex",
                  flexDirection: "row",
                  userSelect: "none",
                }}
              >
                <Radio
                  checked={!selectedApproveRequest || isDenyReasonModifyModal}
                  color={Colors.filters.CHECK_DEFAULT_COLOR}
                  checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
                  disabledColor={Colors.text.DISABLED}
                  css={{ alignItems: "center", cursor: "pointer" }}
                  onClick={() => {}}
                />
                {t("driveaway-search:Deny")}
              </div>
            ) : null}
          </div>

          {showPersistentApproval && (
            <div
              onClick={() => {
                if (selectedPersistentApproval === DPUsubStatus.PERSISTENT) {
                  setSelectedPersistentApproval(null);
                } else {
                  setSelectedPersistentApproval(DPUsubStatus.PERSISTENT);
                }
              }}
              css={{
                marginBottom: "1em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingRight: 10,
              }}
            >
              <Checkbox
                checked={selectedPersistentApproval === DPUsubStatus.PERSISTENT}
                color={Colors.filters.CHECK_DEFAULT_COLOR}
                checkedColor={Colors.filters.CHECK_SELECTED_COLOR}
                disabledColor={Colors.text.DISABLED}
                css={{ alignItems: "center", cursor: "pointer" }}
                onClick={() => {}}
              />
              {t("driveaway-search:Persistent Approval")}

              <Tooltip
                css={{ marginLeft: "0.5em" }}
                placement="right"
                tooltipChildren={
                  <Text>
                    {t(
                      "driveaway-search:When this is enabled, this allows carriers to provide a persistent approval which allows a DPU request to remain in Approved even if the VIN is shipped or bayed at a new location.",
                    )}
                  </Text>
                }
              >
                <Icon src={faInfoCircle} />
              </Tooltip>
            </div>
          )}

          {!selectedApproveRequest && (
            <div>
              {denyReason === "OTHER" &&
              (!userCommentWhenDenyReasonIsOther ||
                userCommentWhenDenyReasonIsOther.trim().length === 0) ? (
                <Alert variant="warning">
                  <div>
                    {t(
                      `driveaway-search:Comments for "OTHER" reason code is mandatory. Please enter a comment before submission`,
                    )}
                  </div>
                </Alert>
              ) : null}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isDenyReasonModifyModal ? "center" : "normal",
                }}
              >
                <Text>{t("driveaway-search:Select Reason Code")}</Text>
                <Dropdown
                  className="ms-3"
                  responsiveModalMenu
                  show={showDenyReasonDropdown}
                  variant="outline-secondary"
                  text={denyReason}
                  onToggle={(isShown) => {
                    setShowDenyReasonDropdown(isShown);
                  }}
                  alignMenuRight={false}
                >
                  {isDenyReasonsLoading && (
                    <Text
                      size={FontSize.size16f}
                      align="center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Icon spin src={faSpinner} style={{ marginRight: 10 }} />
                    </Text>
                  )}
                  {!isDenyReasonsLoading &&
                    driveAwayDenyReasons?.map((reason) => (
                      <Dropdown.Item
                        key={`key.${reason.record_code}`}
                        onClick={() => {
                          setDenyReason(reason.record_code);
                          setDenyReasonExplanation(
                            reason.explanation?.length > 0
                              ? reason.explanation
                              : "",
                          );
                        }}
                      >
                        <Text>{translateDenyReason(t, reason.record)}</Text>
                      </Dropdown.Item>
                    ))}
                </Dropdown>
                {denyReasonExplanation && (
                  <Tooltip
                    placement="top"
                    tooltipChildren={<Text>{denyReasonExplanation}</Text>}
                  >
                    <Icon
                      src={faInfoCircle}
                      color={Colors.comments.unreadCommentIcon}
                      style={{ marginLeft: 8 }}
                    />
                  </Tooltip>
                )}
              </div>

              <div>
                <hr />
                <Text> {t("driveaway-search:Comments")}</Text>
                <FormControl
                  as={"textarea"}
                  rows={4}
                  style={{
                    backgroundColor: "white",
                    boxShadow: "none",
                    borderRadius: "5px",
                    borderWidth: 2,
                    width: "100%",
                  }}
                  value={userCommentWhenDenyReasonIsOther}
                  onChange={(evt) =>
                    setUserCommentWhenDenyReasonIsOther(evt.target.value)
                  }
                ></FormControl>
              </div>
            </div>
          )}
        </div>
      ) : null}
      {isModalLoading || isLoading === true ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Icon src={faSpinner} spin size={3} />
        </div>
      ) : null}
      {loadStatus === "success" ? (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Icon src={faCheckCircle} color="green" size={3} />
          </div>
          {selectedApproveRequest ? (
            <React.Fragment>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1em",
                }}
              >
                <Text>{t("driveaway-search:Approval Received")}</Text>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Text>
                  {t("driveaway-search:Approval ID")}: {approvalId}
                </Text>
              </div>
            </React.Fragment>
          ) : null}
        </div>
      ) : null}
      {!isModalLoading &&
        isLoading === false &&
        selectedApproveRequest &&
        loadStatus === "" && (
          <React.Fragment>
            {showInvalidDateMsg &&
            (_.isNil(prefilledTimeWindows) ||
              prefilledTimeWindows.length === 0) ? (
              <Alert variant="warning">
                <div>
                  {t(
                    `driveaway-search:All time window dates must be current or future.`,
                  )}
                </div>
              </Alert>
            ) : null}
            {showInvalidDateMsg && prefilledTimeWindows?.length > 0 ? (
              <Alert variant="warning">
                <div>
                  {t(
                    `driveaway-search:All time window dates must be in the future.`,
                  )}
                </div>
              </Alert>
            ) : null}
            {showSameDayModificationMsg ? (
              <Alert variant="warning">
                <div>
                  {t(
                    `driveaway-search:Dealer pick-up window can not be modified on the same date of pickup. Dealer may already be en-route to pickup within the existing timeframe.`,
                  )}
                </div>
              </Alert>
            ) : null}
            {showInvalidTimeMsg ? (
              <Alert variant="warning">
                <div>
                  {t(
                    `driveaway-search:Please select a valid pickup window time. Minimum of a 2 hour pickup window is required.`,
                  )}
                </div>
              </Alert>
            ) : null}
            <Text>
              {t(
                "driveaway-search:Please Schedule an Available Pickup Window for the Dealer",
              )}
              :
            </Text>

            {timeWindows.map((ele, idx) => {
              return (
                <div
                  key={"timeWindowKey" + ele.id}
                  style={{ display: "flex" }}
                  className="my-2"
                >
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <div>
                      {idx === 0 ? (
                        <Text style={{ padding: "0 1% 0 2%" }}>
                          {t("driveaway-search:Date")}:{" "}
                        </Text>
                      ) : null}
                      <DatePicker
                        disabled={prefilledTimeWindows?.some(
                          (timeWindow) =>
                            moment().isSame(timeWindow.startTs, "day") &&
                            moment().isSame(ele.startTs, "day"),
                        )}
                        min={
                          prefilledTimeWindows
                            ? moment().add(1, "d").toDate()
                            : new Date(+0)
                        }
                        className="pe-2"
                        defaultValue={ele.startTs}
                        format={dateFormat}
                        onChange={(value) => {
                          const newDate = moment(value).date();
                          const newMonth = moment(value).month();
                          const newYear = moment(value).year();
                          timeWindows[idx].startTs = moment(
                            timeWindows[idx].startTs,
                          )
                            .date(newDate)
                            .month(newMonth)
                            .year(newYear)
                            .toDate();
                          timeWindows[idx].endTs = moment(
                            timeWindows[idx].endTs,
                          )
                            .date(newDate)
                            .month(newMonth)
                            .year(newYear)
                            .toDate();
                          setTimeWindows([...timeWindows]);
                        }}
                      />
                    </div>
                    <div>
                      {idx === 0 ? (
                        <Text style={{ padding: "0 1% 0 2%" }}>
                          {t("driveaway-search:From")}:{" "}
                        </Text>
                      ) : null}
                      <TimePicker
                        disabled={prefilledTimeWindows?.some(
                          (timeWindow) =>
                            moment().isSame(timeWindow.startTs, "day") &&
                            moment().isSame(ele.startTs, "day"),
                        )}
                        className="pe-2"
                        defaultValue={ele.startTs}
                        format={timeFormat}
                        timeFormat={timeFormat}
                        onChange={(value) => {
                          const newHour = moment(value).hour();
                          const newMinute = moment(value).minute();
                          const newSecond = moment(value).second();
                          const newMillisecond = moment(value).millisecond();
                          timeWindows[idx].startTs = moment(
                            timeWindows[idx].startTs,
                          )
                            .set({
                              hour: newHour,
                              minute: newMinute,
                              second: newSecond,
                              millisecond: newMillisecond,
                            })
                            .toDate();
                          setTimeWindows([...timeWindows]);
                        }}
                      />
                    </div>
                    <div>
                      {idx === 0 ? (
                        <Text style={{ padding: "0 1% 0 1%" }}>
                          {t("driveaway-search:To")}:{" "}
                        </Text>
                      ) : null}
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <TimePicker
                          disabled={prefilledTimeWindows?.some(
                            (timeWindow) =>
                              moment().isSame(timeWindow.startTs, "day") &&
                              moment().isSame(ele.startTs, "day"),
                          )}
                          defaultValue={ele.endTs}
                          format={timeFormat}
                          timeFormat={timeFormat}
                          onChange={(value) => {
                            const newHour = moment(value).hour();
                            const newMinute = moment(value).minute();
                            const newSecond = moment(value).second();
                            const newMillisecond = moment(value).millisecond();
                            timeWindows[idx].endTs = moment(
                              timeWindows[idx].endTs,
                            )
                              .set({
                                hour: newHour,
                                minute: newMinute,
                                second: newSecond,
                                millisecond: newMillisecond,
                              })
                              .toDate();
                            setTimeWindows([...timeWindows]);
                          }}
                        />
                        {timeWindows.length !== 1 &&
                        !prefilledTimeWindows?.some(
                          (timeWindow) =>
                            moment().isSame(timeWindow.startTs, "day") &&
                            moment().isSame(ele.startTs, "day"),
                        ) ? (
                          <div
                            className="d-inline-flex ps-2"
                            onClick={() => {
                              timeWindows.splice(idx, 1);
                              setTimeWindows([...timeWindows]);
                            }}
                          >
                            <Icon
                              style={{
                                cursor: "pointer",
                              }}
                              src={faMinusCircle}
                              color="red"
                              size={1.1}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {timeWindows.length !== MAXIMUM_TIME_WINDOWS ? (
              <div
                style={{
                  display: "flex",
                  backgroundColor: "#fff",
                  border: "#ccc 1px solid",
                  borderRadius: "4px",
                  height: "2.429em",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                className="my-2"
                onClick={() => addPickUpWindowHandler()}
              >
                <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <Text align="center">
                    {t("driveaway-search:Add pick up window")}
                  </Text>
                  <Icon
                    style={{ marginLeft: "0.4em" }}
                    src={faPlusCircle}
                    color="green"
                    size={1.1}
                  />
                </div>
              </div>
            ) : null}
            <div style={{ marginTop: "0.5em" }}>
              <Text>
                {t(
                  "driveaway-search:All time windows are in time zone of pickup location",
                )}{" "}
                {`(${moment.tz(moment(), pickUpWindowTz).format("z")})`}
              </Text>
            </div>
            <hr />
            <Text> {t("driveaway-search:Comments")}</Text>
            <FormControl
              as={"textarea"}
              rows={4}
              style={{
                backgroundColor: "white",
                boxShadow: "none",
                borderRadius: "5px",
                borderWidth: 2,
                width: "100%",
              }}
              value={userComment}
              onChange={(evt) => setUserComment(evt.target.value)}
            ></FormControl>
          </React.Fragment>
        )}

      {loadStatus === "success" && !selectedApproveRequest ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <Text>{t("driveaway-search:Successfully Denied Request")}</Text>
          </div>
        </React.Fragment>
      ) : null}
      {loadStatus === "failed" ? (
        <div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Icon src={faTimesCircle} color="red" size={3} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1em",
            }}
          >
            <Text>
              {t(
                "driveaway-search:There was an error. Please try again later.",
              )}
            </Text>
          </div>
        </div>
      ) : null}
    </DialogModal>
  );
};

ReviewRequestModal.propTypes = {
  daId: PropTypes.string,
  showDenyRadioButton: PropTypes.bool,
  prefilledTimeWindows: PropTypes.array,
  pickUpWindowTz: PropTypes.string,
  isDenyReasonModifyModal: PropTypes.bool,
  pickUpWindowTzAbbr: PropTypes.string,
  deniedReason: PropTypes.any,
  deniedComment: PropTypes.any,
  isModalLoading: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  setShowReviewRequestModal: PropTypes.func.isRequired,
  submitDriveAwayRequestReview: PropTypes.func.isRequired,
  driveAwayDenyReasons: PropTypes.array.isRequired,
  isDenyReasonsLoading: PropTypes.bool.isRequired,
  subStatus: PropTypes.any,
  persistentApprovalEnabled: PropTypes.bool,
};
