/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Colors from "styles/colors";

import { Button } from "components/atoms/Button.atom";
import { Tabs } from "components/molecules/Tabs.molecule";
import { ResponsiveSection } from "styles/container-elements";
import { AddLocationModal } from "./components/AddLocation.modal";

import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { InventoryLocationPanel } from "./components/InventoryLocationPanel";
import {
  selectedTab,
  tabsListStyle,
} from "./components/InventoryNavigationTabs";
import { faAdd } from "@fortawesome/pro-solid-svg-icons";
import { isCarrier, isShipper } from "shared/utils/organizations.utils";
import { PaginationControls } from "./components/PaginationControls";
import { BrowserStorage } from "utils/browser-storage.utils";
import { Dashboard } from "components/templates/Dashboard.template";
import InventoryViewDetailsSearchBarContainer from "../details/search/InventoryView.Details.SearchBarContainer";
import SearchFiltersContainer from "../details/search/InventoryView.Details.FilterSectionContainer";

const InventoryViewDashboard = ({
  solutionId,
  watchedLocations,
  allWatchedLocations,
  redirectToInsightsView,
  redirectToDetailsView,
  setSearchFilter,
  resetSearchAndFilters,
  fetchWatchedLocations,
  fetchAllWatchedLocations,
  isWatchedLocationsLoading,
  saveWatchedLocations,
  currentOrganization,
  watchedLocationsPageCount,
}) => {
  const { t } = useTranslation("inventory-view");

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Dashboard");

  const [showAddLocationModal, setShowAddLocationModal] = useState(false);
  const fvId = currentOrganization?.fv_id ?? "";
  const isShipperOrg = isShipper(currentOrganization);

  const [page, setPage] = useState(1);
  const totalPages = watchedLocationsPageCount;

  const handlePrevClick = () => {
    if (page <= 1) {
      setPage(1);
    } else {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    if (page >= totalPages) {
      setPage(totalPages);
    } else {
      setPage(page + 1);
    }
  };

  const addLocationModalHideHandler = () => {
    setShowAddLocationModal(false);
  };

  useEffect(() => {
    fetchWatchedLocations(page - 1);
    fetchAllWatchedLocations();
  }, [page, fetchWatchedLocations, fetchAllWatchedLocations]);

  // reset browser's locationTimezone
  BrowserStorage.locationTimezone = null;

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em 2em 2em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <div style={{ display: "none" }}>
        <Dashboard
          SearchBarContainer={InventoryViewDetailsSearchBarContainer}
          showFilters={false}
          toggleShowFilters={null}
          FiltersContainer={SearchFiltersContainer}
        ></Dashboard>
      </div>

      <Tabs>
        <Tabs.TabList css={{ ...tabsListStyle }}>
          <Tabs.Tab css={{ ...selectedTab }}>
            <Icon src={faHouse} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Home")}
            </Text>
          </Tabs.Tab>
          <div css={{ marginLeft: "auto" }}>
            <Button
              variant="success"
              onClick={() => {
                setShowAddLocationModal(true);
              }}
            >
              <Icon src={faAdd} /> {t("inventory-view:Add Location")}
            </Button>
          </div>
          <AddLocationModal
            show={showAddLocationModal}
            onHide={() => {
              addLocationModalHideHandler();
            }}
            watchedLocations={allWatchedLocations}
            saveWatchedLocations={saveWatchedLocations}
            onSave={() => {
              setPage(1);
              fetchWatchedLocations(page - 1);
              fetchAllWatchedLocations();
            }}
            fvId={fvId}
          ></AddLocationModal>
        </Tabs.TabList>

        <Tabs.TabPanel
          style={{ border: 0, background: "none", boxShadow: "none" }}
        >
          <div css={{ minHeight: 250, position: "relative" }}>
            <Loader loaded={!isWatchedLocationsLoading}>
              {watchedLocations.length > 0 ? (
                <div
                  style={{
                    display: "grid",
                    gridColumnGap: "20px",
                    gridRowGap: "20px",
                    justifyItems: "stretch",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(400px, 1fr))",
                  }}
                >
                  {watchedLocations.map((location) => {
                    return (
                      <InventoryLocationPanel
                        solutionId={solutionId}
                        location={location}
                        key={location?.locationId}
                        redirectToInsightsView={redirectToInsightsView}
                        redirectToDetailsView={redirectToDetailsView}
                        resetSearchAndFilters={resetSearchAndFilters}
                        setSearchFilter={(queryKey, value) => {
                          setSearchFilter(queryKey, value);
                          redirectToDetailsView(
                            solutionId,
                            location?.locationId,
                          );
                        }}
                        orgFvId={fvId}
                        isShipperOrg={isShipperOrg}
                        showTarget={!isCarrier(currentOrganization)}
                      />
                    );
                  })}
                </div>
              ) : (
                <Alert variant={AlertVariant.Primary} show>
                  <p>
                    {t(
                      "inventory-view:Please select your locations to proceed.",
                    )}
                  </p>
                </Alert>
              )}
            </Loader>
          </div>
          <PaginationControls
            page={page}
            totalPages={totalPages}
            onPrevClick={handlePrevClick}
            onNextClick={handleNextClick}
          />
        </Tabs.TabPanel>
      </Tabs>
    </ResponsiveSection>
  );
};

InventoryViewDashboard.propTypes = {
  solutionId: PropTypes.string,
  watchedLocations: PropTypes.array,
  allWatchedLocations: PropTypes.array,
  redirectToInsightsView: PropTypes.func,
  redirectToDetailsView: PropTypes.func,
  setSearchFilter: PropTypes.func,
  resetSearchAndFilters: PropTypes.func,
  fetchWatchedLocations: PropTypes.func,
  fetchAllWatchedLocations: PropTypes.func,
  isWatchedLocationsLoading: PropTypes.bool,
  isAllWatchedLocationsLoading: PropTypes.bool,
  saveWatchedLocations: PropTypes.func,
  currentOrganization: PropTypes.object,
  watchedLocationsPageCount: PropTypes.number,
};

export default InventoryViewDashboard;
