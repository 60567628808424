import axios from "axios";
import apiUrl from "api-url";
import moment from "moment";

const STORE_MOUNT_POINT = "driveAwayReviewRequest";

// URLs
const DRIVE_AWAY_REQUEST_URL = apiUrl(`/dda/dda/drive-away`);

// Actions
const SHOW_REVIEW_REQUEST_MODAL = `${STORE_MOUNT_POINT}/SHOW_REVIEW_REQUEST_MODAL`;
const HIDE_REVIEW_REQUEST_MODAL = `${STORE_MOUNT_POINT}/HIDE_REVIEW_REQUEST_MODAL`;
const RESET_DRIVE_AWAY_REVIEW_STATUS = `${STORE_MOUNT_POINT}/RESET_DRIVE_AWAY_REVIEW_STATUS`;
const DRIVE_AWAY_REVIEW_REQUEST = `${STORE_MOUNT_POINT}/DRIVE_AWAY_REVIEW_REQUEST`;
const DRIVE_AWAY_REVIEW_REQUEST_SUCCEEDED = `${STORE_MOUNT_POINT}/DRIVE_AWAY_REVIEW_REQUEST_SUCCEEDED`;
const DRIVE_AWAY_REVIEW_REQUEST_FAILED = `${STORE_MOUNT_POINT}/DRIVE_AWAY_REVIEW_REQUEST_FAILED`;
const FETCH_DENY_REASONS = `${STORE_MOUNT_POINT}/FETCH_DENY_REASONS`;
const RECEIVE_DENY_REASONS = `${STORE_MOUNT_POINT}/RECEIVE_DENY_REASONS`;

// Action creators
function submitDriveAwayRequestReview(data) {
  const { daId, payload, setIsLoading, setLoadStatus, setApprovalId } = data;

  return (dispatch) => {
    setIsLoading(true);
    dispatch({ type: DRIVE_AWAY_REVIEW_REQUEST });

    // Use the code below to mock successful api call
    // setTimeout(() => {
    //   dispatch({ type: DRIVE_AWAY_REVIEW_REQUEST_SUCCEEDED });
    //   setLoadStatus("success");
    //   setApprovalId('abc123');
    //   setIsLoading(false);
    // }, 2000);
    // return;

    return Promise.all([
      axios.patch(`${DRIVE_AWAY_REQUEST_URL}/${daId}`, payload),
    ])
      .then((responses) => {
        dispatch({ type: DRIVE_AWAY_REVIEW_REQUEST_SUCCEEDED });
        setLoadStatus("success");
        if (payload.status === "approved") {
          setApprovalId(responses[0].data.approval_id);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        dispatch({ type: DRIVE_AWAY_REVIEW_REQUEST_FAILED, error: err });
        setLoadStatus("failed");
        setIsLoading(false);
      });
  };
}

function setDriveAwayReviewRequestSucceeded() {
  return (dispatch) => {
    dispatch({ type: DRIVE_AWAY_REVIEW_REQUEST_SUCCEEDED });
  };
}

function resetDriveAwayRequestReviewStatus() {
  return (dispatch) => {
    dispatch({ type: RESET_DRIVE_AWAY_REVIEW_STATUS });
  };
}

function setShowReviewRequestModal(
  showModal,
  daId,
  persistentApprovalEnabled,
  pickUpWindowTzAbbr,
  pickUpWindowTz,
  prefilledTimeWindows,
  showDenyRadioButton,
  isDenyReasonUpdate,
  deniedReason,
  subStatus,
) {
  if (showModal === true) {
    if (!isDenyReasonUpdate) {
      return (dispatch) => {
        dispatch({
          type: SHOW_REVIEW_REQUEST_MODAL,
          payload: daId,
          pickUpWindowTzAbbr: pickUpWindowTzAbbr,
          pickUpWindowTz: pickUpWindowTz,
          prefilledTimeWindows: prefilledTimeWindows,
          // if showDenyRadioButton is null or undefined, make it to be true
          showDenyRadioButton:
            showDenyRadioButton != null ? showDenyRadioButton : true,
          subStatus: subStatus,
          persistentApprovalEnabled: persistentApprovalEnabled,
        });
      };
    }
    return (dispatch) => {
      dispatch({
        type: SHOW_REVIEW_REQUEST_MODAL,
        isModalLoading: true,
      });
      let deniedComment = "";
      const commentUrl = apiUrl(`/dda/dpuId/${daId}/comment`);
      axios
        .get(commentUrl)
        .then((res) => {
          if (!res?.data?.data) {
            throw new Error("No data");
          }
          deniedComment = res.data.data?.[0]?.text;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          dispatch({
            type: SHOW_REVIEW_REQUEST_MODAL,
            isModalLoading: false,
            payload: daId,
            pickUpWindowTzAbbr: pickUpWindowTzAbbr,
            pickUpWindowTz: pickUpWindowTz,
            prefilledTimeWindows: prefilledTimeWindows,
            // if showDenyRadioButton is null or undefined, make it to be true
            showDenyRadioButton:
              showDenyRadioButton != null ? showDenyRadioButton : true,
            isDenyReasonUpdate: isDenyReasonUpdate,
            deniedReason: deniedReason,
            deniedComment: deniedComment,
            subStatus: subStatus,
            persistentApprovalEnabled: persistentApprovalEnabled,
          });
        });
    };
  } else {
    return (dispatch) => {
      dispatch({ type: HIDE_REVIEW_REQUEST_MODAL });
    };
  }
}

function fetchDenyReasons(solutionId) {
  const entitySearchUrl = apiUrl(
    `/entity-search/solution/${solutionId}/list?ddaDenyReasons=1`,
  );

  const config = {
    headers: {
      Accept: "application/json;version=count",
      "x-time-zone": moment.tz.guess(),
    },
  };

  return (dispatch) => {
    dispatch({
      type: FETCH_DENY_REASONS,
    });
    return Promise.all([axios.get(entitySearchUrl, config)])
      .then((responses) => {
        dispatch({
          type: RECEIVE_DENY_REASONS,
          payload: responses[0].data.ddaDenyReasons,
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  };
}

// Initial state
export const initialState = {
  createStatus: null,
  denyReasons: [],
  denyReasonsLoading: false,
  showReviewRequestModal: false,
  showDenyRadioButton: true,
  selectedDaId: null,
  pickUpWindowTzAbbr: null,
  pickUpWindowTz: null,
  prefilledTimeWindows: null,
  isModalLoading: false,
  isDenyReasonUpdate: false,
  deniedReason: null,
  deniedComment: null,
};

// Reducer
function RequestDriveAwayReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_DRIVE_AWAY_REVIEW_STATUS:
      return Object.assign({}, state, {
        createStatus: null,
      });
    case DRIVE_AWAY_REVIEW_REQUEST:
      return Object.assign({}, state, {
        createStatus: "IN_PROGRESS",
      });
    case DRIVE_AWAY_REVIEW_REQUEST_SUCCEEDED:
      return Object.assign({}, state, {
        createStatus: "SUCCESS",
      });
    case DRIVE_AWAY_REVIEW_REQUEST_FAILED:
      return Object.assign({}, state, {
        createStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 400
            ? "DUPLICATE"
            : "ERROR",
      });
    case FETCH_DENY_REASONS:
      return {
        ...state,
        denyReasonsLoading: true,
      };
    case RECEIVE_DENY_REASONS:
      return {
        ...state,
        denyReasons: action.payload,
        denyReasonsLoading: false,
      };
    case SHOW_REVIEW_REQUEST_MODAL:
      return {
        ...state,
        showReviewRequestModal: true,
        showDenyRadioButton: action.showDenyRadioButton,
        selectedDaId: action.payload,
        prefilledTimeWindows: action.prefilledTimeWindows,
        pickUpWindowTzAbbr: action.pickUpWindowTzAbbr,
        pickUpWindowTz: action.pickUpWindowTz,
        isDenyReasonUpdate: action.isDenyReasonUpdate,
        deniedReason: action.deniedReason,
        deniedComment: action.deniedComment,
        isModalLoading: action.isModalLoading,
        subStatus: action.subStatus,
        persistentApprovalEnabled: action.persistentApprovalEnabled,
      };
    case HIDE_REVIEW_REQUEST_MODAL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

// selector
const getRequestDriveAwayStatus = (state) => {
  return state[STORE_MOUNT_POINT].createStatus;
};
const getPrefilledTimeWindows = (state) => {
  return state[STORE_MOUNT_POINT].prefilledTimeWindows;
};
const getPickUpWindowTzAbbr = (state) => {
  return state[STORE_MOUNT_POINT].pickUpWindowTzAbbr;
};
const getPickUpWindowTz = (state) => {
  return state[STORE_MOUNT_POINT].pickUpWindowTz;
};
const getDenyReasons = (state) => {
  return state[STORE_MOUNT_POINT].denyReasons;
};
const isDenyReasonsLoading = (state) => {
  return state[STORE_MOUNT_POINT].denyReasonsLoading;
};
const getShowReviewRequestModal = (state) => {
  return state[STORE_MOUNT_POINT].showReviewRequestModal;
};
const getSelectedDaId = (state) => {
  return state[STORE_MOUNT_POINT].selectedDaId;
};
const getShowDenyRadioButton = (state) => {
  return state[STORE_MOUNT_POINT].showDenyRadioButton;
};

const getIsDenyReasonUpdate = (state) => {
  return state[STORE_MOUNT_POINT].isDenyReasonUpdate;
};

const getDeniedReason = (state) => {
  return state[STORE_MOUNT_POINT].deniedReason;
};

const getDeniedComment = (state) => {
  return state[STORE_MOUNT_POINT].deniedComment;
};

const getModalLoading = (state) => {
  return state[STORE_MOUNT_POINT].isModalLoading;
};

const getSubStatus = (state) => {
  return state[STORE_MOUNT_POINT].subStatus;
};

const getPersistentApprovalEnabled = (state) => {
  return state[STORE_MOUNT_POINT].persistentApprovalEnabled;
};

// interface
const DriveAwayReviewRequestState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    submitDriveAwayRequestReview,
    resetDriveAwayRequestReviewStatus,
    fetchDenyReasons,
    setDriveAwayReviewRequestSucceeded,
    setShowReviewRequestModal,
  },
  selectors: {
    getRequestDriveAwayStatus,
    getDenyReasons,
    isDenyReasonsLoading,
    getShowReviewRequestModal,
    getPickUpWindowTzAbbr,
    getPickUpWindowTz,
    getPrefilledTimeWindows,
    getSelectedDaId,
    getShowDenyRadioButton,
    getIsDenyReasonUpdate,
    getDeniedReason,
    getDeniedComment,
    getModalLoading,
    getSubStatus,
    getPersistentApprovalEnabled,
  },
  reducer: RequestDriveAwayReducer,
};
export default DriveAwayReviewRequestState;
