import { connect } from "react-redux";
import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";
import InventoryViewDetails from "./InventoryView.Details.page";
import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";

function mapStateToProps(state) {
  const locationId = state.location.payload.locationId;
  const { getLocation, getLocationTimezone } =
    InventoryViewDetailsState.selectors;

  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getShowAdvancedSearch,
    getSortColumn,
    getReverseSort,
    getTotalPages,
    getTotalEntities,
  } = InventorySearchBarState.selectors;

  return {
    solutionId: getSolutionId(state),
    locationId: locationId,
    currentOrganization: getActiveOrganization(state),
    location: getLocation(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    isLoading: getIsLoading(state),
    isOnsiteVinsCountLoading: false,
    searchResults: getSearchResults(state),
    totalCount: getTotalEntities(state),
    totalPages: getTotalPages(state),
    showFilters: getShowAdvancedSearch(state),
    locationTimezone: getLocationTimezone(state),
    exportIdentifier:
      InventorySearchBarState.selectors.getExportIdentifier(state),
    exportName: InventorySearchBarState.selectors.getExportName(state),
    isExporting: InventorySearchBarState.selectors.getIsExporting(state),
    exportFailed: InventorySearchBarState.selectors.getExportFailed(state),
  };
}

const { fetchLocation } = InventoryViewDetailsState.actionCreators;

const {
  searchEntities,
  setPagination,
  resetSearchAndFilters,
  toggleShowFilters,
  setSort,
} = InventorySearchBarState.actionCreators;

const actionCreators = {
  resetSearchAndFilters,
  onsiteVinsResetSearch: (solutionId) => (dispatch) => {
    dispatch(resetSearchAndFilters());
    dispatch(searchEntities(solutionId, true));
  },
  fetchLocation: fetchLocation,
  setDetailsSearchSort: setSort,
  setDetailsSearchPagination: setPagination,
  toggleShowFilters: toggleShowFilters,
  exportEntities: InventorySearchBarState.actionCreators.exportSearch,
  redirectToInsightsView:
    InventoryViewDetailsState.actionCreators.redirectToInsightsView,
  redirectToDashboardView:
    InventoryViewDetailsState.actionCreators.redirectToDashboardView,
  redirectToVinDetailsView:
    InventoryViewDetailsState.actionCreators.redirectToVinDetailsView,
  redirectToVinDetailsViewForPartner:
    InventoryViewDetailsState.actionCreators.redirectToVinDetailsViewForPartner,
  redirectToVinDetailsViewForCarrier:
    InventoryViewDetailsState.actionCreators.redirectToVinDetailsViewForCarrier,
  cleanupInventoryLocationDetails:
    InventoryViewDetailsState.actionCreators.cleanupInventoryLocationDetails,
};

export default connect(mapStateToProps, actionCreators)(InventoryViewDetails);
