/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import {
  faSpinner,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

import apiUrl from "api-url";
import { DialogModal } from "components/molecules/DialogModal.molecule";
import { FontSize, Text } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";

const CheckBoxOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  // styles
  let bg = "transparent";
  if (isFocused) {
    bg = "#eee";
  }

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        checked={isSelected}
        readOnly
        css={{ marginRight: "0.5rem" }}
      />
      {children}
    </components.Option>
  );
};

CheckBoxOption.propTypes = {
  getStyles: PropTypes.func,
  Icon: PropTypes.elementType,
  isDisabled: PropTypes.bool,
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  innerProps: PropTypes.object,
};

export const AddLocationModal = ({
  show,
  onHide,
  watchedLocations,
  saveWatchedLocations,
  fvId,
  onSave,
}) => {
  const { t } = useTranslation("inventory-view");

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [currentLoadStatus, setCurrentLoadStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isEdited, setIsEdited] = useState(false);

  // Initialize the selected locations with already watched locations
  useEffect(() => {
    if (show) {
      if (!_.isEmpty(watchedLocations)) {
        let formattedWatchedLocations = watchedLocations.map((location) => {
          return {
            value: location?.locationId,
            label: `${location?.name} - ${location?.code}`,
            data: location,
          };
        });
        setSelectedLocations(formattedWatchedLocations);
      } else {
        setSelectedLocations([]);
      }
    }
  }, [watchedLocations, show]);

  const loadLocations = async (currentVal, pageNumber) => {
    let data = [];
    let metaData = null;
    const url = apiUrl(
      `/entity-inventory/location-visibility/organization/${fvId}?pageSize=20&pageNumber=${pageNumber}&global_search_term=${searchText}`,
    );
    await axios
      .get(url)
      .then((res) => {
        data = res?.data?.data?.map((val) => {
          return {
            label: `${val?.name} - ${val?.code}`,
            value: val.id,
            data: val,
          };
        });
        metaData = res?.data?.meta;
      })
      .catch((err) => {
        console.log(err);
      });
    const dataValue = {
      options: data ?? [],
      hasMore: metaData
        ? metaData.currentPage + 1 < metaData.totalPages
        : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
    return dataValue;
  };

  const saveHandler = () => {
    setIsSaving(true);

    const watchedLocationIds = watchedLocations.map(
      (location) => location?.locationId,
    );
    const selectedLocationIds = selectedLocations.map(
      (location) => location.value,
    );

    // Get the locations that are unselected in order to call unwatch api
    const unselectedLocationIds = watchedLocationIds.filter(
      (id) => !selectedLocationIds.includes(id),
    );
    // Get the locations that are newly selected in order to call watch api
    const newSelectedLocationIds = selectedLocationIds.filter(
      (id) => !watchedLocationIds.includes(id),
    );

    let savePromise = saveWatchedLocations(
      newSelectedLocationIds,
      unselectedLocationIds,
    );

    savePromise
      .then((response) => {
        if (response) {
          setIsSaving(false);
          setCurrentLoadStatus("success");
          onSave();
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSaving(false);
        setCurrentLoadStatus("failed");
      });
  };

  const hideHandler = () => {
    if (onHide) {
      onHide();
      resetStatus();
    }
  };

  const resetStatus = () => {
    setCurrentLoadStatus(null);
    setIsSaving(false);
    setIsEdited(false);
  };

  return (
    <DialogModal
      show={show}
      size="md"
      onHide={hideHandler}
      title={t("inventory-view:Customize Locations")}
      cancelButtonText={t("inventory-view:Cancel")}
      submitButtonText={t("inventory-view:Save")}
      submitButtonVariant="success"
      submitButtonDisabled={!isEdited}
      onSubmit={saveHandler}
      closeOnBlur={false}
      isLoading={isSaving}
      okButtonText={t("inventory-view:Close")}
      loadStatus={currentLoadStatus}
    >
      {isSaving ? (
        <div css={{ display: "flex", justifyContent: "center" }}>
          <Icon src={faSpinner} spin size={FontSize.size32} />
        </div>
      ) : currentLoadStatus === "success" ? (
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Icon src={faCheckCircle} color="green" size={3} />
          <div
            css={{
              marginTop: "1em",
            }}
          >
            <Text>{t("inventory-view:Locations Added Successfully")}</Text>
          </div>
        </div>
      ) : currentLoadStatus === "failed" ? (
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Icon src={faTimesCircle} color="red" size={3} />
          <div
            css={{
              marginTop: "1em",
            }}
          >
            <Text>
              {t("inventory-view:Something Went Wrong. Please try again.")}
            </Text>
          </div>
        </div>
      ) : (
        <Fragment>
          <Text
            fontSize={FontSize.size12}
            css={{ textAlign: "center", marginBottom: "1rem" }}
            bold
            block
          >
            {t(
              "inventory-view:Please select the locations you want to see in the homepage widgets",
            )}
          </Text>
          <AsyncPaginate
            debounceTimeout={500}
            isMulti={true}
            cacheOptions={false}
            components={{
              Option: CheckBoxOption,
            }}
            onInputChange={(val) => {
              setSearchText(val);
            }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            value={selectedLocations}
            loadOptions={async (currentVal, options, additionalOptions) => {
              const data = await loadLocations(
                currentVal,
                additionalOptions && currentVal === additionalOptions?.prevValue
                  ? additionalOptions.pageNumber + 1
                  : 0,
              );
              return data;
            }}
            cacheUniqs={[searchText]}
            onChange={(selectedData) => {
              if (!isEdited) {
                setIsEdited(true);
              }
              setSelectedLocations(selectedData ?? []);
            }}
          />
        </Fragment>
      )}
    </DialogModal>
  );
};

AddLocationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  watchedLocations: PropTypes.array,
  saveWatchedLocations: PropTypes.func,
  fvId: PropTypes.string,
  onSave: PropTypes.func,
};
