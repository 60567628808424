/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import {
  useSetSubTitle,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
import { Tabs, TabsClassNames } from "components/molecules/Tabs.molecule";
import { ArrivalAndDeparturePanelGroup } from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import {
  endToEndTab,
  legTabs,
} from "components/multimodal-components/tabComponents";
import {
  tabsCss,
  tabPanelStyle,
  tabListStyle,
} from "components/multimodal-components/tabStyles";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import RoutingMap from "modules/map/components/RoutingMap";
import ShipmentContentPanel from "modules/shipment-detail/ShipmentContentPanel";
import { TripSummary } from "shared/components/organisms/TripSummary.organism";
import { PackageDetailsPanelGroup } from "pages/partview/details/components/organisms/PackageDetailsPanelGroup.organism";
import { UpdatesTable } from "pages/partview/details/components/organisms/UpdatesTable.organism";
import { ExceptionTable } from "pages/partview/details/components/organisms/ExceptionTable.organism";
import { OrderDetailsTable } from "pages/partview/details/components/organisms/OrderDetailsTable.organism";
import { PartsTable } from "pages/partview/details/components/organisms/PartsTable.organism";
import { PackageDetailTable } from "pages/partview/details/components/organisms/PackageDetailsTable.organism";
import {
  getFilteredTriplegsByType,
  useTripPlanAndUpdates,
  useTransformPlannedTripleg,
} from "pages/partview/utils/tripleg.utils";
import {
  useCurrentPositionDetails,
  transformLocation,
} from "pages/partview/utils/location.utils";
import { PackageStatus } from "pages/partview/utils/const";
import { useOrderPriorityNameTranslation } from "pages/partview/utils/useOrderPriorityNameTranslation";
import { EtaName, useEtaTranslations } from "shared/hooks/useEtaTranslations";
import { parseDateTime } from "utils/date-time";

export const PartViewDetails = ({
  trackingNumber,
  trackingNumberUUID,
  isLoading = true,
  fetchPackageDetails,
  packageDetails,
  setWatchPackage,
  isSetWatchPackageLoading,
  orders,
  fetchParts,
  fetchReferences,
  parts,
  references,
  meta,
  partsIsLoading,
  fetchShipmentsForTriplegs,
  clearAllTriplegDetails,
  clearPackageDetails,
  isShipmentsLoaded,
  shipmentsForTriplegs,
  coordinates,
  // Comments
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  markCommentsRead,
  updateComment,
  cancelUpdateComment,
  cancelAddComment,
  fetchNotification,
  // Map
  addCoordinate,
  clearCoordinatesByType,
  clearAllCoordinates,
  selectedMapCoordinate,
  currentMapCoordinate,
  // Subscription
  fetchSubscription,
  subscriptionRequestError,
  subscription,
  isSubscriptionLoading,
  updateSubscription,
  subscribe,
  unsubscribe,
  isSubscriptionUpdating,
  subscriptionUpdateSuccess,
  subscriptionUpdateError,
  activeOrganization,
  shipmentModes,
  // Shipment comments
  shipmentComments,
  isFetchingShipmentComments,
  isBatchShipmentCommentInProgress,
  isBatchShipmentCommentSuccessful,
  isBatchShipmentCommentFailed,
  fetchShipmentComments,
  addShipmentComment,
  cancelAddShipmentComment,
  updateShipmentComment,
  cancelUpdateShipmentComment,
  markShipmentCommentsRead,
  addBatchShipmentComments,
  clearBatchShipmentComments,
  // Deliver Package
  setPackageDeliveryEvent,
  resetSetPackageDeliveryEvent,
  isPackageDeliveryEventLoading,
  packageDeliveryEventRequestError,
  packageDeliveryEventRequestSuccess,
  pageConfig,
  // Error redirects
  redirectTo404,
}) => {
  const { t } = useTranslation("partview-details");

  useSetTitleOnMount(t("partview-details:Package Details"));

  useSetSubTitle(trackingNumber, trackingNumber);

  const hasDealerView = pageConfig?.isDealerView;
  let viewedPage = "Viewed Page: PartView / Package Details";

  if (hasDealerView) {
    viewedPage = "Viewed Page: PartView / Dealer Details";
  }

  useTrackWithMixpanelOnce(viewedPage);

  const { getTranslatedOrderPriorityName } = useOrderPriorityNameTranslation();
  const { getEtaTranslation } = useEtaTranslations();

  const [watch, setWatch] = useState(false);
  const [selectedLegID, setSelectedLegID] = useState(null);

  useEffect(() => {
    fetchPackageDetails(trackingNumber);
    fetchParts(trackingNumber);

    return () => clearPackageDetails();
  }, [trackingNumber, fetchPackageDetails, fetchParts, clearPackageDetails]);

  // Redirect when errors happen
  useEffect(() => {
    const status = packageDetails?.status ?? null;
    if (status === 404 || (status === 200 && _.isEmpty(packageDetails?.data))) {
      const errorMessage = t(
        "partview-details:Details for Package [[[trackingNumber]]] could not be found",
        { trackingNumber },
      );
      redirectTo404(errorMessage);
    }
  }, [trackingNumber, packageDetails, redirectTo404, t]);

  useEffect(() => {
    if (!_.isEmpty(packageDetails?.data)) {
      fetchSubscription(packageDetails.data);
      fetchReferences(trackingNumberUUID);
    }
  }, [
    trackingNumberUUID,
    packageDetails?.data,
    fetchSubscription,
    fetchReferences,
  ]);
  // Sync the actual watch value from API to state.
  const actualWatchedValue = packageDetails?.data?.Watched;
  useEffect(() => {
    setWatch(actualWatchedValue);
  }, [actualWatchedValue]);

  const triplegs = packageDetails?.data?.Triplegs;

  const plannedTriplegs = useMemo(
    () => getFilteredTriplegsByType(triplegs, "planned"),
    [triplegs],
  );

  const shipmentNumberAndLoadCode = plannedTriplegs.filter((leg, i) => {
    let lastLeg = plannedTriplegs.length - 1;

    if (lastLeg === i) {
      return leg;
    } else {
      return null;
    }
  });

  useEffect(() => {
    const requestToken = fetchShipmentsForTriplegs(packageDetails?.data);

    return () => {
      // Cancel pending requests.
      // This happens when packageDetails data change or when the component unmounts.
      if (requestToken) {
        requestToken.cancel();
      }
      clearAllTriplegDetails();
    };
  }, [fetchShipmentsForTriplegs, packageDetails?.data, clearAllTriplegDetails]);

  const [plannedStops, plannedTripPlanUpdates] = useTripPlanAndUpdates(
    packageDetails?.data,
  );

  const plannedTriplegsForMap = useTransformPlannedTripleg(
    packageDetails?.data,
  );

  const getActualOriginDeparture = useMemo(() => {
    const originDetail = packageDetails.data?.OriginDetail;

    if (originDetail?.hasOwnProperty("actualDeparture")) {
      return { ...originDetail };
    }

    const originLocationCode = originDetail?.code;
    const latestActualDeparture = plannedTripPlanUpdates
      ?.filter(
        ({ code, locationCode }) =>
          code === "DP" && locationCode === originLocationCode,
      )
      .map(({ eventTs }) => {
        return eventTs;
      })
      .sort((a, b) => new Date(b) - new Date(a))[0];

    return {
      ...originDetail,
      actualDeparture: latestActualDeparture?.toString(),
    };
  }, [packageDetails.data?.OriginDetail, plannedTripPlanUpdates]);

  const origin = transformLocation(
    getActualOriginDeparture,
    "origin",
    packageDetails.data,
  );

  const destination = transformLocation(
    packageDetails.data?.DestinationDetail,
    "destination",
    packageDetails.data,
  );
  // get position details from actual tripleg (shipment/partview generated)to show the current location of package on MAP and details panel
  const currentPositionDetails = useCurrentPositionDetails(
    packageDetails?.data,
    isShipmentsLoaded,
    shipmentsForTriplegs,
  );

  const shipmentsList = Object.values(shipmentsForTriplegs);

  const sortedShipmentList = _.sortBy(shipmentsList, "shipmentLegSequence");

  const isDelivered = (details) => {
    return (
      details?.data?.DestinationDetail?.actualArrival ||
      details?.data?.LifecycleState?.toUpperCase() === PackageStatus.DELIVERED
    );
  };

  const getDestinationLatLong = (details) => {
    let latLong = null;
    const actualLegs = _.filter(details?.data?.Triplegs, { type: "ACTUAL" });
    if (actualLegs) {
      actualLegs.forEach(function (leg) {
        if (leg?.destination?.code === details?.data?.DestinationDetail?.code) {
          latLong = {
            latitude: leg?.destination.latitude,
            longitude: leg?.destination.longitude,
          };
        }
      });
    }

    return latLong;
  };

  const latLong = getDestinationLatLong(packageDetails);

  //TODO: need to figure out which one to trigger this on
  const deliveryData = isDelivered(packageDetails)
    ? null
    : {
        trackingNumber: trackingNumber,
        packageContainerId: packageDetails?.data?.id,
        organizationFvId: activeOrganization?.fv_id,
        locationCode: packageDetails?.data?.DestinationDetail?.code,
        eventCode: "FD",
        eventType: "dealer",
        isException: false,
        latitude: latLong?.latitude,
        longitude: latLong?.longitude,
      };

  let content = null;

  if (isLoading || packageDetails?.isLoadingError) {
    content = <Loader />;
  } else {
    const eventHandler = (val, type) => {
      if (type === "SELECT_LEG") {
        setSelectedLegID(val);
      }
    };

    const getTabLabel = (shipment) => {
      let originCode = shipment.origin_location_code;
      let destinationCode = shipment.destination_location_code;
      return `${originCode} - ${destinationCode}`;
    };

    //hide leg tabs for dealer view.
    const childSummaries = !hasDealerView
      ? sortedShipmentList
          .map((shipment) => {
            if (!shipment || shipment.isPartViewShipment) {
              return null;
            }
            return {
              shipment_id: shipment.creator_shipment_id,
              active: _.toLower(shipment.active_status_ng) === "active",
            };
          })
          .filter((shipment) => {
            return shipment !== null;
          })
      : [];

    const tabs = [
      endToEndTab(eventHandler),
      ...legTabs(
        childSummaries,
        shipmentsForTriplegs,
        eventHandler,
        false,
        () => clearAllCoordinates(),
        getTabLabel,
      ),
    ];

    //filter the held exception to get it's resolved date time
    const heldExceptionDateTime = null;

    //checks for valid date format
    const { isValid } = heldExceptionDateTime
      ? parseDateTime(heldExceptionDateTime)
      : parseDateTime(destination?.eta);

    //show tbd for both hold and tbd eta universally
    const getEta = (eta) => {
      if (
        eta?.toLowerCase() === EtaName.HOLD ||
        eta?.toLowerCase() === EtaName.TBD ||
        heldExceptionDateTime
      ) {
        return getEtaTranslation(EtaName.TBD);
      } else if (eta?.toLowerCase() === EtaName.DELIVERED) {
        return null;
      } else {
        return eta;
      }
    };

    const packageEta = {
      eta: getEta(packageDetails.data?.DestinationEta),
    };

    const translatedOrderPriorityNameCode = getTranslatedOrderPriorityName(
      packageDetails.data?.OrderPriority,
    );

    content = (
      <Tabs fullWidthTabs css={tabsCss}>
        <Tabs.TabList maxTabs={3} staticTabs={1} style={tabListStyle}>
          {tabs}
        </Tabs.TabList>

        {/* End to End TabPanel */}
        <Tabs.TabPanel
          key="end-to-end"
          style={{ border: "1px solid #aaa" }}
          data-qa="panel-end-to-end"
        >
          <div css={{ overflow: "auto", padding: "1em" }}>
            {/* Detail Panel */}
            <PackageDetailsPanelGroup
              trackingNumber={trackingNumber}
              type={packageDetails.data?.Type}
              trailerEquipmentNumber={
                packageDetails.data?.TrailerEquipmentNumber
              }
              // We want to display the package's lifecycleState as "status"
              status={packageDetails.data?.LifecycleState}
              weight={packageDetails.data?.Weight}
              weightUnits={packageDetails.data?.UnitOfMeasure}
              exceptions={packageDetails?.data?.ActiveExceptionList}
              watch={watch}
              isSetWatchPackageLoading={
                isSetWatchPackageLoading || packageDetails.isLoading
              }
              onWatchChange={(watch) => {
                // Update local state, make the request, and refresh details.
                setWatch(watch);
                setWatchPackage(trackingNumber, watch, () => {
                  fetchPackageDetails(trackingNumber, hasDealerView);
                });
              }}
              // Map
              currentPositionDetails={currentPositionDetails}
              addCoordinate={addCoordinate}
              currentMapCoordinate={currentMapCoordinate}
              clearCoordinatesByType={clearCoordinatesByType}
              // Subscription
              subscribeeDetails={packageDetails.data}
              subscription={subscription}
              // Dependent on parts loaded to send in `subscriptionContext`.
              isSubscriptionLoading={isSubscriptionLoading || partsIsLoading}
              subscriptionRequestError={subscriptionRequestError}
              subscribe={subscribe}
              updateSubscription={updateSubscription}
              unsubscribe={unsubscribe}
              isSubscriptionUpdating={isSubscriptionUpdating}
              subscriptionUpdateSuccess={subscriptionUpdateSuccess}
              subscriptionUpdateError={subscriptionUpdateError}
              // "context" is passed to the initial subscription
              // for extra info on the confirmation email
              subscriptionContext={{
                parts:
                  parts?.map(
                    (part) =>
                      `${part.partNumber} ${part.name ? `(${part.name})` : ""}`,
                  ) ?? [],
                package_type: packageDetails.data?.Type,
              }}
              deliveryData={deliveryData}
              fetchPackageDetails={fetchPackageDetails}
              setPackageDeliveryEvent={setPackageDeliveryEvent}
              resetSetPackageDeliveryEvent={resetSetPackageDeliveryEvent}
              isPackageDeliveryEventLoading={isPackageDeliveryEventLoading}
              packageDeliveryEventRequestError={
                packageDeliveryEventRequestError
              }
              packageDeliveryEventRequestSuccess={
                packageDeliveryEventRequestSuccess
              }
            />
            {/* Arrivals and Departures */}
            <ArrivalAndDeparturePanelGroup
              origin={origin}
              destination={destination}
              hideScheduled={hasDealerView ? true : false}
              eta={packageEta.eta}
              onlyShowEtaDate={isValid}
              heldExceptionDateTime={heldExceptionDateTime}
              style={{ marginTop: "1em" }}
              visibleFields={{
                originActualArrival: false,
                destinationActualDeparture: false,
              }}
            />

            {/* Comment Feed */}
            {!hasDealerView ? (
              <CommentFeed
                fetchComments={(pageNumber, pageSize) => {
                  return fetchComments(trackingNumber, pageNumber, pageSize);
                }}
                isFetchingComments={isFetchingComments}
                comments={comments}
                showBatchUpload={false}
                addComment={(data) => {
                  return addComment(trackingNumber, data);
                }}
                cancelAddComment={cancelAddComment}
                updateComment={(commentId, updatedData) => {
                  return updateComment(trackingNumber, commentId, updatedData);
                }}
                cancelUpdateComment={cancelUpdateComment}
                markCommentsRead={(datetime) => {
                  return markCommentsRead(trackingNumber, datetime);
                }}
                fetchNotification={fetchNotification}
                style={{ marginTop: "1em" }}
              />
            ) : null}

            {/* Detail tabs */}
            <Tabs
              css={{
                marginTop: "1em",
                [TabsClassNames.Tab]: {
                  // Reduces the tabs padding so more text can fit horizontally.
                  padding: "0 2px",
                },
              }}
              fullWidthTabs
            >
              <Tabs.TabList>
                <Tabs.Tab>{t("partview-details:Trip Plan")}</Tabs.Tab>
                <Tabs.Tab>{t("partview-details:Updates")}</Tabs.Tab>

                <Tabs.Tab>{t("partview-details:Exceptions")}</Tabs.Tab>

                {!hasDealerView ? (
                  <Tabs.Tab>{t("partview-details:Coordinates")}</Tabs.Tab>
                ) : null}
                {pageConfig?.tabs.order.isVisible ? (
                  <Tabs.Tab>{t("partview-details:Order Details")}</Tabs.Tab>
                ) : null}
                <Tabs.Tab>{t("partview-details:Parts")}</Tabs.Tab>
                <Tabs.Tab>{t("partview-details:Package Details")}</Tabs.Tab>
              </Tabs.TabList>
              {/* Trip Summary */}
              <Tabs.TabPanel>
                {plannedTriplegs?.length > 0 ? (
                  <TripSummary
                    plannedStops={plannedStops}
                    hideMadBorder={false}
                    showMadTooltip={false}
                    hideScheduled={hasDealerView ? true : false}
                    showUpdates={false}
                    showComments={false}
                    showTripLegReferences={false}
                    showHolds={false}
                    showEta={false}
                    isPartView={true}
                  />
                ) : (
                  <TabPanelPlaceholder
                    text={t("partview-details:No Trip Plans")}
                  />
                )}
              </Tabs.TabPanel>
              <Tabs.TabPanel>
                {console.log(plannedTripPlanUpdates)}
                <UpdatesTable
                  updates={plannedTripPlanUpdates}
                  noUpdatesText={t("partview-details:No Updates")}
                />
              </Tabs.TabPanel>
              {/* Exceptions */}

              <Tabs.TabPanel>
                {packageDetails?.data?.ExceptionList?.length > 0 ? (
                  <ExceptionTable
                    exceptionList={packageDetails?.data?.ExceptionList}
                  />
                ) : (
                  <TabPanelPlaceholder
                    text={t("partview-details:No Active Exceptions")}
                  />
                )}
              </Tabs.TabPanel>

              {/* Coordinates tab */}
              {!hasDealerView ? (
                <Tabs.TabPanel>
                  {coordinates.length > 0 ? (
                    <CoordinatesTable
                      addCoordinate={(
                        coordinateType,
                        lat,
                        long,
                        time,
                        ind,
                        row,
                      ) => {
                        addCoordinate(coordinateType, lat, long, time, ind, {
                          City: row.city,
                          State: row.state,
                        });
                      }}
                      clearCoordinatesByType={clearCoordinatesByType}
                      selectedMapCoordinate={selectedMapCoordinate}
                      coords={coordinates}
                    />
                  ) : (
                    <TabPanelPlaceholder
                      text={t("partview-details:No Coordinates Available")}
                    />
                  )}
                </Tabs.TabPanel>
              ) : null}
              {/* Order Details tab */}
              {pageConfig?.tabs.order.isVisible ? (
                <Tabs.TabPanel>
                  {orders?.length > 0 ? (
                    <OrderDetailsTable orders={orders} />
                  ) : (
                    <TabPanelPlaceholder
                      text={t("partview-details:No Order Details")}
                    />
                  )}
                </Tabs.TabPanel>
              ) : null}
              {/* Parts */}
              <Tabs.TabPanel>
                {parts?.length > 0 ? (
                  <PartsTable parts={parts} meta={meta} />
                ) : (
                  <TabPanelPlaceholder text={t("partview-details:No Parts")} />
                )}
              </Tabs.TabPanel>
              {/* Package Details */}
              <Tabs.TabPanel>
                <PackageDetailTable
                  orderPriority={translatedOrderPriorityNameCode}
                  commodityCode={packageDetails.data?.CommodityCode}
                  shipmentNumberAndLoadCode={shipmentNumberAndLoadCode}
                  references={references}
                />
              </Tabs.TabPanel>
            </Tabs>
          </div>
        </Tabs.TabPanel>

        {/* hide Shipment Leg TabPanel(s) for dealer view only */}
        {!hasDealerView
          ? sortedShipmentList
              .filter((tripleg) => !tripleg.isPartViewShipment)
              .map((shipment, i) => (
                <Tabs.TabPanel
                  style={tabPanelStyle}
                  key={shipment.id}
                  data-qa={`panel-leg-${i + 1}`}
                >
                  <ShipmentContentPanel
                    shipment={shipment}
                    clearCoordinatesByType={clearCoordinatesByType}
                    addCoordinate={(
                      coordinateType,
                      lat,
                      long,
                      time,
                      ind,
                      row,
                    ) => {
                      addCoordinate(coordinateType, lat, long, time, ind, {
                        City: row.city,
                        State: row.state,
                        Country: row.country,
                      });
                    }}
                    enableActionMenu={false}
                    organization={activeOrganization}
                    shipmentModes={shipmentModes}
                    isShipmentLoaded={true}
                    isLoaded={true}
                    showVinsTab={false}
                    showEta={false}
                    // Comments
                    showComments={true}
                    comments={shipmentComments}
                    isFetchingComments={isFetchingShipmentComments}
                    isBatchCommentInProgress={isBatchShipmentCommentInProgress}
                    isBatchCommentSuccessful={isBatchShipmentCommentSuccessful}
                    isBatchCommentFailed={isBatchShipmentCommentFailed}
                    fetchComments={(pageNumber, pageSize) => {
                      return fetchShipmentComments(
                        shipment.id,
                        pageNumber,
                        pageSize,
                      );
                    }}
                    addComment={(data) => {
                      return addShipmentComment(shipment.id, data);
                    }}
                    cancelAddComment={cancelAddShipmentComment}
                    updateComment={(commentId, data) => {
                      return updateShipmentComment(
                        shipment.id,
                        commentId,
                        data,
                      );
                    }}
                    cancelUpdateComment={cancelUpdateShipmentComment}
                    markCommentsRead={(datetime) => {
                      return markShipmentCommentsRead(shipment.id, datetime);
                    }}
                    addBatchComments={(data, isCsvFormat) => {
                      return addBatchShipmentComments(data, isCsvFormat);
                    }}
                    clearBatchComments={clearBatchShipmentComments}
                    fetchNotification={fetchNotification}
                  />
                </Tabs.TabPanel>
              ))
          : null}
      </Tabs>
    );
  }

  const shipmentsForMap = selectedLegID
    ? [shipmentsForTriplegs[selectedLegID]]
    : sortedShipmentList ?? [];

  const isValidUuid = (str) => {
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
  };

  // Get the "active" shipment, looking at the end of the array, for the latest
  // shipment with "active" or "arrived" status.
  // Note: Array.reverse mutates the original array. Creating a new array before calling that function.
  const activeShipmentId =
    [...shipmentsForMap]
      .filter((shipment) => !isValidUuid(shipment.id)) // filter out auto generated actual trip legs
      .reverse()
      .find((shipment) => {
        const status = shipment.active_status_ng?.toLowerCase();
        return status === "active" || status === "arrived";
      })?.id ?? shipmentsForMap[0]?.id;

  const activeShipmentIdForPlanned =
    [...plannedTriplegsForMap].reverse().find((shipment) => {
      const status = shipment?.active_status_ng?.toLowerCase();
      return status === "active" || status === "arrived";
    })?.id ?? null;

  return (
    <DetailWithMap
      mapChildren={
        <RoutingMap
          shipments={hasDealerView ? plannedTriplegsForMap : shipmentsForMap}
          showBreadCrumbs={true}
          showStopSequence={false}
          showHeatmap={false}
          isMultileg={
            hasDealerView
              ? plannedTriplegsForMap.length > 0
              : shipmentsForMap.length > 0
          }
          activeShipment={
            hasDealerView ? activeShipmentIdForPlanned : activeShipmentId
          }
          hideMads={false}
          allowCaptureScreenshot={false}
        />
      }
      detailsChildren={content}
    />
  );
};

PartViewDetails.propTypes = {
  trackingNumber: PropTypes.string,
  trackingNumberUUID: PropTypes.string,
  isLoading: PropTypes.bool,
  packageDetails: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      Watched: PropTypes.bool,
      TrackingNumber: PropTypes.string,
      TrailerEquipmentNumber: PropTypes.string,
      lastPositionUpdate: PropTypes.shape({
        positionType: PropTypes.string,
        source: PropTypes.string,
      }),
      Type: PropTypes.string,
      LifecycleState: PropTypes.string,
      Weight: PropTypes.number,
      UnitOfMeasure: PropTypes.string,
      ExceptionList: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          reasonCode: PropTypes.string,
          comment: PropTypes.string,
          datetime: PropTypes.string,
          eventDatetime: PropTypes.string,
          longitude: PropTypes.number,
          latitude: PropTypes.number,
          city: PropTypes.string,
          state: PropTypes.string,
          resolvedDateTime: PropTypes.string,
        }),
      ),
      ActiveExceptionList: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          reasonCode: PropTypes.string,
          comment: PropTypes.string,
          datetime: PropTypes.string,
          eventDatetime: PropTypes.string,
          longitude: PropTypes.number,
          latitude: PropTypes.number,
          city: PropTypes.string,
          state: PropTypes.string,
          categoryCode: PropTypes.string,
          resolvedDateTime: PropTypes.string,
        }),
      ),
      OriginDetail: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        country: PropTypes.string,
        actualArrival: PropTypes.string,
        actualPickup: PropTypes.string,
        scheduledArrivalWindow: PropTypes.array,
      }),
      DestinationDetail: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zipCode: PropTypes.string,
        country: PropTypes.string,
        actualArrival: PropTypes.string,
        actualPickup: PropTypes.string,
        scheduledArrivalWindow: PropTypes.array,
        eta: PropTypes.string,
      }),
      DestinationEta: PropTypes.string,
      Triplegs: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string, // PLANNED or ACTUAL
          creatorShipmentId: PropTypes.string,
          carrierInformation: PropTypes.shape({
            carrierScac: PropTypes.string,
          }),
          origin: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
            address: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            country: PropTypes.string,
            lad: PropTypes.object,
            scheduledPickupWindow: PropTypes.arrayOf(PropTypes.string),
          }),
          destination: PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string,
            address: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
            country: PropTypes.string,
            lad: PropTypes.object,
            scheduledDeliveryWindow: PropTypes.arrayOf(PropTypes.string),
          }),
          mode: PropTypes.string,
        }),
      ),
      updates: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          datetime: PropTypes.string,
          eventDatetime: PropTypes.string,
          code: PropTypes.string,
          locationName: PropTypes.string,
          locationCode: PropTypes.string,
          latitude: PropTypes.number,
          longitude: PropTypes.number,
          comments: PropTypes.string,
          updateDescription: PropTypes.string,
          eventType: PropTypes.string,
        }),
      ),
      ProNumber: PropTypes.string,
      OrderPriority: PropTypes.string,
      OrderPriorityName: PropTypes.string,
      CommodityCode: PropTypes.string,
      BillOfLading: PropTypes.string,
      CustomerNumber: PropTypes.string,
      references: PropTypes.array,
      OwnerSolutionId: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    isLoadingError: PropTypes.bool,
    status: PropTypes.number,
  }),
  fetchPackageDetails: PropTypes.func.isRequired,
  setWatchPackage: PropTypes.func,
  isSetWatchPackageLoading: PropTypes.bool,
  orders: PropTypes.array,
  fetchParts: PropTypes.func.isRequired,
  parts: PropTypes.arrayOf(
    PropTypes.shape({
      partNumber: PropTypes.string,
      name: PropTypes.string,
      plannedQuantity: PropTypes.number,
      actualQuantity: PropTypes.number,
    }),
  ),
  fetchReferences: PropTypes.func.isRequired,
  references: PropTypes.array,
  meta: PropTypes.object,
  partsIsLoading: PropTypes.bool,
  isShipmentsLoaded: PropTypes.bool,
  fetchShipmentsForTriplegs: PropTypes.func.isRequired,
  clearAllTriplegDetails: PropTypes.func,
  clearPackageDetails: PropTypes.func,
  shipmentsForTriplegs: PropTypes.object,
  coordinates: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      db_time: PropTypes.string,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      city: PropTypes.string,
      state: PropTypes.string,
    }),
  ),
  // Comments
  fetchComments: PropTypes.func.isRequired,
  isFetchingComments: PropTypes.bool.isRequired,
  comments: PropTypes.shape({
    currentPage: PropTypes.number,
    data: PropTypes.array,
    totalCount: PropTypes.number,
    totalCountRead: PropTypes.number,
    totalCountUnread: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  addComment: PropTypes.func.isRequired,
  markCommentsRead: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  cancelUpdateComment: PropTypes.func.isRequired,
  cancelAddComment: PropTypes.func.isRequired,
  fetchNotification: PropTypes.func.isRequired,
  // Map
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
  currentMapCoordinate: PropTypes.shape({
    coordinateType: PropTypes.string,
    data: PropTypes.object,
    floor: PropTypes.string,
    ind: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    radius: PropTypes.string,
    time: PropTypes.string,
  }),
  // Subscription
  fetchSubscription: PropTypes.func,
  subscriptionRequestError: PropTypes.bool,
  subscription: PropTypes.shape({
    email: PropTypes.string,
    enable_email: PropTypes.bool,
    enable_platform: PropTypes.bool,
    enable_sms: PropTypes.bool,
    id: PropTypes.number,
    mobile_number: PropTypes.string,
    owner_id: PropTypes.number,
    reference_id: PropTypes.string,
    source_service: PropTypes.string,
    timezone: PropTypes.string,
    type: PropTypes.string,
  }),
  isSubscriptionLoading: PropTypes.bool,
  updateSubscription: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
  unsubscribe: PropTypes.func.isRequired,
  isSubscriptionUpdating: PropTypes.bool,
  subscriptionUpdateSuccess: PropTypes.bool,
  subscriptionUpdateError: PropTypes.bool,
  clearAllCoordinates: PropTypes.func.isRequired,
  activeOrganization: PropTypes.object,
  shipmentModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
  // Shipment comments
  shipmentComments: PropTypes.object,
  isFetchingShipmentComments: PropTypes.bool,
  isBatchShipmentCommentInProgress: PropTypes.bool,
  isBatchShipmentCommentSuccessful: PropTypes.bool,
  isBatchShipmentCommentFailed: PropTypes.bool,
  fetchShipmentComments: PropTypes.func,
  addShipmentComment: PropTypes.func,
  cancelAddShipmentComment: PropTypes.func,
  updateShipmentComment: PropTypes.func,
  cancelUpdateShipmentComment: PropTypes.func,
  markShipmentCommentsRead: PropTypes.func,
  addBatchShipmentComments: PropTypes.func,
  clearBatchShipmentComments: PropTypes.func,
  pageConfig: PropTypes.shape({
    tabs: PropTypes.shape({
      packageDetails: PropTypes.shape({
        labels: PropTypes.shape({
          BillOfLading: PropTypes.string,
          CommodityCode: PropTypes.string,
          OrderPriority: PropTypes.string,
          ProNumber: PropTypes.string,
          TrackingNumber: PropTypes.string,
          CustomerNumber: PropTypes.string,
        }),
      }),
      order: PropTypes.shape({
        isVisible: PropTypes.bool,
      }),
    }),
    isDealerView: PropTypes.bool,
  }),
  // Delivered package
  setPackageDeliveryEvent: PropTypes.func,
  resetSetPackageDeliveryEvent: PropTypes.func,
  isPackageDeliveryEventLoading: PropTypes.bool,
  packageDeliveryEventRequestError: PropTypes.bool,
  packageDeliveryEventRequestSuccess: PropTypes.bool,
  // Error redirects
  redirectTo404: PropTypes.func,
};
