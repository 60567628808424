import { TimeOnSiteDuration } from "api/consts";
import moment from "moment";
import { QUERY_KEY_MAPPING } from "pages/inventoryview/details/search/InventoryView.Details.Search.Options";
import { useTranslation } from "react-i18next";
import Colors from "styles/colors";

export const dataTypes = {
  NONSHIPPABLE: "nonShippable",
  SHIPPABLE: "shippable",
  FORECASTED: "forecasted",
  REPORTED: "reported",
  TODAY: "today",
  CAPACITY: "capacity",
  PROJECTED_TREND: "projectedTrend",
};

export const useInventoryChartBars = (
  solutionId,
  setSearchFilter,
  resetSearchAndFilters,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.SHIPPABLE,
      legendLabel: t("inventory-view:Shippable"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      dy: 20,
      onClick: (datum) => {
        if (datum.isTodaysDate) {
          resetSearchAndFilters();
          setSearchFilter(queryKey, "true");
          setSearchFilter(
            QUERY_KEY_MAPPING.TIME_ON_SITE,
            TimeOnSiteDuration.ALL,
          );
          redirectToDetailsView(solutionId, datum.locationId);
        }
        return null;
      },
    },
    {
      y: dataTypes.NONSHIPPABLE,
      legendLabel: t("inventory-view:Non-shippable"),
      color: Colors.graphs.CHART_GRAY,
      textColor: Colors.text.DARK_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        if (datum.isTodaysDate) {
          resetSearchAndFilters();
          setSearchFilter(queryKey, "false");
          setSearchFilter(
            QUERY_KEY_MAPPING.TIME_ON_SITE,
            TimeOnSiteDuration.ALL,
          );
          redirectToDetailsView(solutionId, datum.locationId);
        }
        return null;
      },
    },
    {
      y: dataTypes.TODAY,
      legendLabel: t("inventory-view:Shippable (Today)"),
      color: Colors.graphs.CHART_CYAN_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      onClick: null,
    },
    {
      y: dataTypes.FORECASTED,
      legendLabel: t("inventory-view:Forecasted (Today)"),
      color: Colors.graphs.FORECASTED_GRAY,
      textColor: Colors.text.DARKER_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        const queryKey = QUERY_KEY_MAPPING.FORECASTED_ARRIVAL;
        const includeUptoLastHour = moment(datum.name).add(1, "days");
        resetSearchAndFilters();
        setSearchFilter(queryKey, {
          dateType: [],
          from: moment(datum.name).toISOString(),
          to: moment(includeUptoLastHour).toISOString(),
        });
        redirectToDetailsView(solutionId, datum.locationId);
      },
    },
    {
      y: dataTypes.CAPACITY,
      legendLabel: t("inventory-view:Capacity"),
      color: Colors.graphs.CHART_LINE,
      textColor: Colors.graphs.CHART_LINE,
      symbolType: "minus",
      onClick: null,
    },
  ];
};

export const usePipelineChartBars = (
  solutionId,
  setSearchFilter,
  resetSearchAndFilters,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.SHIPPABLE,
      legendLabel: t("inventory-view:Shippable"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      dy: 20,
      onClick: (datum) => {
        resetSearchAndFilters();
        setSearchFilter("isShippable", "true");
        // set for current location type
        setSearchFilter(
          QUERY_KEY_MAPPING.TIME_ON_SITE,
          TimeOnSiteDuration.NONE,
        );
        setSearchFilter(QUERY_KEY_MAPPING.CURRENT_LOCATION_TYPE, [datum.value]);
        redirectToDetailsView(solutionId, datum.locationId);
      },
    },
    {
      y: dataTypes.NONSHIPPABLE,
      legendLabel: t("inventory-view:Non-shippable"),
      color: Colors.graphs.CHART_GRAY,
      textColor: Colors.text.DARK_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        resetSearchAndFilters();
        setSearchFilter("isShippable", "false");
        // set for current location type
        setSearchFilter(
          QUERY_KEY_MAPPING.TIME_ON_SITE,
          TimeOnSiteDuration.NONE,
        );
        setSearchFilter(QUERY_KEY_MAPPING.CURRENT_LOCATION_TYPE, [datum.value]);
        redirectToDetailsView(solutionId, datum.locationId);
      },
    },
  ];
};

export const useArrivalsChartBars = (
  solutionId,
  setSearchFilter,
  resetSearchAndFilters,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.REPORTED,
      legendLabel: t("inventory-view:Reported"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      dy: 20,
      symbolType: "circle",
      onClick: (datum) => {
        const includeUptoLastHour = moment(datum.name).add(1, "days");
        resetSearchAndFilters();
        setSearchFilter(queryKey, {
          from: moment(datum.name).toISOString(),
          to: moment(includeUptoLastHour).toISOString(),
        });
        redirectToDetailsView(solutionId, datum.locationId);
      },
    },
    {
      y: dataTypes.TODAY,
      legendLabel: t("inventory-view:Today"),
      color: Colors.graphs.CHART_CYAN_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      onClick: null,
    },
    {
      y: dataTypes.FORECASTED,
      legendLabel: t("inventory-view:Forecasted"),
      color: Colors.graphs.CHART_GRAY,
      textColor: Colors.text.DARKER_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        const queryKey = QUERY_KEY_MAPPING.FORECASTED_ARRIVAL;
        const includeUptoLastHour = moment(datum.name).add(1, "days");
        resetSearchAndFilters();
        setSearchFilter(queryKey, {
          dateType: [],
          from: moment(datum.name).toISOString(),
          to: moment(includeUptoLastHour).toISOString(),
        });
        redirectToDetailsView(solutionId, datum.locationId);
      },
    },
  ];
};

export const useDeparturesChartAreas = (
  solutionId,
  setSearchFilter,
  resetSearchAndFilters,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.REPORTED,
      legendLabel: t("inventory-view:Actual"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      dy: 20,
      symbolType: "circle",
      onClick: (datum) => {
        const includeUptoLastHour = moment(datum.x).add(1, "days");
        resetSearchAndFilters();
        setSearchFilter(queryKey, {
          from: moment(datum.x).toISOString(),
          to: moment(includeUptoLastHour).toISOString(),
        });
        redirectToDetailsView(solutionId, datum.locationId);
      },
    },
    {
      y: dataTypes.TODAY,
      legendLabel: t("inventory-view:Target"),
      color: Colors.text.DARK_GRAY,
      textColor: Colors.text.WHITE,
      symbolType: "minus",
      onClick: null,
    },
    {
      y: dataTypes.FORECASTED,
      legendLabel: t("inventory-view:Stretch"),
      color: Colors.graphs.CHART_LINE,
      textColor: Colors.text.DARK_GRAY,
      symbolType: "minus",
      onClick: null,
    },
    {
      y: dataTypes.PROJECTED_TREND,
      legendLabel: t("inventory-view:Projected Trend"),
      color: Colors.inventoryView.ORANGE,
      textColor: Colors.inventoryView.ORANGE,
      symbolType: "minus",
      onClick: null,
    },
  ];
};
