import { connect } from "react-redux";
import { PartnerViewDetails } from "./PartnerView.Details.page";

import PartnerViewEntityDetailsState from "../redux/partnerview/PartnerViewEntityDetailsState";
import PartnerViewTripLegState from "../redux/partnerview/PartnerViewTripLegState";
import PartnerViewSubscriptionState from "../redux/partnerview/PartnerViewSubscriptionState";
import MapState from "modules/map/MapState";
import ShipmentsStatusState from "pages/shipments/redux/ShipmentsState";
import {
  getSolutionId,
  getActiveOrganization,
} from "modules/organizations/OrganizationsState";
import { getAuthorization } from "modules/auth/AuthorizationSelectors";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import PartnerViewEntityHistoricalState from "../redux/partnerview/PartnerViewEntityHistoricalState";
import NotificationsState from "modules/notifications/NotificationsState";

function mapStateToProps(state) {
  const entityId = state.location.payload.entity_id;

  return {
    solutionId: getSolutionId(state),
    entityId,
    details: PartnerViewEntityDetailsState.selectors.getEntityDetails(state),
    coords:
      PartnerViewEntityDetailsState.selectors.getEntityPositionUpdates(state),
    connectedCarCoords:
      PartnerViewEntityDetailsState.selectors.getEntityConnectedCarPositionUpdates(
        state,
      ),
    plannedLegs: PartnerViewTripLegState.selectors.getPlannedTripLeg(state),
    actualLegs: PartnerViewTripLegState.selectors.getActualTripLeg(state),
    combinedLegs: PartnerViewTripLegState.selectors.getCombinedTripLegs(state),
    parentShipment: ShipmentsStatusState.selectors.getShipmentDetails(state),
    childShipments:
      ShipmentsStatusState.selectors.getChildShipmentDetails(state),
    shipmentsLoaded: ShipmentsStatusState.selectors.getIsLoaded(state),
    exceptions:
      PartnerViewEntityDetailsState.selectors.getEntityExceptions(state),
    combinedExceptions:
      PartnerViewEntityDetailsState.selectors.getCombinedExceptions(state),
    currentLocation:
      PartnerViewEntityDetailsState.selectors.getEntityCurrentLocation(state),
    authorization: getAuthorization(state),
    holds: PartnerViewEntityDetailsState.selectors.getEntityHolds(state),
    events: PartnerViewEntityDetailsState.selectors.getEntityEvents(state),
    media: PartnerViewEntityDetailsState.selectors.getEntityMedia(state),
    // Comments
    isFetchingComments:
      PartnerViewEntityDetailsState.selectors.getIsFetchingComments(state),
    comments: PartnerViewEntityDetailsState.selectors.getComments(state),
    shippers: PartnerViewEntityDetailsState.selectors.getEntityShippers(state),
    // Subscription
    vinSubscription:
      PartnerViewSubscriptionState.selectors.getSubscription(state),
    isVinSubscriptionLoading:
      PartnerViewSubscriptionState.selectors.getIsSubscriptionLoading(state),
    vinSubscriptionRequestError:
      PartnerViewSubscriptionState.selectors.getSubscriptionRequestError(state),
    isVinSubscriptionUpdating:
      PartnerViewSubscriptionState.selectors.getIsSubscriptionUpdating(state),
    vinSubscriptionUpdateSuccess:
      PartnerViewSubscriptionState.selectors.getSubscriptionUpdateSuccess(
        state,
      ),
    vinSubscriptionUpdateError:
      PartnerViewSubscriptionState.selectors.getSubscriptionUpdateError(state),
    // Share
    isSharingVin:
      PartnerViewEntityDetailsState.selectors.getIsSharingVin(state),
    isShareVinSuccessful:
      PartnerViewEntityDetailsState.selectors.getIsShareVinSuccessful(state),
    isShareVinFailed:
      PartnerViewEntityDetailsState.selectors.getIsShareVinFailed(state),
    // Map Coordinates
    currentMapCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.CURRENT_LOCATION,
    )(state),
    selectedMapCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.SELECTED_COORDINATE,
    )(state),
    selectedConnectedCarCoordinate: MapState.selectors.getMapCoordinate(
      MapCoordinateType.CURRENT_CONNECTED_CAR,
    )(state),
    // Historical Data
    getHistoryByQualifier:
      PartnerViewEntityHistoricalState.selectors.getHistoryByQualifier,
    // Other
    activeOrganization: getActiveOrganization(state),
  };
}

// Dispatch to props

const {
  fetchEntityExceptions,
  fetchEntityHolds,
  fetchEntityDetails,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  fetchComments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  fetchEntityShippers,
  shareVin,
  clearShareVin,
} = PartnerViewEntityDetailsState.actionCreators;
const { fetchPlannedTripLeg, fetchActualTripLeg } =
  PartnerViewTripLegState.actionCreators;
const {
  fetchSubscription: fetchVinSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
} = PartnerViewSubscriptionState.actionCreators;
const { fetchHistoricalData } = PartnerViewEntityHistoricalState.actionCreators;
const { fetchLegShipmentDetails, clearLegShipmentDetails } =
  ShipmentsStatusState.actionCreators;
const { fetchNotification } = NotificationsState.actionCreators;
const { addCoordinate, clearCoordinatesByType } = MapState.actionCreators;

const actionCreators = {
  fetchEntityExceptions,
  fetchEntityDetails,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityHolds,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  fetchPlannedTripLeg,
  fetchActualTripLeg,
  fetchLegShipmentDetails,
  clearLegShipmentDetails,
  // Map Coordinates
  addCoordinate,
  clearCoordinatesByType,
  // Historical Data
  fetchHistoricalData,
  // Comments
  fetchComments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  fetchNotification,
  fetchEntityShippers,
  // Subscription
  fetchVinSubscription,
  updateSubscription,
  subscribe,
  unsubscribe,
  // Share
  shareVin,
  clearShareVin,
};

export default connect(mapStateToProps, actionCreators)(PartnerViewDetails);
