/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";

import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryStack,
  VictoryVoronoiContainer,
} from "victory";

import { FontSize, Text } from "components/atoms/Text.atom";
import {
  formatXAxisName,
  getLabels,
  getStyle,
} from "pages/inventoryview/utils/chart.utils";

import {
  strokeLinecap,
  strokeLinejoin,
  strokeWidth,
} from "styles/graph-styles";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";

export const VerticalStackedBarChart = ({
  data = [],
  bars = [],
  axisStyle,
  axisTicksLabelStyle,
  crossAxisStyle,
  onClickLegend,
  xAxisTickFormat,
  width,
  showTotals = false,
  totalSum,
  threshold,
  showAngledXAxisLabel,
}) => {
  const { t } = useTranslation("components");

  const containerRef = useRef();

  let legendLabels = bars.map((bar) => ({
    name: bar.legendLabel,
    y: bar.y,
    symbol: { type: bar.symbolType ?? "square" },
  }));

  const colorScale = bars.map((bar) => bar.color);

  const summedData = data.map((datum) => ({
    ...datum,
    summed: bars
      .map((bar) => datum[bar.y])
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0),
  }));

  // delegate to onClickLegend prop when legend is clicked
  const onLegendClickHandler = () => [
    {
      target: "data",
      mutation: (props) =>
        onClickLegend ? onClickLegend(props.datum.y) : props,
      propTypes: {
        datum: PropTypes.object,
      },
    },
  ];

  const maxLimit =
    threshold && threshold > 0
      ? threshold > totalSum
        ? threshold
        : totalSum
      : totalSum > 0
      ? totalSum
      : 100;

  return (
    <Fragment>
      {showTotals ? (
        <div style={{ lineHeight: "1em" }}>
          <Text
            fontSize={FontSize.size12}
            color={Colors.text.TOTALS_LABEL_GRAY}
            bold
            block
          >
            {t("components:Total")}
          </Text>
          <Text
            fontSize={FontSize.size12}
            color={Colors.text.GRAPH_DARKER_GRAY}
            bold
          >
            <LocalizedNumber value={totalSum} />
          </Text>
        </div>
      ) : null}
      <div useRef={containerRef}>
        <VictoryChart
          domain={{ y: [0, maxLimit] }}
          domainPadding={{ x: 0, y: 30 }}
          padding={{ top: 20, bottom: 30, left: 30, right: 50 }}
          width={width}
          height={400}
          containerComponent={<VictoryVoronoiContainer radius={50} />}
        >
          <VictoryAxis
            style={axisStyle}
            tickLabelComponent={
              <VictoryLabel
                textAnchor="middle"
                style={[{ fontSize: 12, ...axisTicksLabelStyle }]}
                angle={showAngledXAxisLabel ? -15 : 0}
              />
            }
          />
          <VictoryAxis
            style={crossAxisStyle}
            dependentAxis
            tickFormat={xAxisTickFormat}
          />

          <VictoryStack colorScale={colorScale}>
            {bars.map((bar) => {
              return (
                <VictoryBar
                  barRatio={0.7}
                  key={bar.y}
                  data={summedData}
                  x={formatXAxisName}
                  y={bar.y}
                  domain={{ x: [0, 7] }}
                  style={getStyle(bar)}
                  labels={getLabels(bar)}
                  labelComponent={
                    <VictoryLabel dy={bar.dy} data={bar.y} lineHeight={[1]} />
                  }
                  events={[
                    {
                      target: "data",
                      eventHandlers: {
                        onClick: () => ({
                          target: "data",
                          mutation: (props) => {
                            if (bar.onClick) {
                              bar.onClick(props.datum);
                            }
                          },
                        }),
                      },
                    },
                  ]}
                />
              );
            })}
          </VictoryStack>
          {threshold > 0 ? (
            <VictoryAxis
              axisValue={threshold}
              orientation="top"
              style={{
                tickLabels: { fill: "none" },
                axisLabel: { fontSize: FontSize.size12 },
                axis: {
                  stroke: Colors.text.CHART_GRAY_LINE,
                  strokeLinecap,
                  strokeLinejoin,
                  strokeWidth,
                },
              }}
              label={threshold}
              axisLabelComponent={
                <VictoryLabel
                  dy={30}
                  dx={width / 2}
                  style={{
                    fontWeight: 600,
                    fill: Colors.text.CHART_GRAY_LINE,
                  }}
                />
              }
            />
          ) : null}
        </VictoryChart>
        <VictoryLegend
          x={(width / legendLabels.length) * 0.65}
          y={0}
          height={25}
          width={width}
          style={{ labels: { fontFamily: "inherit" } }}
          colorScale={colorScale}
          data={legendLabels}
          orientation="horizontal"
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: onLegendClickHandler,
              },
            },
          ]}
        />
      </div>
    </Fragment>
  );
};

VerticalStackedBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  /** Defines the accessor for the x value in data */
  // xAccessor should match to a key in an element in data
  // All x values should refer to the same part of data
  // This forces developers to make sure thats true
  xAccessor: PropTypes.string,
  /** Bar definitions for each set in the stack */
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      /** Accessor for the range */
      y: PropTypes.string,
      /** Function thats passed an object with `datum`. Should return a string*/
      // similar to the labels prop on VictoryBar
      getLabelForData: PropTypes.func,
      /** The label for this bar in the legend */
      legendLabel: PropTypes.string,
      /** Defines whether to show/hide the tooltip for the bar chart */
      showTooltip: PropTypes.bool,
      /** The color for this bar and the legend */
      color: PropTypes.string,
      /** The click handler for this bar */
      onClick: PropTypes.func,
    }),
  ),
  barStyle: PropTypes.object,
  axisStyle: PropTypes.object,
  crossAxisStyle: PropTypes.object,
  /**
   * Event handler that fires when clicking any element in the legend
   * It passes the y attribute on the specific datum to the caller which
   * can be used to determine which element in the legend was clicked.
   */
  onClickLegend: PropTypes.func,
  xAxisTickFormat: PropTypes.array,
  width: PropTypes.number,
  axisTicksLabelStyle: PropTypes.object,
  showTotals: PropTypes.bool,
  threshold: PropTypes.number,
  totalSum: PropTypes.number,
  showAngledXAxisLabel: PropTypes.bool,
};
