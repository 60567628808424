/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { Button } from "components/atoms/Button.atom";
import { Icon } from "components/atoms/Icon.atom";
import { useTranslation } from "react-i18next";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { Text } from "components/atoms/Text.atom";

export const PaginationControls = ({
  page,
  totalPages,
  onPrevClick,
  onNextClick,
}) => {
  // TODO: need to check about changing namespace
  const { t } = useTranslation("inv-dashboard");

  const currentPageLabel = totalPages === 0 ? 0 : page;
  const totalPagesLabel = totalPages;

  return (
    <div className="d-flex align-items-center justify-content-center mt-4">
      <Button
        data-qa="saved-search-pagination-button-previous"
        size="sm"
        className="me-2"
        onClick={onPrevClick}
        disabled={currentPageLabel <= 1}
      >
        <Icon src={faChevronLeft} />
      </Button>
      <Text data-qa="saved-search-pagination-text">
        {t("inv-dashboard:Page")} {currentPageLabel} / {totalPagesLabel}
      </Text>
      <Button
        data-qa="saved-search-pagination-button-next"
        size="sm"
        className="ms-2"
        onClick={onNextClick}
        disabled={currentPageLabel === totalPagesLabel}
      >
        <Icon src={faChevronRight} />
      </Button>
    </div>
  );
};

PaginationControls.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};
