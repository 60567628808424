/** @jsxImportSource @emotion/react */
import { Fragment, useState, useEffect, useCallback, useMemo } from "react";
import Loader from "react-loader";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { useGetLatest } from "components/hooks/useGetLatest";
import { useEntityTripSummary } from "shared/hooks/useEntityTripSummary";
import { DetailWithMap } from "components/templates/DetailWithMap.template";
import RoutingMap from "modules/map/components/RoutingMap";
import {
  RoutingMapContext,
  RoutingMapContextProvider,
} from "modules/map/components/RoutingMapContext";
import { MapCoordinateType } from "modules/map/components/map-coordinate-definition";
import { Features } from "modules/auth/Authorization";
import { OrganizationType } from "shared/constants/organization.const";
import { useDiagnosticDataFormatter } from "shared/utils/entity.utils";
import { getLegShipments } from "modules/shipment-detail/ShipmentUtils";
import { isShipper } from "shared/utils/organizations.utils";
import { HeatMapButton } from "components/atoms/HeatMapButton.atom";
import {
  getTransitDuration,
  getCurrentPositionDetails,
} from "shared/utils/entity.utils";
import { getVinPopUpDetails } from "shared/utils/vin.utils";
import {
  endToEndTab,
  legTabs,
} from "components/multimodal-components/tabComponents";
import {
  Tabs,
  TabsClassNames,
  NO_PAGINATION,
} from "components/molecules/Tabs.molecule";
import {
  tabsCss,
  tabPanelStyle,
  tabListStyle,
} from "components/multimodal-components/tabStyles";
import { isExtraLarge } from "components/responsive";
import ShipmentContentPanel from "modules/shipment-detail/ShipmentContentPanel";
import { VinDetailsPanelGroup } from "pages/components/organisms/VinDetailsPanelGroup";
import {
  ArrivalAndDeparturePanelGroup,
  transformLocation,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
// Components for detail tabs
import { TabPanelPlaceholder } from "components/no-data-placeholders";
import {
  BaseTable,
  Themes,
} from "components/organisms/base-table/BaseTable.organism";
import { TripSummary } from "shared/components/organisms/TripSummary.organism";
import { CoordinatesTable } from "components-old/CoordinatesTable";
import { ConnectedCarCoordinatesTable } from "shared/components/organisms/ConnectedCarCoordinatesTable.organism";
import { useUpdatesTable } from "shared/hooks/columns/useUpdatesTable.columns";
import { useActiveExceptionsTable } from "shared/hooks/columns/useActiveExceptionsTable";

import { useDiagnosticsDataTable } from "shared/hooks/columns/useDiagnosticsDataTable";
import { useOrderDetailsTable } from "shared/hooks/columns/useOrderDetailsTable";
import { useVinDetailsTable } from "shared/hooks/columns/useVinDetailsTable";
import {
  useSetSubTitle,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import { batchCsvVinsExample } from "components/search-bar/batch-comment-csv-data";
import { MediaQueries, isExtraSmall } from "components/responsive";
import { getVinTabs } from "../utils/FinishedVehicleDetailsTabDefinitions";

const MAX_CONNECTED_CAR_COORDINATES = 1000;

const detailTabPanelStyle = {
  boxShadow: "0px 15px 12px 0px rgba(0,0,0,0.05)",
};

const DEFAULT_TAB_PAGE_SIZE = 20;

export const FinishedVehicleDetails = ({
  solutionId,
  entityId,
  details,
  detailsError,
  selectedDealerOrgId,
  fetchEntityDetails,
  fetchEntityExceptions,
  fetchEntityHolds,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  fetchPlannedTripLeg,
  fetchActualTripLeg,
  fetchVinSubscription,
  fetchEntityMedia,
  fetchLegShipmentDetails,
  clearLegShipmentDetails,
  clearEntityMedia,
  setWatchEntity,
  shareVin,
  combinedLegs,
  shipmentModes,
  childShipments,
  shipmentsLoaded,
  plannedLegs,
  actualLegs,
  connectedCarCoords,
  combinedExceptions,
  currentLocation,
  authorization,
  // Comments
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  fetchShipmentComments,
  isFetchingShipmentComments,
  shipmentComments,
  addShipmentComment,
  cancelAddShipmentComment,
  updateShipmentComment,
  cancelUpdateShipmentComment,
  markShipmentCommentsRead,
  addBatchComments,
  clearBatchComments,
  isBatchCommentInProgress,
  isBatchCommentSuccessful,
  isBatchCommentFailed,
  fetchNotification,
  shareableCarrierFvIds,
  // Shipment Comments
  addBatchShipmentComments,
  clearBatchShipmentComments,
  isBatchShipmentCommentInProgress,
  isBatchShipmentCommentSuccessful,
  isBatchShipmentCommentFailed,
  activeOrganization,
  events = null,
  exceptions = null,
  holds = null,
  vinSubscription,
  isVinSubscriptionLoading,
  vinSubscriptionRequestError,
  updateSubscription,
  subscribe,
  unsubscribe,
  isVinSubscriptionUpdating,
  vinSubscriptionUpdateSuccess,
  vinSubscriptionUpdateError,
  media = null,
  clearShareVin,
  isSharingVin,
  isShareVinSuccessful,
  isShareVinFailed,
  // Map Coordinates
  addCoordinate,
  clearCoordinatesByType,
  clearAllCoordinates,
  selectedMapCoordinate,
  currentMapCoordinate,
  selectedConnectedCarCoordinate,
  // Historical Data
  getHistoryByQualifier,
  fetchHistoricalData,
  fetchEntityStateHistory,
  getEntityStateChangeTs,
  // Error redirects
  redirectTo404,
}) => {
  const { t } = useTranslation("fv-vin-details");
  const vinDetailsColumns = useVinDetailsTable();

  const orderDetailsColumns = useOrderDetailsTable();

  const showScheduleWindow = authorization.hasFeatures([
    Features.FINISHED_VEHICLE_SCHEDULED_WINDOW,
  ]);

  const {
    columns: diagnosticsDetailsColumns,
    SubComponent: DiagnosticsHistoricalTableSubComponent,
  } = useDiagnosticsDataTable(t, true);

  const { getDiagonsticLabel, getColorByState } = useDiagnosticDataFormatter();
  useSetTitleOnMount(t("fv-vin-details:VIN Details"));
  useSetSubTitle(details?.id, details);

  useTrackWithMixpanelOnce("Viewed Page: VIN / Details");

  const columns = useActiveExceptionsTable();

  const [isLoading, setIsLoading] = useState(true);
  const [watch, setWatch] = useState(null);
  const [showHeatmap, setShowHeatmap] = useState(false);
  const [selectedLegID, setSelectedLegID] = useState(null);
  const {
    columns: updatesTableColumns,
    SubComponent: UpdatesTableSubComponent,
  } = useUpdatesTable(true, false, true);

  // Redirect when errors happen
  useEffect(() => {
    if (detailsError?.response.status === 404) {
      const errorMessage = t(
        'fv-vin-details:Details for VIN "[[[entityId]]]" could not be found',
        {
          entityId,
        },
      );
      redirectTo404(errorMessage);
    }
  }, [detailsError, redirectTo404, t, entityId]);

  useEffect(() => {
    if (entityId) {
      setIsLoading(true);

      // Non-layout data fetches; do not display spinner while loading
      fetchEntityExceptions(
        solutionId,
        entityId,
        "ACTIVE",
        selectedDealerOrgId,
      );
      fetchEntityHolds(solutionId, entityId);
      // Connected Car tab details - type set to "entity"
      fetchEntityPositionUpdates(
        solutionId,
        entityId,
        selectedDealerOrgId,
        "entity",
        MAX_CONNECTED_CAR_COORDINATES,
      );
      // Populates `props.coords`.
      // This prop isn't used but needs to be fetched for currentLocation data.
      // See `getEntityCurrentLocation` selector.
      fetchEntityPositionUpdates(
        solutionId,
        entityId,
        selectedDealerOrgId,
        "tripleg",
      );
      fetchEntityEvents(solutionId, entityId, selectedDealerOrgId);

      // Data fetches needed to render layout; return Promise to display isLoading spinner
      Promise.all([
        fetchEntityDetails(solutionId, entityId, selectedDealerOrgId),
        fetchPlannedTripLeg(solutionId, entityId, selectedDealerOrgId),
        fetchActualTripLeg(solutionId, entityId, selectedDealerOrgId),
        fetchEntityStateHistory(solutionId, entityId, "Complete"),
      ]).then(() => {
        setIsLoading(false);
      });
    }
  }, [
    entityId,
    solutionId,
    selectedDealerOrgId,
    fetchEntityExceptions,
    fetchEntityHolds,
    fetchEntityPositionUpdates,
    fetchEntityEvents,
    fetchEntityDetails,
    fetchPlannedTripLeg,
    fetchActualTripLeg,
    fetchEntityStateHistory,
  ]);

  // Get existing vin subscription
  useEffect(() => {
    if (_.isFunction(fetchVinSubscription) && !_.isEmpty(details)) {
      fetchVinSubscription(details);
    }
  }, [fetchVinSubscription, details]);

  useEffect(() => {
    clearEntityMedia();

    if (details) {
      if (details?.references) {
        const imageURLRef = _.find(details.references, {
          qualifier: "Decoded-ImageURL",
        });

        if (imageURLRef) {
          fetchEntityMedia(imageURLRef.value);
        }
      }

      // If the details have been updated we need to make sure
      // that our watch checkbox matches the value in the props
      setWatch(details.watch);
    }
  }, [clearEntityMedia, details, fetchEntityMedia]);

  const loaded = !isLoading && shipmentsLoaded && !detailsError;

  // Wrap the value in a "getter" callback so it doesn't cause additional updates to the effect.
  // We can access the object in the effect by calling `getLatestActualLegs()`
  const getLatestActualLegs = useGetLatest(actualLegs);
  // Using the ID in the deps of the effect so it will update when the ID changes.
  const latestActualLegEntityId = getLatestActualLegs()?.id;
  useEffect(() => {
    // H2-3151: Make sure we fetch using legs for this VIN.
    // The ID can be null so making sure we have it before requesting.
    if (!_.isNil(entityId) && entityId === latestActualLegEntityId) {
      fetchLegShipmentDetails(getLatestActualLegs());
    } else {
      // If we don't yet have actual legs, clear the leg shipment details
      clearLegShipmentDetails();
    }
  }, [
    entityId,
    latestActualLegEntityId,
    getLatestActualLegs,
    fetchLegShipmentDetails,
    clearLegShipmentDetails,
  ]);

  const doShare = useCallback(
    (emails, capturedScreenshot, timezone) => {
      return shareVin(
        solutionId,
        entityId,
        emails,
        capturedScreenshot,
        timezone,
        selectedDealerOrgId,
      );
    },
    [solutionId, entityId, selectedDealerOrgId, shareVin],
  );

  const eventHandler = (val, type) => {
    switch (type) {
      case "SELECT_LEG":
        setSelectedLegID(val);
        break;
      default:
        break;
    }
  };

  const endToEndCoords = (shipments) => {
    let coordinatesData = [];

    if (!shipments || !shipments.length) {
      return coordinatesData;
    }

    shipments.forEach((s) => {
      s.current_location.updates.forEach((c, i) => {
        coordinatesData.push(c);
      });
    });

    return coordinatesData;
  };

  const tripSummary = useEntityTripSummary(combinedLegs, events);
  const hasTripPlan = tripSummary.stops.length > 0;

  let hasActualLegs = false;
  let legShipments = [];
  let isMultimodal = false;
  let routeShipments = [];

  /* H1-1263 make sure child shipments are also loaded before attempting to render */
  if (loaded) {
    hasActualLegs =
      actualLegs && actualLegs.tripLegs && actualLegs.tripLegs.length > 0;

    legShipments = getLegShipments(
      childShipments,
      shipmentsLoaded,
      hasTripPlan || hasActualLegs,
    );
    // H1-1546: Added plannedLegs to this condition, once the ticket says that
    // Trip plan should be showed even when actual trip legs do not exist
    isMultimodal = legShipments.length > 0 || plannedLegs.tripLegs.length > 0;

    routeShipments = selectedLegID
      ? [childShipments[selectedLegID]]
      : legShipments;
  }

  // When there are too many connected car coordinates,
  // it takes forever to load the map, so limit it to the top
  // MAX_CONNECTED_CAR_COORDINATES coordinates.
  let limitedConnectedCarCoords = _.slice(
    connectedCarCoords,
    0,
    MAX_CONNECTED_CAR_COORDINATES,
  );

  let hasMoreConnectedCarCoords =
    limitedConnectedCarCoords.length < connectedCarCoords.length;

  const showConnectedCarTab = true;

  const showDiagnosticDataTab =
    authorization.isAuthorized([], [Features.DIAGNOSTIC_DATA]) ||
    authorization.hasOrganizationTypes([OrganizationType.DEALER]);

  const vinTabs = useMemo(
    () =>
      getVinTabs({
        t,
        combinedExceptions,
        currentLocation,
        details,
        connectedCarCoords,
        showDiagnosticDataTab,
        showConnectedCarTab,
        shouldFilterExceptions: true,
        getDiagnosticLabel: getDiagonsticLabel,
        getColorByState,
        hasTripPlan,
      }),
    [
      t,
      combinedExceptions,
      currentLocation,
      details,
      connectedCarCoords,
      showDiagnosticDataTab,
      showConnectedCarTab,
      getDiagonsticLabel,
      getColorByState,
      hasTripPlan,
    ],
  );

  const heatmapCoords = _.flatten(
    routeShipments.map((item) => _.get(item, "current_location.updates")),
  );

  let shipmentsSorted = null;

  if (hasActualLegs) {
    // H1-1451: If there are actual trip plan legs, sort in the exact order returned from the API
    shipmentsSorted = actualLegs.tripLegs.map((actualLeg) => {
      const matchingShipment = _.find(
        legShipments,
        (shipment) => shipment.creator_shipment_id === actualLeg.id,
      );

      return matchingShipment ?? null;
    });
  } else {
    // Sort by the origin's earliest arrival timestamp
    shipmentsSorted = _.orderBy(legShipments, [
      (s) => s.origin_earliest_arrival || "", // Make sure null values appear first
    ]);
  }

  // Child summaries for `legTabs`.
  const childSummaries = shipmentsSorted.map((s) => {
    if (s) {
      return {
        shipment_id: s.id,
        active: _.toLower(s.active_status_ng) === "active",
      };
    }
    return {};
  });

  // Child shipment details for `legTabs`
  // This is a map from shipment ID to details object.
  let childDetails = {};
  shipmentsSorted.forEach((s) => {
    if (s) {
      childDetails[s.id] = { ...s };
    }
  });

  // Remove nulls (so errors don't throw in the render)
  shipmentsSorted = shipmentsSorted.filter((shipment) => !_.isNil(shipment));

  const hasTransitTimerFeature = authorization.isAuthorized(
    [],
    [Features.TRANSIT_TIMER],
  );

  const origin =
    loaded && !_.isNil(details?.ultimateOrigin)
      ? transformLocation(details.ultimateOrigin)
      : null;
  const destination =
    loaded && !_.isNil(details?.ultimateDestination)
      ? transformLocation(details.ultimateDestination)
      : null;

  const currentPositionDetails = loaded
    ? getCurrentPositionDetails(details, t)
    : null;
  const transitDuration =
    loaded && hasTransitTimerFeature
      ? getTransitDuration(tripSummary.updates)
      : null;

  let eta = null;
  let isFvEta = false;
  // If the VIN is not delivered, show the ETA.
  if (loaded && details?.lifeCycleState !== "Delivered") {
    // H2-2413: `lastEntityProgressUpdate.destinationEta` is the source of the ETA for the E2E tab (VinView too).
    // The API will handle which ETA to show (FV, HERE, Smart, etc) in this field.
    eta = details?.lastEntityProgressUpdate?.destinationEta;

    // If the lastEntityProgressUpdate.progressUpdateReference has a qualifier of entity_status_update,
    // it's an FV-generated ETA. Otherwise, it's a HERE-generated ETA.
    isFvEta = _.find(
      details?.lastEntityProgressUpdate?.progressUpdateReference,
      ["qualifier", "entity_status_update"],
    ) // Will return undefined if entity_status_update doesn't exist, otherwise returns an object
      ? true
      : false;
  }

  let tripPlanCompleteTs = loaded ? details?.tripPlanCompleteTs : null;

  // For tabs...
  const onTabsSelect = (index, lastIndex, event) => {
    // Connected Car
    // if we leave from the connected car tab
    if (event.target.textContent !== t("fv-vin-details:Connected Car")) {
      clearCoordinatesByType([MapCoordinateType.CURRENT_CONNECTED_CAR]);
    }

    // Coordinates
    // if we leave from the coordinates tab
    if (event.target.textContent !== t("fv-vin-details:Coordinates")) {
      clearCoordinatesByType([MapCoordinateType.SELECTED_COORDINATE]);
    }
  };

  const endToEndTabCoords = endToEndCoords(shipmentsSorted);
  const popUpDetails = getVinPopUpDetails(details);
  const completeStateChangeTs = getEntityStateChangeTs("Complete");

  const endToEndPanelContent = loaded ? (
    <div css={{ overflow: "auto" }}>
      {/* Details Group */}
      <VinDetailsPanelGroup
        entityId={details?.id}
        details={details}
        media={media}
        holds={holds}
        exceptions={exceptions}
        addCoordinate={addCoordinate}
        clearCoordinatesByType={clearCoordinatesByType}
        currentMapCoordinate={currentMapCoordinate}
        hasTransitTimerFeature={hasTransitTimerFeature}
        transitDuration={transitDuration}
        currentPositionDetails={currentPositionDetails}
        canDisplaySource={true}
        showShareAction={true}
        showAlertAction={true}
        shouldBuildExceptionsBySeverity={false}
        showOrderNumber={true}
        vinSubscription={vinSubscription}
        isVinSubscriptionLoading={isVinSubscriptionLoading}
        vinSubscriptionRequestError={vinSubscriptionRequestError}
        subscribe={subscribe}
        updateSubscription={updateSubscription}
        unsubscribe={unsubscribe}
        isVinSubscriptionUpdating={isVinSubscriptionUpdating}
        vinSubscriptionUpdateSuccess={vinSubscriptionUpdateSuccess}
        vinSubscriptionUpdateError={vinSubscriptionUpdateError}
        watch={watch}
        toggleWatchEntity={() => {
          // Toggle our internal state, so the UI remains responsive
          // and then send the update.  If the state is not properly
          // changed on the backend, we will revert when we receive the
          // response from the API
          const newWatchState = !watch;
          setWatch(newWatchState);
          setWatchEntity(solutionId, entityId, newWatchState);
        }}
        showComments={isShipper(activeOrganization)}
        watchToggleTooltipText={
          isShipper(activeOrganization)
            ? t(
                "fv-vin-details:Add this VIN to your Watched VINs list on the Finished Vehicle Dashboard and receive notifications for new comments",
              )
            : t(
                "fv-vin-details:Add this VIN to your Watched VINs list on the Finished Vehicle Dashboard",
              )
        }
        clearShareVin={clearShareVin}
        shareVin={doShare}
        isSharingVin={isSharingVin}
        isShareVinSuccessful={isShareVinSuccessful}
        isShareVinFailed={isShareVinFailed}
        style={{ margin: "1em" }}
      />

      {/* Arrivals and Departures Group */}
      {hasTripPlan ? (
        <ArrivalAndDeparturePanelGroup
          origin={origin}
          destination={destination}
          hideScheduled={!showScheduleWindow}
          eta={eta}
          etaMessage={
            eta?.toLowerCase() === "tbd"
              ? t(
                  "fv-vin-details:ETA will be recalculated once the VIN is available to ship",
                )
              : null
          }
          isFvEta={isFvEta}
          style={{ margin: "1em" }}
          tripPlanCompleteTs={tripPlanCompleteTs}
          completeStateChangeTs={completeStateChangeTs}
          manualEtaRangeStart={details?.manualEtaRangeStart}
          manualEtaRangeEnd={details?.manualEtaRangeEnd}
          visibleFields={{
            destinationActualDeparture: !Boolean(completeStateChangeTs),
            destinationCompletionDate: Boolean(completeStateChangeTs),
          }}
        />
      ) : null}

      {isShipper(activeOrganization) ? (
        <CommentFeed
          commentShareableWithOrgId={details?.dealerOrgId}
          commentShareableWithCarrierFvIds={
            // List of unique carrier FV IDs from trip plan.
            shareableCarrierFvIds
          }
          fetchComments={(pageNumber, pageSize) => {
            return fetchComments(solutionId, entityId, pageNumber, pageSize);
          }}
          isFetchingComments={isFetchingComments}
          comments={comments}
          addComment={(data) => {
            return addComment(solutionId, entityId, data);
          }}
          cancelAddComment={cancelAddComment}
          updateComment={(commentId, updatedData) => {
            return updateComment(solutionId, entityId, commentId, updatedData);
          }}
          cancelUpdateComment={cancelUpdateComment}
          markCommentsRead={(datetime) => {
            return markCommentsRead(solutionId, entityId, datetime);
          }}
          addBatchComments={(data, isCsvFormat) =>
            addBatchComments(solutionId, data, isCsvFormat)
          }
          clearBatchComments={clearBatchComments}
          isBatchCommentInProgress={isBatchCommentInProgress}
          isBatchCommentSuccessful={isBatchCommentSuccessful}
          isBatchCommentFailed={isBatchCommentFailed}
          batchCsvExample={batchCsvVinsExample}
          batchImportLabel={t("fv-vin-details:VIN")}
          style={{ margin: "1em" }}
          fetchNotification={fetchNotification}
        />
      ) : null}

      <div css={{ margin: "1em" }}>
        <Tabs
          css={{
            [TabsClassNames.Tab]: {
              // Reduces the tabs padding so more text can fit horizontally.
              padding: "0 2px",
            },
          }}
          fullWidthTabs
          onSelect={onTabsSelect}
        >
          <Tabs.TabList maxTabs={isExtraSmall() ? 4 : NO_PAGINATION}>
            {vinTabs.array?.map((tab) => (
              <Tabs.Tab key={tab.title}>
                <div
                  css={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    // This adjusts the text at different screen sizes
                    // so they are more likely to fit on one line.
                    [MediaQueries.mediumAndDown]: {
                      fontSize: 12,
                    },
                    [MediaQueries.large]: {
                      fontSize: 10,
                    },
                    [MediaQueries.extraLarge]: {
                      fontSize: 12,
                    },
                  }}
                >
                  {tab.title}
                </div>
              </Tabs.Tab>
            ))}
          </Tabs.TabList>
          {/* Trip Plan tab */}

          {
            // If we have tripSummary.stops, render the TripSummary (with Location Info and Updates).
            tripSummary.stops?.length > 0 ? (
              <Tabs.TabPanel style={detailTabPanelStyle}>
                <TripSummary
                  plannedStops={tripSummary.stops}
                  hideMadBorder={false}
                  showMadTooltip={false}
                  hideScheduled={!showScheduleWindow}
                  showUpdates={false}
                  showEta={!showScheduleWindow}
                  eta={eta}
                />
              </Tabs.TabPanel>
            ) : null
          }

          {/* Updates tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {
              // Else, if we have events, render the Events/Updates table.
              tripSummary.updates.length > 0 ? (
                <BaseTable
                  theme={Themes.LIGHT}
                  data={tripSummary.updates}
                  columns={updatesTableColumns}
                  disableSortBy={true}
                  pageSize={Math.max(
                    tripSummary.updates.length,
                    DEFAULT_TAB_PAGE_SIZE,
                  )}
                  subComponent={(row) => {
                    return (
                      <UpdatesTableSubComponent
                        statusUpdate={row.row.original.statusUpdate}
                        comments={row.row.original.comments}
                        duplicateMilestoneGroup={
                          row.row.original.duplicateMilestoneGroup
                        }
                        showComments={true}
                        t={t}
                      />
                    );
                  }}
                />
              ) : (
                // Else, "no data" placeholder.
                <TabPanelPlaceholder
                  text={t("fv-vin-details:No Updates Available")}
                />
              )
            }
          </Tabs.TabPanel>
          {/* Active Exceptions tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {vinTabs.data.combinedExceptions?.length > 0 ? (
              <BaseTable
                data-qa="active-exceptions"
                theme={Themes.LIGHT}
                data={vinTabs.data.combinedExceptions}
                columns={columns}
                pageSize={Math.max(
                  vinTabs.data.combinedExceptions.length,
                  DEFAULT_TAB_PAGE_SIZE,
                )}
              />
            ) : (
              <TabPanelPlaceholder
                text={t("fv-vin-details:No Active Exceptions")}
              />
            )}
          </Tabs.TabPanel>
          {/* Coordinates tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {endToEndTabCoords.length > 0 ? (
              <CoordinatesTable
                addCoordinate={(coordinateType, lat, long, time, ind, row) => {
                  addCoordinate(coordinateType, lat, long, time, ind, {
                    PopUpDetails: popUpDetails,
                    City: row.city,
                    State: row.state,
                    Country: row.country,
                  });
                }}
                clearCoordinatesByType={clearCoordinatesByType}
                selectedMapCoordinate={selectedMapCoordinate}
                coords={endToEndTabCoords}
              />
            ) : (
              <TabPanelPlaceholder
                text={t("fv-vin-details:No Coordinates Available")}
              />
            )}
          </Tabs.TabPanel>
          {/* Order Details tab */}
          {vinTabs.data.orderDetails.length > 0 && (
            <Tabs.TabPanel style={detailTabPanelStyle}>
              <BaseTable
                data-qa="order-details"
                theme={Themes.LIGHT}
                data={vinTabs.data.orderDetails}
                columns={orderDetailsColumns}
                pageSize={Math.max(
                  vinTabs.data.orderDetails.length,
                  DEFAULT_TAB_PAGE_SIZE,
                )}
                defaultSortColumn="qualifier"
                defaultReverseSort={false}
              />
            </Tabs.TabPanel>
          )}
          {/* VIN Details tab */}
          <Tabs.TabPanel style={detailTabPanelStyle}>
            {vinTabs.data.vinEquipment.length > 0 ? (
              <BaseTable
                data-qa="equipment-details"
                theme={Themes.LIGHT}
                data={vinTabs.data.vinEquipment}
                columns={vinDetailsColumns}
                pageSize={Math.max(
                  vinTabs.data.vinEquipment.length,
                  DEFAULT_TAB_PAGE_SIZE,
                )}
                defaultSortColumn="qualifier"
                defaultReverseSort={false}
              />
            ) : (
              <TabPanelPlaceholder
                text={t("fv-vin-details:No Details Available")}
              />
            )}
          </Tabs.TabPanel>
          {/* Connected Car tab */}
          {showConnectedCarTab && limitedConnectedCarCoords.length > 0 ? (
            <Tabs.TabPanel style={detailTabPanelStyle}>
              <ConnectedCarCoordinatesTable
                addCoordinate={addCoordinate}
                clearCoordinatesByType={clearCoordinatesByType}
                selectedConnectedCarCoordinate={selectedConnectedCarCoordinate}
                coords={limitedConnectedCarCoords}
                maxConnectedCarCoords={MAX_CONNECTED_CAR_COORDINATES}
                hasMoreConnectedCarCoords={hasMoreConnectedCarCoords}
                popUpDetails={popUpDetails}
              />
            </Tabs.TabPanel>
          ) : null}
          {/* Diagnostics Data tab */}
          {showDiagnosticDataTab &&
            vinTabs.data.diagnosticsData?.length > 0 && (
              <Tabs.TabPanel style={detailTabPanelStyle}>
                <BaseTable
                  data-qa="diagnostic-details"
                  theme={Themes.LIGHT}
                  data={vinTabs.data.diagnosticsData}
                  columns={diagnosticsDetailsColumns}
                  pageSize={Math.max(
                    vinTabs.data.diagnosticsData.length,
                    DEFAULT_TAB_PAGE_SIZE,
                  )}
                  subComponent={(row) => {
                    const qualifier = row.row.original.qualifier;

                    return (
                      <DiagnosticsHistoricalTableSubComponent
                        solutionId={solutionId}
                        entityId={entityId}
                        qualifier={qualifier}
                        dealerOrgId={selectedDealerOrgId}
                        fetchHistoricalData={fetchHistoricalData}
                        getHistoryByQualifier={getHistoryByQualifier}
                      />
                    );
                  }}
                />
              </Tabs.TabPanel>
            )}
        </Tabs>
      </div>
    </div>
  ) : null;

  let content = null;

  if (loaded) {
    // H2-2501: For Finished Vehicle, display tabs
    // TODO: Create tab components for E2E and Leg tabs
    // Current functions accept params (not just props) and return JSX.
    // Expected future usage would be something like:
    //  - <EndToEndTab showPrevTabPageButton={...} eventHandler={...} />
    //  - childSummaries.map((summary) => (
    //      <LegTab
    //        summary={summary}
    //        shipment={childDetails[summary.shipment_id]}
    //        isShipmentDetails={false}
    //        eventHandler={...}
    //        onClick={...}
    //      />
    //    ))
    const tabs = [
      endToEndTab(eventHandler),
      ...legTabs(childSummaries, childDetails, eventHandler, false, () =>
        clearAllCoordinates(),
      ),
    ];

    content = (
      <Tabs fullWidthTabs css={tabsCss}>
        <Tabs.TabList
          maxTabs={isExtraLarge() ? 5 : 3}
          staticTabs={1}
          style={tabListStyle}
        >
          {tabs}
        </Tabs.TabList>

        {/* End to End TabPanel */}
        <Tabs.TabPanel
          key="end-to-end"
          style={{ border: "1px solid #aaa" }}
          data-qa="panel-end-to-end"
        >
          {endToEndPanelContent}
        </Tabs.TabPanel>

        {/* Shipment Leg TabPanel(s) */}
        {shipmentsSorted.map((shipment, i) => (
          <Tabs.TabPanel
            style={tabPanelStyle}
            key={shipment.id}
            data-qa={`panel-leg-${i + 1}`}
          >
            <ShipmentContentPanel
              shipment={shipment}
              clearCoordinatesByType={clearCoordinatesByType}
              addCoordinate={(coordinateType, lat, long, time, ind, row) => {
                addCoordinate(coordinateType, lat, long, time, ind, {
                  PopUpDetails: getVinPopUpDetails(details),
                  City: row.city,
                  State: row.state,
                  Country: row.country,
                });
              }}
              enableActionMenu={false}
              organization={activeOrganization}
              shipmentModes={shipmentModes}
              isShipmentLoaded={true}
              isLoaded={true}
              showComments={isShipper(activeOrganization)}
              commentShareableWithOrgId={shipment?.carrier_organization_id}
              fetchComments={(pageNumber, pageSize) => {
                return fetchShipmentComments(shipment.id, pageNumber, pageSize);
              }}
              isFetchingComments={isFetchingShipmentComments}
              comments={shipmentComments}
              addComment={(data) => {
                return addShipmentComment(shipment.id, data);
              }}
              cancelAddComment={cancelAddShipmentComment}
              updateComment={(commentId, updatedData) => {
                return updateShipmentComment(
                  shipment.id,
                  commentId,
                  updatedData,
                );
              }}
              cancelUpdateComment={cancelUpdateShipmentComment}
              markCommentsRead={(datetime) => {
                return markShipmentCommentsRead(shipment.id, datetime);
              }}
              addBatchComments={(data, isCsvFormat) =>
                addBatchShipmentComments(data, isCsvFormat)
              }
              clearBatchComments={clearBatchShipmentComments}
              isBatchCommentInProgress={isBatchShipmentCommentInProgress}
              isBatchCommentSuccessful={isBatchShipmentCommentSuccessful}
              isBatchCommentFailed={isBatchShipmentCommentFailed}
              fetchNotification={fetchNotification}
            />
          </Tabs.TabPanel>
        ))}
      </Tabs>
    );
  }

  return (
    <RoutingMapContextProvider
      shipments={routeShipments}
      showBreadCrumbs={true}
      showStopSequence={true}
      showHeatmap={showHeatmap}
      heatmapCoords={heatmapCoords}
      isMultimodal={isMultimodal}
      activeShipment={isMultimodal ? details?.activeTripLegId : null}
      connectedCarCoords={limitedConnectedCarCoords}
      selectedLegID={selectedLegID}
      hideMads={false}
      t={t}
      allowCaptureScreenshot={false}
    >
      <DetailWithMap
        mapChildren={
          <Fragment>
            <RoutingMapContext.Consumer>
              {(routingMapProps) => <RoutingMap {...routingMapProps} />}
            </RoutingMapContext.Consumer>

            {heatmapCoords?.length > 0 ? (
              <HeatMapButton
                isToggled={showHeatmap}
                onToggle={() => setShowHeatmap(!showHeatmap)}
                css={{
                  position: "absolute",
                  top: 5,
                  right: 0,
                  zIndex: 2,
                }}
              />
            ) : null}
          </Fragment>
        }
        detailsChildren={<Loader loaded={loaded}>{content}</Loader>}
      />
    </RoutingMapContextProvider>
  );
};

FinishedVehicleDetails.propTypes = {
  solutionId: PropTypes.string,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedDealerOrgId: PropTypes.string,
  details: PropTypes.object,
  detailsError: PropTypes.object,
  combinedLegs: PropTypes.array,
  shipmentModes: PropTypes.array,
  childShipments: PropTypes.object,
  shipmentsLoaded: PropTypes.bool,
  plannedLegs: PropTypes.object,
  actualLegs: PropTypes.object,
  connectedCarCoords: PropTypes.array,
  combinedExceptions: PropTypes.array,
  currentLocation: PropTypes.object,
  authorization: PropTypes.object,
  fetchComments: PropTypes.func,
  isFetchingComments: PropTypes.bool,
  comments: PropTypes.object,
  addComment: PropTypes.func,
  cancelAddComment: PropTypes.func,
  updateComment: PropTypes.func,
  cancelUpdateComment: PropTypes.func,
  markCommentsRead: PropTypes.func,
  fetchShipmentComments: PropTypes.func,
  isFetchingShipmentComments: PropTypes.bool,
  shipmentComments: PropTypes.object,
  addShipmentComment: PropTypes.func,
  cancelAddShipmentComment: PropTypes.func,
  updateShipmentComment: PropTypes.func,
  cancelUpdateShipmentComment: PropTypes.func,
  markShipmentCommentsRead: PropTypes.func,
  addBatchComments: PropTypes.func.isRequired,
  clearBatchComments: PropTypes.func.isRequired,
  isBatchCommentInProgress: PropTypes.bool.isRequired,
  isBatchCommentSuccessful: PropTypes.bool.isRequired,
  isBatchCommentFailed: PropTypes.bool.isRequired,
  fetchNotification: PropTypes.func.isRequired,
  shareableCarrierFvIds: PropTypes.arrayOf(PropTypes.string),
  // Shipper Comments
  addBatchShipmentComments: PropTypes.func.isRequired,
  clearBatchShipmentComments: PropTypes.func.isRequired,
  isBatchShipmentCommentInProgress: PropTypes.bool.isRequired,
  isBatchShipmentCommentSuccessful: PropTypes.bool.isRequired,
  isBatchShipmentCommentFailed: PropTypes.bool.isRequired,
  activeOrganization: PropTypes.object,
  events: PropTypes.array,
  exceptions: PropTypes.array,
  holds: PropTypes.array,
  vinSubscription: PropTypes.object,
  isVinSubscriptionLoading: PropTypes.bool,
  vinSubscriptionRequestError: PropTypes.bool,
  updateSubscription: PropTypes.func,
  subscribe: PropTypes.func,
  unsubscribe: PropTypes.func,
  isVinSubscriptionUpdating: PropTypes.bool,
  vinSubscriptionUpdateSuccess: PropTypes.bool,
  vinSubscriptionUpdateError: PropTypes.bool,
  media: PropTypes.string,
  clearEntityMedia: PropTypes.func,
  fetchLegShipmentDetails: PropTypes.func,
  clearLegShipmentDetails: PropTypes.func,
  fetchEntityDetails: PropTypes.func,
  fetchEntityExceptions: PropTypes.func,
  fetchEntityHolds: PropTypes.func,
  fetchEntityPositionUpdates: PropTypes.func,
  fetchEntityEvents: PropTypes.func,
  fetchPlannedTripLeg: PropTypes.func,
  fetchActualTripLeg: PropTypes.func,
  fetchVinSubscription: PropTypes.func,
  fetchEntityMedia: PropTypes.func,
  setWatchEntity: PropTypes.func,
  coords: PropTypes.array,
  clearShareVin: PropTypes.func.isRequired,
  shareVin: PropTypes.func.isRequired,
  isSharingVin: PropTypes.bool,
  isShareVinSuccessful: PropTypes.bool,
  isShareVinFailed: PropTypes.bool,
  // Map Coordinates
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  clearAllCoordinates: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.object,
  currentMapCoordinate: PropTypes.object,
  selectedConnectedCarCoordinate: PropTypes.object,
  // Historical Data
  getHistoryByQualifier: PropTypes.func.isRequired,
  fetchHistoricalData: PropTypes.func.isRequired,
  fetchEntityStateHistory: PropTypes.func.isRequired,
  getEntityStateChangeTs: PropTypes.func.isRequired,
  // Error redirects
  redirectTo404: PropTypes.func.isRequired,
};
